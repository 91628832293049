import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/hooks';

// Components
import UserDropdown from '../dropdowns/UserDropdown';

const Navbar = () => {
  const { t } = useTranslation(['navbar']);
  const role = useAppSelector((state) => state.auth.role);

  return (
    <nav className='absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4'>
      <div className='w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4'>
        <div className='text-white text-sm uppercase hidden lg:inline-block font-semibold'>
          {role === 'admin' ? t('header') : t('headerCoach')}
        </div>
        <ul className='flex-col md:flex-row list-none items-center hidden md:flex'>
          <UserDropdown />
        </ul>
      </div>
    </nav>
  );
};
export default Navbar;
