import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { StoryListItemI } from 'src/interfaces/storiesInterface';
import { EntryNodeI, Stories } from '../state.types';

const initialState: Stories = {
  stories: {
    isSuccess: false,
    statusCode: '',
    message: '',
    metadata: {
      page: 1,
      perPage: 10,
      pageCount: null,
      totalCount: null,
    },
    data: [],
  },
  storyDetail: {
    id: null,
    duration: '',
    createdAt: '',
    updatedAt: '',
    isDeleted: false,
    subscriptionRequired: false,
    localizedStories: null,
  },
  localizedStoryDetailEn: {
    id: null,
    title: '',
    description: '',
    locale: '',
    mediumId: null,
    medium: null,
    createdAt: '',
    updatedAt: '',
    isDeleted: false,
    isPublished: false,
    entryNode: {
      type: '',
      description: '',
      question: '',
      statement: '',
      medium: {
        id: null,
        type: '',
        url: '',
        thumbnailUrl: '',
      },
      mediumId: null,
      createdAt: '',
      updatedAt: '',
      isDeleted: false,
      id: null,
      children: null,
    },
  },
  localizedStoryDetailEs: {
    id: null,
    title: '',
    description: '',
    locale: '',
    mediumId: null,
    medium: {
      id: null,
      type: '',
      url: '',
      thumbnailUrl: '',
    },
    createdAt: '',
    updatedAt: '',
    isDeleted: false,
    isPublished: false,
    entryNode: {
      type: '',
      description: '',
      question: '',
      statement: '',
      mediumId: null,
      createdAt: '',
      updatedAt: '',
      isDeleted: false,
      id: null,
      children: null,
      medium: {
        id: null,
        type: '',
        url: '',
        thumbnailUrl: '',
      },
    },
  },
  createStoryView: '',
  storyIsPublished: false,
  existsEn: '',
  existsEs: '',
  storyView: '',
};

const findChild = (
  id: string,
  children?: Array<WritableDraft<EntryNodeI>> | null,
): WritableDraft<EntryNodeI> | null => {
  if (!children) return null;

  let child: WritableDraft<EntryNodeI> | null = children.find((child) => child.id === id) || null;

  if (!child) {
    children.forEach((c) => {
      child = child || findChild(id, c.children);
    });
  }

  return child || null;
};

const findNode = (id: string, state: WritableDraft<Stories>): WritableDraft<EntryNodeI> | null => {
  if (id === state.localizedStoryDetailEn?.entryNode?.id) {
    return state.localizedStoryDetailEn.entryNode;
  } else if (id === state.localizedStoryDetailEs?.entryNode?.id) {
    return state.localizedStoryDetailEs.entryNode;
  }

  return (
    findChild(id, state.localizedStoryDetailEn?.entryNode?.children) ||
    findChild(id, state.localizedStoryDetailEs?.entryNode?.children)
  );
};

const markNodeAsDeleted = (node: WritableDraft<EntryNodeI>): void => {
  node.isDeleted = true;
  node.children?.forEach((child) => markNodeAsDeleted(child));
};

const storiesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    setStories: (state, action) => {
      state.stories.data = action.payload;
    },
    setVisibility: (state, action) => {
      const findStory = state.stories.data.find(
        (story: StoryListItemI) => story.id === action.payload.id,
      );
      if (findStory) {
        findStory.isDeleted = action.payload.isDeleted;
      }
      return state;
    },
    setStoriesMetadata: (state, action) => {
      state.stories.metadata = action.payload;
    },
    deleteStory: (state, action) => {
      state.stories.data = state.stories.data.filter((s: any) => s.id !== action.payload);
    },
    setFreeStory: (state, action) => {
      const findStory = state.stories.data.find((s: StoryListItemI) => s.id === action.payload);
      const findCurrentFreeStory = state.stories.data.find(
        (story: StoryListItemI) => story.subscriptionRequired === false,
      );
      if (findStory && findCurrentFreeStory) {
        findStory.subscriptionRequired = false;
        findCurrentFreeStory.subscriptionRequired = true;
      }
      return state;
    },
    setStoryDetail: (state, action) => {
      state.storyDetail = action.payload;
    },
    setLocalizedStoryDetailEn: (state, action) => {
      // Conditional fix to solve entryNode null error when editing
      if (state.localizedStoryDetailEn.entryNode.type !== '') {
        state.localizedStoryDetailEn = {
          ...action.payload,
          entryNode: state.localizedStoryDetailEn.entryNode,
        };
      } else {
        state.localizedStoryDetailEn = action.payload;
      }
    },
    setLocalizedStoryDetailEs: (state, action) => {
      // Conditional fix to solve entryNode null error when editing
      if (state.localizedStoryDetailEs.entryNode.type !== '') {
        state.localizedStoryDetailEs = {
          ...action.payload,
          entryNode: state.localizedStoryDetailEs.entryNode,
        };
      } else {
        state.localizedStoryDetailEs = action.payload;
      }
    },
    resetLocalizedStoryDetailData: (state) => {
      state.localizedStoryDetailEn = initialState.localizedStoryDetailEn;
      state.localizedStoryDetailEs = initialState.localizedStoryDetailEs;
    },
    addLocalizedStoryDetail: (state, action) => {
      const currentNode = findNode(action.payload.currentId, state);
      if (currentNode && currentNode.type !== 'Question') {
        currentNode.children = [action.payload.node];
      } else if (currentNode) {
        currentNode.children = [...(currentNode.children || []), action.payload.node];
      }
    },
    deleteNode(state, action) {
      const currentNode = findNode(action.payload.currentId, state);

      if (currentNode) {
        markNodeAsDeleted(currentNode);
      }
    },
    editNode(state, action) {
      const currentNode = findNode(action.payload.currentId, state);

      if (currentNode) {
        currentNode.type = action.payload.node.type;
        currentNode.description = action.payload.node.description;
        currentNode.statement = action.payload.node.statement;
        currentNode.question = action.payload.node.question;
        currentNode.medium = action.payload.node.medium;
      }
    },
    setCreateStoryView: (state, action) => {
      state.createStoryView = action.payload;
    },
    setStoryIsPublished: (state, action) => {
      state.storyIsPublished = action.payload;
    },
    setExistsEn: (state, action) => {
      state.existsEn = action.payload;
    },
    setExistsEs: (state, action) => {
      state.existsEs = action.payload;
    },
    setStoryView: (state, action) => {
      state.storyView = action.payload;
    },
  },
});

export const {
  setStories,
  setStoriesMetadata,
  deleteStory,
  setStoryDetail,
  setLocalizedStoryDetailEn,
  setLocalizedStoryDetailEs,
  addLocalizedStoryDetail,
  setVisibility,
  deleteNode,
  editNode,
  setCreateStoryView,
  setStoryIsPublished,
  resetLocalizedStoryDetailData,
  setExistsEn,
  setExistsEs,
  setFreeStory,
  setStoryView,
} = storiesSlice.actions;

export default storiesSlice.reducer;
