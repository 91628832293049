import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import noPhoto from '../../../../assets/img/noPhoto.svg';
import emailImg from '../../../../assets/img/email.svg';
import phoneImg from '../../../../assets/img/phone.svg';
import locationImg from '../../../../assets/img/location.svg';
import birthdayImg from '../../../../assets/img/birthday.svg';
import genderImg from '../../../../assets/img/gender.svg';
import subscribedImg from '../../../../assets/img/subscribed.svg';
import consentImg from '../../../../assets/img/noDescription.svg';
import photosImg from '../../../../assets/img/photos.svg';
import questionImg from '../../../../assets/img/question.svg';
import closeBtn from '../../../../assets/img/closeBtn.svg';
import { dateUTCToLocale } from 'src/common/helpers/utcToLocale';
import { Gallery } from 'react-grid-gallery';
import Lightbox from 'react-image-lightbox';

// Components
import Icon from 'src/ui/Icon';
import UserDetailItem from './UserDetailItem';
import Button from 'src/ui/Button';
import PopUp from 'src/ui/Popup';
import ChooseItem from 'src/ui/ChooseItem';
import DecisionFooter from 'src/ui/layout/DecisionFooter';

// Interfaces
import { UserDetailI } from 'src/interfaces/usersInterface';
import moment from 'moment';

const UserDetailCard = (props: UserDetailI) => {
  const {
    review,
    userDetail,
    galleryImages,
    handleClick,
    handleClose,
    handleMoveNext,
    handleMovePrev,
    currentImage,
    prevImage,
    nextImage,
    setShowPopupApprove,
    setShowPopupReject,
    showPopupApprove,
    showPopupReject,
    options,
    handleCheckboxs,
    handleApprove,
    handleReject,
    checkedOptions,
  } = props;
  const { t } = useTranslation(['userDetail']);
  const navigate = useNavigate();

  return (
    <>
      <div
        className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary overflow-auto'
        style={{ height: review ? '85vh' : '75vh' }}
      >
        <div className='flex items-center p-3 bg-background rounded-t-2xl'>
          <Icon icon='backBtn' onClick={() => navigate(-1)} />
          <span className='flex font-semibold text-sm mx-2 uppercase'>{`${
            review ? t('review') : t('header')
          }`}</span>
        </div>
        <hr />
        <div className='flex h-full'>
          <div className='flex flex-col p-4 w-2/5 h-full'>
            <div className='coach-picture'>
              <div
                className={`coach-preview w-32 h-32 ${userDetail.profilePicture ? 'bg-cover' : ''}`}
                style={{
                  backgroundImage: `url(${userDetail.profilePicture || noPhoto})`,
                }}
              />
            </div>
            <div className='flex flex-col p-4'>
              <span className='text-center text-xl'>{userDetail.fullName}</span>
              <span className='my-4 text-center text-sm'>{userDetail.description || ''}</span>
              <div style={{ maxHeight: '44vh', overflow: 'auto' }}>
                <UserDetailItem
                  imgSrc={emailImg}
                  text={userDetail.email}
                  imgAlt='email'
                  tooltipId='email'
                  tooltipText={t('emailTooltip')}
                />
                <UserDetailItem
                  imgSrc={phoneImg}
                  text={userDetail.phone || t('noPhone')}
                  imgAlt='phone'
                  tooltipId='phone'
                  tooltipText={t('phoneTooltip')}
                />
                <UserDetailItem
                  imgSrc={locationImg}
                  text={userDetail.city ? `${userDetail.city} ${userDetail.state}` : t('noLoc')}
                  imgAlt='location'
                  tooltipId='location'
                  tooltipText={t('locTooltip')}
                />
                <UserDetailItem
                  imgSrc={genderImg}
                  text={userDetail.gender || t('noGen')}
                  imgAlt='gender'
                  tooltipId='gender'
                  tooltipText={t('genderTooltip')}
                />
                <UserDetailItem
                  imgSrc={birthdayImg}
                  text={moment(userDetail.birthDay).format('L') || t('noBirth')}
                  imgAlt='birthday'
                  tooltipId='birthday'
                  tooltipText={t('birthTooltip')}
                />
                <UserDetailItem
                  imgSrc={userDetail.isSubscribed ? subscribedImg : closeBtn}
                  text={userDetail.isSubscribed ? t('statusSub') : t('statusNotSub')}
                  imgAlt='subscription'
                  imgStyle={{ width: userDetail.isSubscribed ? '' : '25px', height: '20px' }}
                  tooltipId='subscription'
                  tooltipText={t('statusTooltip')}
                />
                <UserDetailItem
                  imgSrc={consentImg}
                  text={
                    userDetail.consentDate
                      ? `${dateUTCToLocale(userDetail.consentDate, 'L')} at ${dateUTCToLocale(
                          userDetail.consentDate,
                          'LT',
                        )}`
                      : t('noConsent')
                  }
                  imgAlt='consent'
                  imgExtraClass='w-6'
                  tooltipId='consent'
                  tooltipText={t('consentTooltip')}
                />
              </div>
            </div>
          </div>
          <hr className='h-full border' />
          <div
            className={`w-3/5 p-4 gallery-container ${
              userDetail.media?.length === 0 ? 'flex flex-col justify-center items-center' : ''
            }`}
          >
            <div>
              {userDetail.media && (
                <Gallery
                  images={galleryImages}
                  onClick={handleClick}
                  enableImageSelection={false}
                  margin={8}
                  rowHeight={200}
                  maxRows={10}
                />
              )}
              {userDetail.media?.length === 0 && (
                <div className='flex flex-col justify-center items-center h-64'>
                  <img className='w-14' src={photosImg} alt='description' />
                  <h1 className='my-5'>{t('noPhotos')}</h1>
                </div>
              )}
              {!!currentImage && (
                <Lightbox
                  mainSrc={currentImage.original}
                  imageTitle={currentImage.caption}
                  mainSrcThumbnail={currentImage.src}
                  nextSrc={nextImage.original}
                  nextSrcThumbnail={nextImage.src}
                  prevSrc={prevImage.original}
                  prevSrcThumbnail={prevImage.src}
                  onCloseRequest={handleClose}
                  onMovePrevRequest={handleMovePrev}
                  onMoveNextRequest={handleMoveNext}
                />
              )}
            </div>
          </div>
        </div>
        {props.review && (
          <>
            <hr />
            <div className='review-footer'>
              <div className='w-2/12 mr-8'>
                <Button extraClass='p-2 capitalize' onClick={() => setShowPopupReject(true)}>
                  {t('rejectBtn')}
                </Button>
              </div>
              <div className='w-2/12'>
                <Button extraClass='p-2 capitalize' onClick={() => setShowPopupApprove(true)}>
                  {t('approveBtn')}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <PopUp
        extraClassesChildren='flex flex-col items-center'
        isVisible={showPopupReject}
        onClose={() => {
          setShowPopupReject(false);
        }}
        extraClass='max-w-lg'
      >
        <img src={questionImg} alt='question' />
        <div className='text-primary text-3xl w-full text-center font-normal p-4'>Why?</div>
        <hr className='w-full border border-white' />
        <ChooseItem options={options} name='rejectOptions' onChange={handleCheckboxs} />
        <DecisionFooter
          cancel={t('cancelBtn')}
          approve={t('submitBtn')}
          onCancel={() => setShowPopupReject(false)}
          onApprove={handleReject}
          disabled={checkedOptions.length === 0}
        />
      </PopUp>
      <PopUp
        extraClass='max-w-lg'
        extraClassesChildren='flex flex-col items-center'
        isVisible={showPopupApprove}
        onClose={() => {
          setShowPopupApprove(false);
        }}
      >
        <img src={questionImg} alt='magnifying glass' className='my-4' />
        <div className='text-primary text-3xl w-full text-center font-normal'>
          {t('approveTitle')}
        </div>
        <span className='text-base mt-12 mb-4 mx-8'>
          {`${t('approveSubtitleOne')} ${userDetail.fullName}${t('approveSubtitleTwo')}`}
        </span>
        <DecisionFooter
          cancel={t('cancelBtn')}
          approve={t('yesApprove')}
          onApprove={handleApprove}
          onCancel={() => setShowPopupApprove(false)}
        />
      </PopUp>
    </>
  );
};

export default UserDetailCard;
