import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Components
import UserProfilePictureForm from 'src/components/forms/admin/users/UserProfilePictureForm';

// Interfaces
import { UserProfilePictureFormProps } from 'src/interfaces/usersInterface';

// Services
import { uploadProfilePictureUseCase } from 'src/services/users/useCases';

// Redux
import { setAddUserView } from 'src/redux/slices/users.slice';

const AddUserProfilePicture = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const addUserView = useAppSelector((state) => state.users.addUserView);
  const currentUserId = JSON.parse(localStorage.getItem('current-user') || '');
  const handleSuccess = async (values: UserProfilePictureFormProps) => {
    const formData = new FormData();
    formData.append('profilePicture', values.profilePicture as Blob);
    if (values.profilePicture) {
      dispatch(uploadProfilePictureUseCase({ publicId: currentUserId, profilePicture: formData }));
    }
    dispatch(setAddUserView('photos'));
  };

  useEffect(() => {
    if (addUserView === 'photos') {
      navigate('/admin/users/add-user/photos');
    }
  }, [addUserView, navigate]);

  return <UserProfilePictureForm onSuccess={handleSuccess} isEditing={false} />;
};

export default AddUserProfilePicture;
