const acceptedImageFormats = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/heic',
  'image/raw',
];

export default acceptedImageFormats;
