import { useTranslation } from 'react-i18next';
import { dateUTCToLocale } from 'src/common/helpers/utcToLocale';

// Interfaces
import { PSessionsI } from 'src/interfaces/coach/wellness';

const Session = (props: PSessionsI) => {
  const { session, opacity } = props;
  const responsesArray = JSON.parse(session.surveyResponse);
  const newArray: Array<string> = [];
  const { t } = useTranslation(['wellness']);
  const handleResponseValues = () => {
    responsesArray?.[0].answers.map((value: string) => {
      if (value === 'RelationshipIssues') newArray.push(t('session.relIssues'));
      if (value === 'FamilyIssues') newArray.push(t('session.famIssues'));
      if (value === 'PersonalIssues') newArray.push(t('session.personalIssues'));
      if (value === 'Other') newArray.push(t('session.otherIssues'));
    });
    return newArray.length > 0 ? newArray.join(', ') : t('session.noTopic');
  };

  return (
    <div className='flex items-center w-full'>
      <div className='w-5/6 flex flex-col items-start ml-0 sm:ml-4 mr-4'>
        <div className={`font-semibold pb-2 text-left ${opacity ? 'opacity-70' : ''}`}>
          {`${t('session.durationFirst')} ${session.duration} ${t('session.durationLast')}`}
        </div>
        <div className={`text-left text-xs sm:text-sm ${opacity ? 'opacity-70' : ''}`}>
          <p>
            <b className='font-medium pr-1'>{t('session.when')}</b>
            {dateUTCToLocale(session.startDate, 'DD MMM YYYY')}
            <span className='mx-0 sm:mx-1'> / </span>
            {dateUTCToLocale(session.startDate, 'hh:mm')} <span> - </span>
            {dateUTCToLocale(session.endDate, 'hh:mm A')}
          </p>
        </div>
        <div className={`text-left mr-2 text-xs sm:text-sm ${opacity ? 'opacity-70' : ''}`}>
          <b className='font-medium pr-1'>{t('session.with')}</b>
          {`${session.userFullName}`}
        </div>
        <div className={`text-left mr-2 text-xs sm:text-sm ${opacity ? 'opacity-70' : ''}`}>
          <b className='font-medium pr-1'>{t('session.topic')}</b>
          {handleResponseValues()}
        </div>
      </div>
    </div>
  );
};

export default Session;
