import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import acceptedImageFormats from 'src/common/constants/imgFormats';
import { validateImageDimension } from 'src/common/helpers/resizerImage';
import * as Yup from 'yup';
import { useAppDispatch } from 'src/hooks';

// Redux
import { setResponsePopup } from 'src/redux/slices/generalSlice';
import { setEditCurrentCoachView } from 'src/redux/slices/currentCoach.slice';

// Interfaces
import {
  CoachAdditionalInfoFormProps,
  CoachProfilePictureProps,
} from 'src/interfaces/coachesInterface';
import { FileWithUrl, IFile } from 'src/interfaces/generalInterface';

// Components
import Button from 'src/ui/Button';
import Icon from 'src/ui/Icon';
import ConfirmationPopup from 'src/ui/popups/ConfirmationPopup';

// Services
import { deleteCurrentCoachProfilePictureUseCase } from 'src/services/wellness/useCases';
import { deleteCurrentCoachCertificateAction } from 'src/services/wellness/requests';

const CurrentCoachAdditionalInfoForm = (props: CoachProfilePictureProps) => {
  const dispatch = useAppDispatch();
  const { onSuccess, data, isEditing } = props;
  const image = new Image();
  const [profilePicture, setProfilePicture] = useState<IFile | null>();
  const { t } = useTranslation(['addEditCoachAdditionalInfoForm']);
  const [preview, setPreview] = useState<any>(data?.profilePicture || '');
  const [showDeletePicture, setShowDeletePicture] = useState<boolean>(false);
  const [showDeleteCertificate, setShowDeleteCertificate] = useState<boolean>(false);
  const [currentCertificate, setCurrentCertificate] = useState<IFile>();
  const [certificates, setCertificates] = useState<FileWithUrl[]>([]);
  const fileInputRef = React.createRef<HTMLInputElement>();
  const certificatesInputRef = React.createRef<HTMLInputElement>();
  const navigate = useNavigate();
  const formik = useFormik<CoachAdditionalInfoFormProps>({
    initialValues: {
      profilePicture: '',
      certificates: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      profilePicture: Yup.mixed()
        .notRequired()
        .test('type', t('errorImageFormats'), (file) => {
          if (file) {
            return acceptedImageFormats.includes(file.type);
          }
          return true;
        })
        .test('fileSize', t('errorFileSize'), (file) => {
          if (file) {
            return file.size <= 10485760;
          }
          return true;
        })
        .test('imageWidth', t('errorWidth'), (file) => {
          if (file && image.width > 2048) {
            return false;
          }
          return true;
        })
        .test('imageHeight', t('errorHeight'), (file) => {
          if (file && image.height > 2048) {
            return false;
          }
          return true;
        }),
      certificates: Yup.mixed()
        .test('length', t('maxImages'), (photos) => {
          if (photos && photos?.length > 10) {
            return false;
          }
          return true;
        })
        .test('type', t('errorImageFormats'), (photos) => {
          if (photos && photos.length > 0) {
            for (let i = 0; i < photos.length; i++) {
              if (!photos[i].inServer) {
                if (!acceptedImageFormats.includes(photos[i].file.type)) return false;
              }
            }
          }
          return true;
        })
        .test('fileSize', t('errorFileSize'), (files) => {
          let valid = true;
          if (files) {
            files.forEach((file: IFile) => {
              if (!file.inServer) {
                const size = file.file.size / 1024 / 1024;
                if (size > 10) {
                  valid = false;
                }
              }
            });
          }
          return valid;
        })
        .test('width', t('errorDimensions'), async (value) => {
          const imageArray: Array<any> = [];
          if (value) {
            value.forEach((file: IFile) => {
              if (!file.inServer) {
                const promise = validateImageDimension(file.file);
                imageArray.push(promise);
              }
            });

            const res = await Promise.all(imageArray);
            return !res.includes(false);
          }
          return true;
        }),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });
  const handleUpload = (upPhotos: any) => {
    const nArray: any = certificates.concat(upPhotos);
    const send = nArray.map((p: any, index: number) => {
      const send: any = Object.assign({}, p);
      if (!send.inServer) {
        send.id = index;
      }
      return send;
    });
    setCertificates(send);
    formik.setFieldValue('certificates', send);
    formik.setFieldTouched('certificates');
  };

  const handleChange = function (e: any) {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      if (e.currentTarget.files.length === 1) {
        const iFile = {
          id: 1,
          inServer: false,
          file: e.currentTarget.files[0],
          urlPreview: URL.createObjectURL(e.currentTarget.files[0]),
        };
        validateImageDimension(iFile.file).then(() => {
          handleUpload([iFile]);
        });
      } else {
        const arrayImage = [...e.currentTarget.files];
        const send = arrayImage.map((file: File, index: number) => {
          const r = {
            id: index,
            file: file,
            urlPreview: URL.createObjectURL(file),
            inServer: false,
          };
          return r;
        });
        handleUpload(send);
      }
    }
  };
  const handlePreviewProfile = () => {
    setProfilePicture(null);
    formik.setFieldValue('profilePicture', null);
    setPreview('');
  };

  const handleDeletePicture = () => {
    setShowDeletePicture(false);
    handlePreviewProfile();
    if (profilePicture?.inServer) {
      dispatch(deleteCurrentCoachProfilePictureUseCase());
    }
  };
  const deleteCertificateConfirmation = (certificate: IFile) => {
    setCurrentCertificate(certificate);
    setShowDeleteCertificate(true);
  };
  const handleDeleteCertificate = () => {
    setShowDeleteCertificate(false);
    if (currentCertificate?.inServer) {
      deleteCurrentCoachCertificateAction({
        certificationId: currentCertificate?.id,
      }).then(() => {
        const copyPhotos = [...certificates];
        const save = copyPhotos.filter((p) => p.id !== currentCertificate?.id);
        setCertificates(save);
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'success',
            header: t('deleteCertificate.deleteConfirmationTitle'),
            subtitle: t('deleteCertificate.deleteConfirmationSubtitle'),
            submitBtn: t('deleteCertificate.ok'),
          }),
        );
      });
    } else {
      const copyPhotos = [...certificates];
      const save = copyPhotos.filter((p) => p.id !== currentCertificate?.id);
      setCertificates(save);
      formik.setFieldValue('certificates', save);
      formik.setFieldTouched('certificates');
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'success',
          header: t('deleteCertificate.deleteConfirmationTitle'),
          subtitle: t('deleteCertificate.deleteConfirmationSubtitle'),
          submitBtn: t('deleteCertificate.ok'),
        }),
      );
    }
  };

  const handleNavigation = () => {
    dispatch(setEditCurrentCoachView(''));
    navigate(-1);
  };

  useEffect(() => {
    if (data?.profilePicture) {
      const r: any = Object.assign({}, data?.profilePicture);
      r.urlPreview = data?.profilePicture;
      r.inServer = true;
      setProfilePicture(r);
      setPreview(r.urlPreview);
    }
  }, [data?.profilePicture]);

  useEffect(() => {
    if (data?.certifications) {
      const updatedPhotos = data?.certifications.map((file: any) => {
        const r = Object.assign({}, file);
        r.urlPreview = file.fileUrl;
        r.inServer = true;
        return r;
      });
      setCertificates(updatedPhotos);
    }
  }, [data?.certifications]);

  return (
    <div
      className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary'
      style={{ height: '75vh' }}
    >
      <div className='flex items-center p-3 bg-background rounded-t-2xl'>
        {isEditing && <Icon icon='backBtn' onClick={handleNavigation} />}
        <span className='flex font-semibold text-sm mx-2 uppercase'>{t('header')}</span>
      </div>
      <hr />
      <div className='coach-container h-full pt-4'>
        <div className='coach-top flex w-full h-full'>
          <div className='coach-general w-full overflow-auto'>
            <form onSubmit={formik.handleSubmit} className='h-full'>
              <div className='flex px-8' style={{ height: '80%' }}>
                <div className='flex justify-center items-center relative w-6/12 p-8'>
                  <div className='w-64 flex flex-col items-center'>
                    <div className='block text-primary mb-4 text-base text-left'>
                      {t('picture')}
                    </div>
                    {formik.touched.profilePicture && formik.errors.profilePicture && (
                      <div className='formUsersError mb-4'>{formik.errors.profilePicture}</div>
                    )}
                    {preview ? (
                      <div className='relative'>
                        <img
                          src={preview}
                          alt='preview'
                          className='coach-profile-picture'
                          aria-hidden='true'
                        />
                        <Icon
                          icon='delete'
                          extraClass='coach-delete-picture'
                          onClick={() => setShowDeletePicture(true)}
                        />
                      </div>
                    ) : (
                      <button
                        type='button'
                        className='border-2 border-primary border-solid rounded-3xl w-52 p-2 mt-4 mb-12'
                        onClick={(e) => {
                          e.preventDefault();
                          fileInputRef.current?.click();
                        }}
                      >
                        {t('upload')}
                      </button>
                    )}
                    <input
                      ref={fileInputRef}
                      className='hidden'
                      type='file'
                      name='file'
                      accept='image/*'
                      onBlur={formik.handleBlur}
                      onChange={(event) => {
                        formik.setFieldValue(
                          'profilePicture',
                          event.currentTarget.files ? event.currentTarget.files[0] : 'no file',
                        );
                        if (
                          event.target.files &&
                          event.target.files[0].type.substring(0, 5) === 'image'
                        ) {
                          const iFile = {
                            id: 1,
                            inServer: false,
                            file: event.target.files[0],
                            urlPreview: URL.createObjectURL(event.target.files[0]),
                          };
                          setProfilePicture(iFile);
                          setPreview(iFile.urlPreview);
                        }
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`flex flex-col ${
                    certificates.length > 0 ? 'justify-start' : 'justify-center'
                  } items-center w-6/12 p-8`}
                >
                  <>
                    <div className='formUsersHeader text-base text-left w-60'>
                      <>
                        <div className='formUsersHeader text-base text-center'>
                          {t('certifications')}
                        </div>
                        <input
                          type='file'
                          onChange={handleChange}
                          multiple
                          ref={certificatesInputRef}
                          className='hidden'
                          name='certificates'
                          accept='image/*'
                        />
                        <button
                          type='submit'
                          className='border-2 border-primary border-solid rounded-3xl w-full p-2 mt-4'
                          onClick={(e) => {
                            e.preventDefault();
                            certificatesInputRef.current?.click();
                          }}
                        >
                          {`${certificates.length > 0 ? t('certiMoreBtn') : t('certiBtn')}`}
                        </button>
                      </>
                    </div>
                    <div className='flex flex-col w-full p-4 overflow-auto max-h-48'>
                      {certificates.map((certificate) => (
                        <div
                          className='flex h-full justify-center relative mb-2'
                          key={certificate.id}
                        >
                          <img
                            src={certificate.urlPreview}
                            alt='certificate'
                            className='w-8 h-6 mr-4 rounded-t-lg'
                          />
                          <a
                            className='underline text-sm  w-72 whitespace-nowrap overflow-hidden text-ellipsis'
                            href={certificate.urlPreview}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {certificate.urlPreview}
                          </a>
                          <Icon
                            icon='delete'
                            extraClass='coach-delete-certification'
                            onClick={() => deleteCertificateConfirmation(certificate)}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                </div>
              </div>
              <hr className='w-full border' />
              <div className='flex justify-center mt-4'>
                {isEditing ? (
                  <Button type='submit' extraClass='font-semibold w-52 p-2 mt-4'>
                    {data?.certifications !== certificates ||
                    data?.profilePicture !== profilePicture
                      ? t('save')
                      : t('skip')}
                  </Button>
                ) : (
                  <Button type='submit' extraClass='font-semibold w-52 p-2 mt-4'>
                    {certificates.length > 0 || profilePicture ? t('save') : t('skip')}
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ConfirmationPopup
        isVisible={showDeletePicture}
        title={t('deleteAlert.title')}
        subtitle={t('deleteAlert.subtitle')}
        submitBtn={t('deleteAlert.confirm')}
        handleSubmit={handleDeletePicture}
        onClose={() => setShowDeletePicture(false)}
      />
      <ConfirmationPopup
        isVisible={showDeleteCertificate}
        title={t('deleteCertificate.title')}
        subtitle={t('deleteCertificate.subtitle')}
        submitBtn={t('deleteCertificate.confirm')}
        handleSubmit={handleDeleteCertificate}
        onClose={() => setShowDeleteCertificate(false)}
      />
    </div>
  );
};

export default CurrentCoachAdditionalInfoForm;
