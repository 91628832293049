import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Services
import {
  setLocalizedStoryEnUseCase,
  setLocalizedStoryEsUseCase,
} from 'src/services/stories/useCases';

// Components
import EditLocalizedStoryDetail from './EditLocalizedStoryDetail';
import Tabs from 'src/ui/Tabs';

export const EditStory = () => {
  const localizedStoryDetailEn = useAppSelector((state) => state.stories.localizedStoryDetailEn);
  const localizedStoryDetailEs = useAppSelector((state) => state.stories.localizedStoryDetailEs);
  const [activeTab, setActiveTab] = useState(1);
  const params = useParams<{ storyId: string }>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (params.storyId) {
      dispatch(setLocalizedStoryEnUseCase(params.storyId, 'en-US'));
      dispatch(setLocalizedStoryEsUseCase(params.storyId, 'es-US'));
    }
  }, [dispatch, params.storyId]);

  return (
    <div
      className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary overflow-auto'
      style={{ height: '90vh' }}
    >
      <Tabs
        availability={false}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        navbar={true}
        backBtn={true}
        data={{ first: 'EN', second: 'ES' }}
        content={{
          one: <EditLocalizedStoryDetail data={localizedStoryDetailEn} />,
          two: <EditLocalizedStoryDetail data={localizedStoryDetailEs} />,
        }}
      />
    </div>
  );
};
