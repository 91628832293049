import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import StarRatings from 'react-star-ratings';
import noDescription from '../../assets/img/noDescription.svg';
import noPhoto from '../../assets/img/noPhoto.svg';
import { Link } from 'react-router-dom';

// Components
import CertificationsCard from 'src/components/cards/shared/CertificationsCard';
import Icon from 'src/ui/Icon';

// Services
import { getCurrentCoachUseCase } from 'src/services/wellness/useCases';

// Redux
import { setEditCurrentCoachView } from 'src/redux/slices/currentCoach.slice';

const CurrentCoachProfile = () => {
  const params = useParams<{ coachId: string }>();
  const currentCoach = useAppSelector((state) => state.currentCoach.currentCoach);
  const role = useAppSelector((state) => state.auth.role);

  const dispatch = useAppDispatch();
  const { t } = useTranslation(['wellness']);

  useEffect(() => {
    dispatch(getCurrentCoachUseCase());
  }, [dispatch, params.coachId, role]);

  useEffect(() => {
    dispatch(setEditCurrentCoachView(''));
  }, [dispatch]);

  return (
    <div className='overflow-auto w-full'>
      <div className='flex w-full'>
        <div className='coachInfo'>
          <div className='flex '>
            <div className='flex justify-center w-2/4'>
              <div className='coach-picture'>
                <div
                  className={`coach-preview w-48 h-48 ${
                    currentCoach.profilePicture ? 'bg-cover' : ''
                  }`}
                  style={{
                    backgroundImage: `url(${currentCoach.profilePicture || noPhoto})`,
                  }}
                />
              </div>
              <div className='coach-contact-info'>
                <div className='flex mb-2 items-center'>
                  <span className='text-xl self-center w-3/5'>{currentCoach.fullName}</span>{' '}
                  <Link className='current-coach-profile' to='/coach/edit-profile'>
                    {t('profile.edit')}
                  </Link>
                </div>
                <StarRatings
                  rating={currentCoach.rating}
                  starRatedColor='#9362C7'
                  numberOfStars={5}
                  name='rating'
                  starDimension='20px'
                  starSpacing='2px'
                />
                <span className='flex text-sm my-2 items-center'>
                  <i className='mr-1'>
                    <Icon icon='email' />
                  </i>
                  <span>{currentCoach.email}</span>
                </span>
                <span className='flex text-sm mb-2 items-center'>
                  <i className='mr-1'>
                    <Icon icon='phone' />
                  </i>
                  <span>{currentCoach.phone}</span>
                </span>
                <span className='flex text-sm mb-2 items-center'>
                  <i className='mr-1'>
                    <Icon icon='location' />
                  </i>
                  <span>
                    {currentCoach.address ? currentCoach.address : t('profile.noLocation')}
                  </span>
                </span>
              </div>
            </div>
            <div className='certificationsInfo pt-0 w-2/4'>
              <CertificationsCard certificates={currentCoach.certifications} />
            </div>
          </div>
          <div className='flex'>
            <div className='certificationsInfo w-full'>
              {currentCoach.description && (
                <span className='coach-description'>{currentCoach.description}</span>
              )}
              {!currentCoach.description && (
                <div className='flex flex-col justify-center items-center h-64'>
                  <img className='w-14' src={noDescription} alt={t('profile.altDesc')} />
                  <h1 className='my-5'>{t('profile.noDesc')}</h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentCoachProfile;
