import { useState } from 'react';
import { useAppSelector } from 'src/hooks';
import { useTranslation } from 'react-i18next';

// Components
import Tabs from 'src/ui/Tabs';
import AvailabilityList from 'src/components/cards/admin/coaches/AvailabilityList';
import CurrentCoachProfile from 'src/views/wellness/CurrentCoachProfile';

const CurrentCoachDetail = () => {
  const role = useAppSelector((state) => state.auth.role);
  const [activeTab, setActiveTab] = useState(1);
  const { t } = useTranslation(['coachDetail']);

  return (
    <div
      className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary overflow-auto'
      style={{ height: '75vh' }}
    >
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        navbar={true}
        backBtn={false}
        availability={false}
        data={{ first: role === 'admin' ? t('header') : t('currentCoach'), second: t('av') }}
        content={{
          one: <CurrentCoachProfile />,
          two: <AvailabilityList />,
        }}
      />
    </div>
  );
};

export default CurrentCoachDetail;
