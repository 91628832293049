import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import questionImg from '../../../../assets/img/question.svg';

// Components
import EditGeneralForm from 'src/components/forms/admin/stories/EditGeneralForm';
import NodeForm from 'src/components/forms/admin/stories/NodeForm';
import { useAppSelector } from 'src/hooks';
import Button from 'src/ui/Button';
import PopUp from 'src/ui/Popup';
import DecisionFooter from 'src/ui/layout/DecisionFooter';

// Interfaces
import { LocalizedStoryDetailC } from 'src/interfaces/storiesInterface';
import { EntryNodeI } from 'src/redux/state.types';

const LocalizedStoryDetail = (props: LocalizedStoryDetailC) => {
  const {
    current,
    handleStoryHeader,
    setIsPlaying,
    isPlaying,
    setShowDeleteLocalizedStory,
    setShowDeleteNodePopup,
    setShowEditNode,
    setShowEditGeneral,
    fetchChildren,
    setShowAddNode,
    setPrevious,
    setCurrentId,
    setCurrentResponseId,
    setShowEditResponse,
    previous,
    showDeleteNodePopup,
    showDeleteLocalizedStory,
    handleDeleteLocalizedStory,
    showEditGeneral,
    handleApprove,
    handleGeneralEditSuccess,
    showEditNode,
    handleNodeSuccess,
    showEditResponse,
    currentResponseId,
    showAddNode,
  } = props;
  const { t } = useTranslation(['localizedStory']);

  const storyIsPublished = useAppSelector((state) => state.stories.storyIsPublished);
  return (
    <>
      <div className='flex justify-center items-center w-full'>
        <div
          className={`flex flex-col ${
            current.type === 'Question' ? 'w-4/6' : 'w-3/5'
          } rounded-2xl p-4 bg-sidebarHover my-8`}
        >
          <div className='nodes w-full relative'>
            <span className='text-2xl mr-4 w-full flex flex-col text-center mb-4'>
              {handleStoryHeader}
            </span>
            {current.type !== 'Question' && current.medium && (
              <div className='flex justify-center object-cover' style={{ maxHeight: '350px' }}>
                <ReactPlayer
                  url={current.medium.url}
                  playing={isPlaying}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  controls
                  volume={1}
                  width='100%'
                  height='300px'
                  light={current.medium.thumbnailUrl}
                  onClickPreview={() => setIsPlaying(true)}
                />
              </div>
            )}
            <div
              className='p-4 rounded-lg mb-4'
              style={{ backgroundColor: current.type === 'Question' ? '#f3f3f3' : 'transparent' }}
            >
              {current.entryNode && (
                <div className='story-status flex justify-start'>
                  <p>
                    <b className='font-medium pr-1'>{`Status: `}</b>
                    {`${storyIsPublished ? t('publised') : t('notPublished')}`}
                  </p>
                  <Button
                    type='button'
                    extraClass='p-2 cursor-pointer w-auto bg-transparent text-secondary shadow-none text-center font-semibold border-0 hover:drop-shadow-none  flex-1 mx-1 !important'
                    onClick={
                      current.entryNode
                        ? () => setShowDeleteLocalizedStory(true)
                        : () => setShowDeleteNodePopup(true)
                    }
                  >
                    {t('deleteBtn')}
                  </Button>
                  <Button
                    type='button'
                    extraClass={`p-2 cursor-pointer mr-4 bg-bgLightPurple text-secondary flex-1`}
                    onClick={
                      current.entryNode
                        ? () => setShowEditGeneral(true)
                        : () => setShowEditNode(true)
                    }
                  >
                    {`${current.entryNode ? t('editBtn') : t('editBtn')}`}
                  </Button>
                </div>
              )}
              <div className='w-full'>
                <div className='text-2xl text-center'>
                  {current.question ? (
                    <p>
                      <b className='font-medium pr-1'>{t('question')}</b>
                      {current.question}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
                {/*current.description && (
                <p>
                  <b className='font-medium pr-1'>{`Description: `}</b>
                  {current.description}
                </p>
              )*/}
              </div>

              {!current.entryNode && (
                <div className={`flex justify-center mt-4`}>
                  {current.type !== 'Introduction' && !current.isDeleted && (
                    <div className='w-1/3 flex items-center justify-center'>
                      <Button
                        type='button'
                        extraClass='p-2 cursor-pointer bg-transparent text-secondary shadow-none text-center font-semibold border-0 hover:drop-shadow-none w-full'
                        onClick={
                          current.entryNode
                            ? () => setShowDeleteLocalizedStory(true)
                            : () => setShowDeleteNodePopup(true)
                        }
                      >
                        {`${current.entryNode ? t('deleteBtn') : t('deleteBtn')}`}
                      </Button>
                    </div>
                  )}

                  <Button
                    type='button'
                    extraClass={`p-2 cursor-pointer mr-4 bg-bgLightPurple text-secondary ${
                      current.type === 'Introduction' ? 'w-4/12' : ' w-1/3'
                    }`}
                    onClick={
                      current.entryNode
                        ? () => setShowEditGeneral(true)
                        : () => setShowEditNode(true)
                    }
                  >
                    {`${current.entryNode ? t('editBtn') : t('editBtn')}`}
                  </Button>
                  {(!fetchChildren(current.children)?.length ||
                    (current.type === 'Question' && current.children.length <= 2)) &&
                    !current.entryNode &&
                    current.type !== 'Final' &&
                    current.type !== 'Restart' && (
                      <Button
                        type='button'
                        extraClass='p-2 cursor-pointer w-1/3'
                        onClick={() => setShowAddNode(true)}
                      >
                        {current.type === 'Question' ? t('addRes') : t('addNode')}
                      </Button>
                    )}
                </div>
              )}
            </div>
          </div>
          {current.type === 'Question' && fetchChildren(current.children) && (
            <>
              <span className='text-xl font-medium mb-2'>{t('resOptions')}</span>
              <div className='flex w-full justify-center'>
                {fetchChildren(current.children).map((node: EntryNodeI) => (
                  <div key={node.id} className='w-1/3 flex justify-between'>
                    <div className='options-card-stories w-full justify-center flex flex-col'>
                      <div className='story-response'>
                        <p>
                          <b className='font-medium pr-1'>{t('type')}</b>
                          {node.type === 'LearningMoment' ? 'Learning M.' : node.type}
                        </p>
                        <p>
                          <b className='font-medium pr-1'>{t('response')}</b>
                          {`${node.statement}`}
                        </p>
                      </div>
                      <div className='flex'>
                        <Button
                          type='button'
                          extraClass={`p-2 cursor-pointer mr-4 bg-bgLightPurple text-secondary flex-1`}
                          onClick={() => {
                            /*if (node.type === 'Restart') {
                          const cover = previous.shift();
                          setPrevious([]);
                          if (cover) setCurrentId(cover);
                        } else
                        */ if (
                              node.type === 'Final' ||
                              node.type === 'LearningMoment' ||
                              node.type === 'Question' ||
                              node.type === 'Restart'
                            ) {
                              previous.push(current.id);
                              setPrevious(previous);
                              setCurrentId(node.id);
                            } else {
                              previous.push(current.id);
                              setPrevious(previous);
                              const children = fetchChildren(node.children);
                              if (children) {
                                setCurrentId(children[0].id);
                              }
                            }
                          }}
                        >
                          {t('select')}
                        </Button>
                        <Button
                          type='button'
                          extraClass={`p-2 cursor-pointer mr-4 bg-bgLightPurple text-secondary flex-1`}
                          onClick={() => {
                            setCurrentResponseId(node.id);
                            setShowEditResponse(true);
                          }}
                        >
                          {t('editBtn')}
                        </Button>
                      </div>
                      {
                        //node.statemet
                      }
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <hr
            className={`w-full border border-white ${
              current.type === 'Question' ? 'mt-8' : 'mt-0'
            }`}
          />
          <div className='flex mt-8 justify-center'>
            {
              //previous.length > 0 && (
              <div className='w-4/12 mr-4'>
                <button
                  className={`back-btn-story ${
                    previous.length === 0 ? 'shadow-none opacity-50' : 'shadow hover:shadow-lg'
                  }`}
                  disabled={previous.length === 0}
                  onClick={() => {
                    const prev = previous.pop();
                    setPrevious(previous);
                    if (current.type === 'Introduction') {
                      setCurrentId('');
                    } else {
                      if (prev) setCurrentId(prev);
                    }
                  }}
                >
                  {current.entryNode ? t('selectNextBtn') : t('backBtn')}
                </button>
              </div>
            }

            {
              // current.type !== 'Question' && current.children && fetchChildren(current.children)?.length > 0 && (
              <div className='w-4/12 mr-4'>
                <button
                  className={`text-sm font-medium rounded-xl focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 p-2 normal-case  text-white bg-secondary border-2 border-white border-solid ${
                    current.children === null ||
                    current.type === 'Question' ||
                    current.type === 'Final' ||
                    current.type === 'Restart' ||
                    (current.children && fetchChildren(current.children)?.length === 0)
                      ? 'shadow-none opacity-50'
                      : 'shadow hover:shadow-lg'
                  }`}
                  disabled={
                    current.children === null ||
                    current.type === 'Question' ||
                    current.type === 'Final' ||
                    current.type === 'Restart' ||
                    (current.children && fetchChildren(current.children)?.length === 0)
                  }
                  onClick={() => {
                    previous.push(current.id);
                    setPrevious(previous);
                    const children = fetchChildren(current.children);
                    if (current.entryNode) {
                      setCurrentId(current.entryNode.id);
                    } else if (children) {
                      setCurrentId(children[0].id);
                    }
                  }}
                >
                  {`${
                    current.type === 'Question'
                      ? current.children?.length === 0
                        ? t('addRes')
                        : t('selectRes')
                      : t('nextBtn')
                  }`}
                </button>
              </div>
            }
          </div>
        </div>
      </div>
      <PopUp
        extraClass='max-w-lg'
        extraClassesChildren='flex flex-col items-center'
        isVisible={showDeleteNodePopup}
        onClose={() => {
          setShowDeleteNodePopup(false);
        }}
      >
        <img src={questionImg} alt='magnifying glass' className='my-4' />
        <div className='text-primary text-3xl w-full text-center font-normal'>
          {t('popups.deleteNodeConfTitle')}
        </div>
        <span className='text-base mt-12 mb-4'>{t('popups.deleteNodeConfSubtitle')}</span>
        <DecisionFooter
          cancel={t('popups.cancel')}
          approve={t('popups.yesDelete')}
          onApprove={handleApprove}
          onCancel={() => setShowDeleteNodePopup(false)}
        />
      </PopUp>
      <PopUp
        extraClass='max-w-lg'
        extraClassesChildren='flex flex-col items-center'
        isVisible={showDeleteLocalizedStory}
        onClose={() => {
          setShowDeleteLocalizedStory(false);
        }}
      >
        <img src={questionImg} alt='magnifying glass' className='my-4' />
        <div className='text-primary text-3xl w-full text-center font-normal'>
          {t('popups.deleteLocalizedConfTitle')}
        </div>
        <span className='text-base mt-12 mb-4'>{t('popups.deleteLocalizedConfSubtitle')}</span>
        <DecisionFooter
          cancel={t('popups.cancel')}
          approve={t('popups.yesDelete')}
          onApprove={handleDeleteLocalizedStory}
          onCancel={() => setShowDeleteLocalizedStory(false)}
        />
      </PopUp>
      {showEditGeneral && (
        <PopUp
          isVisible={showEditGeneral}
          onClose={() => setShowEditGeneral(false)}
          extraClass='max-w-lg'
        >
          <EditGeneralForm
            onSuccess={handleGeneralEditSuccess}
            data={current}
            onClose={() => setShowEditGeneral(false)}
          />
        </PopUp>
      )}
      {showEditNode && (
        <PopUp
          isVisible={showEditNode}
          onClose={() => setShowEditNode(false)}
          extraClass='max-w-lg'
        >
          <NodeForm
            onSuccess={handleNodeSuccess}
            data={current}
            isEditing={true}
            currentNode={current?.type}
            onClose={() => setShowEditNode(false)}
            isResponse={false}
          />
        </PopUp>
      )}
      {showEditResponse && (
        <PopUp
          isVisible={showEditResponse}
          onClose={() => {
            setShowEditResponse(false);
            setCurrentResponseId('');
          }}
          extraClass='max-w-lg'
        >
          <NodeForm
            onSuccess={handleNodeSuccess}
            data={current}
            isEditing={true}
            currentNode={current?.type}
            onClose={() => {
              setShowEditResponse(false);
              setCurrentResponseId('');
            }}
            isResponse={true}
            responseId={currentResponseId}
          />
        </PopUp>
      )}
      {showAddNode && (
        <PopUp isVisible={showAddNode} onClose={() => setShowAddNode(false)} extraClass='max-w-lg'>
          <NodeForm
            onSuccess={handleNodeSuccess}
            isEditing={false}
            currentNode={current?.type}
            onClose={() => setShowAddNode(false)}
            isResponse={false}
          />
        </PopUp>
      )}
    </>
  );
};

export default LocalizedStoryDetail;
