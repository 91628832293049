import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import lock from '../../assets/img/lock.svg';

// Components
import ResetPasswordForm from 'src/components/forms/auth/ResetPasswordForm';

// Interfaces
import { ChangePasswordValuesI } from 'src/interfaces/ui/formInterface';

// Services
import { resetPasswordUseCase } from 'src/services/auth/useCases';

const ResetPassword = () => {
  const { t } = useTranslation(['changePassword']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const resetPasswordView = useAppSelector((state) => state.auth.resetPasswordView);
  const email = JSON.parse(localStorage.getItem('email') || '');
  const role = JSON.parse(localStorage.getItem('role') || '');
  const code = JSON.parse(localStorage.getItem('code') || '');

  const handleSuccess = async (values: ChangePasswordValuesI) => {
    dispatch(
      await resetPasswordUseCase({
        newPassword: values.confirmPassword,
        email: email,
        code: code,
        role: role,
      }),
    );
  };

  useEffect(() => {
    if (resetPasswordView === 'login') {
      navigate('/auth');
    }
  }, [resetPasswordView, navigate]);

  return (
    <div className='flex content-center items-center justify-center h-full'>
      <div className='w-full lg:w-4/12 px-4'>
        <div className='p-4 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-background border-0'>
          <div className='w-full flex flex-col items-center'>
            <img src={lock} alt='key' width={'30px'} />
            <span className='text-primary text-3xl mt-8'>{t('header')}</span>
            <span className='password-requirements'>{t('passwordInstructions')}</span>
            <div className='mt-12 mx-3 w-10/12'>
              <ResetPasswordForm onSuccess={(e) => handleSuccess(e)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
