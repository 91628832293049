import Datepicker from 'react-tailwindcss-datepicker';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PopoverDirectionType } from 'react-tailwindcss-datepicker/dist/types';
import { newTimes } from 'src/common/constants/availabilityOptions';
import { useAppSelector } from 'src/hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Interfaces
import { NewAvForm } from 'src/interfaces/coachesInterface';

// Components
import Button from 'src/ui/Button';
import DecisionFooter from 'src/ui/layout/DecisionFooter';
import { useEffect } from 'react';

const NewAvailabilityForm = (props: NewAvForm) => {
  const {
    isEditing,
    isEditingSpecific,
    handleCancel,
    handleDeleteSpecific,
    onSuccess,
    setShowForm,
  } = props;
  const { t } = useTranslation(['newAvailabilityForm']);
  const coachDetail = useAppSelector((state) => state.coaches.coachDetail);
  const currentAvailability = useAppSelector((state) => state.coaches.currentAvailability);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);

  const formik = useFormik({
    initialValues: {
      dateValues: {
        startDate: isEditing || isEditingSpecific ? currentAvailability.startDate : '',
        endDate: isEditing || isEditingSpecific ? currentAvailability.endDate : '',
      },
      startHour: isEditing || isEditingSpecific ? currentAvailability.startHour : '',
      endHour: isEditing || isEditingSpecific ? currentAvailability.endHour : '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      startHour: Yup.string().required(t('startHourReq')),
      endHour: Yup.string().required(t('endHourReq')),
    }),

    onSubmit: (values) => {
      onSuccess(values);
      isEditingSpecific ? '' : formik.resetForm();
      isEditingSpecific ? '' : setShowForm(false);
    },
  });

  const currentEndDate = moment(currentAvailability.endDate).add(1, 'days');
  const currentEndDateFormated = currentEndDate.format('YYYY-MM-DD');
  const specificTimes = newTimes.slice(
    newTimes.findIndex((time) => time.value === currentAvailability.startHour),
    newTimes.findIndex((time) => time.value === currentAvailability.endHour) + 1,
  );
  const generalEnd = newTimes.slice(
    newTimes.findIndex((time) => time.value === formik.values.startHour) + 8,
  );

  const specificEnd = newTimes.slice(
    newTimes.findIndex((time) => time.value === formik.values.startHour) + 2,
    newTimes.findIndex((time) => time.value === currentAvailability.endHour) + 1,
  );

  const checkRemoveSpecific = newTimes.slice(
    newTimes.findIndex((time) => time.value === currentAvailability.startHour),
    newTimes.findIndex((time) => time.value === currentAvailability.endHour) + 1,
  );

  const handleDisabled = () => {
    if (!isEditing && !isEditingSpecific) {
      if (
        !formik.values.dateValues.endDate ||
        !formik.values.dateValues.startDate ||
        !formik.values.endHour ||
        !formik.values.startHour
      ) {
        return true;
      } else return false;
    } else if (isEditing || isEditingSpecific) {
      if (
        formik.values.dateValues.startDate !== currentAvailability.startDate ||
        formik.values.dateValues.endDate !== currentAvailability.endDate ||
        formik.values.startHour !== currentAvailability.startHour ||
        formik.values.endHour !== currentAvailability.endHour
      ) {
        return false;
      } else return true;
    }
  };

  const handleMaxDate = () => {
    const maxDate: any = moment(new Date()).add(90, 'days').format('MM/DD/YY');
    return maxDate.toString();
  };

  const handleCancelForm = () => {
    formik.resetForm();
    handleCancel();
  };

  useEffect(() => {
    if (isEditing || isEditingSpecific) {
      formik.resetForm();
    }
  }, [isEditing, isEditingSpecific]);

  return (
    <form className='newAvContainer' onSubmit={formik.handleSubmit}>
      <div className='flex items-center'>
        <div className={`font-semibold pb-2 text-left text-xl`}>
          {isEditing
            ? isEditingSpecific
              ? `${t('header')} ${moment(currentAvailability.startDate).format(
                  'MM/DD/YY',
                )} - ${moment(currentAvailability.endDate).format('MM/DD/YY')} from ${moment
                  .utc(currentAvailability.startHour, 'HH:mm')
                  .format('hh:mm A')} ${t('to')} ${moment
                  .utc(currentAvailability.endHour, 'HH:mm')
                  .format('hh:mm A')}`
              : `${t('editing')} ${moment(currentAvailability.startDate).format(
                  'MM/DD/YY',
                )} - ${moment(currentAvailability.endDate).format('MM/DD/YY')} from ${moment
                  .utc(currentAvailability.startHour, 'HH:mm')
                  .format('hh:mm A')} ${t('to')} ${moment
                  .utc(currentAvailability.endHour, 'HH:mm')
                  .format('hh:mm A')}`
            : `${t('new')} ${coachDetail.fullName}${t('newAv')}`}
        </div>
        {isEditing && !isEditingSpecific && (
          <Button
            extraClass='w-auto normal-case py-2 px-4 ml-8'
            type='button'
            onClick={handleDeleteSpecific}
            disabled={checkRemoveSpecific.length === 9}
          >
            {t('deleteSpecific')}
          </Button>
        )}
      </div>
      <div className='font-semibold mb-2'>{t('date')}</div>
      <div className='w-2/4 mb-4'>
        <div className='mb-4'>{t('from')}</div>
        <Datepicker
          value={formik.values.dateValues}
          onChange={(val) => {
            formik.setFieldValue('dateValues', val);
          }}
          primaryColor={'purple'}
          useRange={true}
          popoverDirection={'down' as PopoverDirectionType}
          minDate={isEditingSpecific ? new Date(currentAvailability.startDate) : currentDate}
          maxDate={
            isEditingSpecific
              ? currentEndDateFormated && new Date(currentEndDateFormated)
              : handleMaxDate()
          }
          displayFormat={'MM/DD/YY'}
        />
      </div>
      <br />
      <div className='w-2/4 mb-8'>
        <div className='font-semibold mb-2'>{t('time')}</div>
        <div>{t('timeFrom')}</div>
        <select
          name='startHour'
          className={`w-full border-0 px-3 py-2 mb-8 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-md text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary transition-all duration-150`}
          onChange={(e) => formik.handleChange(e)}
          value={formik.values.startHour}
        >
          <option value='' disabled={true}>
            {t('selectStart')}
          </option>
          {(isEditingSpecific ? specificTimes : newTimes).map((option: any) => (
            <option value={option.value} key={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <div>{t('timeTo')}</div>
        <select
          name='endHour'
          className={`w-full border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-md text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary transition-all duration-150`}
          onChange={(e) => formik.handleChange(e)}
          value={formik.values.endHour}
        >
          <option value='' disabled={true}>
            {t('selectEnd')}
          </option>
          {(isEditingSpecific ? specificEnd : generalEnd).map((option: any) => (
            <option value={option.value} key={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      <DecisionFooter
        cancel={t('cancel')}
        onCancel={handleCancelForm}
        approve={
          isEditing ? (isEditingSpecific ? t('saveRemoveSpecific') : t('saveEdit')) : t('save')
        }
        btnWitdh={`${isEditingSpecific ? 'w-3/12' : 'w-4/12'}`}
        disabled={handleDisabled()}
      />
    </form>
  );
};

export default NewAvailabilityForm;
