import { DetailInfoI } from 'src/interfaces/coachesInterface';

const DetailInfo = (props: DetailInfoI) => {
  const { info, children } = props;

  return (
    <span className='flex text-sm mb-2 items-center'>
      <i className='mr-1'>{children}</i>
      <span>{info}</span>
    </span>
  );
};

export default DetailInfo;
