import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';

// Components
import Icon from './Icon';

// Interfaces
import { TabsProps } from 'src/interfaces/generalInterface';

const Tabs = (props: TabsProps) => {
  const { first, second, third, fourth, fifth, sixth, seventh, firstDisabled, secondDisabled } =
    props.data;
  const { one, two, three, four, five, six, seven } = props.content;
  const role = useAppSelector((state) => state.auth.role);
  const activeTab = props.activeTab;
  const setActiveTab = props.setActiveTab;
  const navigate = useNavigate();
  const handleContent = () => {
    if (activeTab === 1) {
      return one;
    } else if (activeTab === 2) {
      return two;
    } else if (activeTab === 3) {
      return three;
    } else if (activeTab === 4) {
      return four;
    } else if (activeTab === 5) {
      return five;
    } else if (activeTab === 6) {
      return six;
    } else if (activeTab === 7) {
      return seven;
    }
  };

  const handleSetActiveTab = (id: number) => {
    setActiveTab(id);
  };

  return (
    <>
      {/* Tab nav */}
      <div
        className={`flex items-center ${props.navbar ? 'justify-start' : 'justify-center'} p-3 ${
          props.navbar ? 'bg-background' : 'bg=white'
        } rounded-t-2xl`}
      >
        {props.backBtn && <Icon icon='backBtn' onClick={() => navigate(-1)} />}
        <div
          className={`${
            props.backBtn || (!props.backBtn && !props.availability) ? '' : ''
          } flex items-center ml-4 justify-between bg-gradient-to-r from-cyan-500 to-blue-500`}
        >
          <button
            className={`flex font-semibold text-sm ${
              props.navbar ? 'py-2 px-4' : 'p-2'
            } hover:text-darkBlueHim ${
              activeTab === 1
                ? props.navbar
                  ? 'text-primary border-b-2 border-primary'
                  : 'underline underline-offset-4'
                : 'opacity-50'
            } ${role === 'coach' ? 'uppercase' : ''}`}
            onClick={() => setActiveTab(1)}
            disabled={firstDisabled}
          >
            {first}
          </button>
          <button
            className={`flex font-semibold text-sm ${
              props.navbar ? 'py-2 px-4' : 'p-2'
            }  hover:text-darkBlueHim ${
              activeTab === 2
                ? props.navbar
                  ? 'text-primary border-b-2 border-primary'
                  : 'underline underline-offset-4'
                : 'opacity-50'
            } ${role === 'coach' ? 'uppercase' : ''}`}
            onClick={() => setActiveTab(2)}
            disabled={secondDisabled}
          >
            {second}
          </button>
          {third && (
            <button
              className={`flex font-semibold text-sm ${
                props.navbar ? 'py-2 px-4' : 'p-2'
              } hover:text-darkBlueHim ${
                activeTab === 3
                  ? props.navbar
                    ? 'text-primary border-b-2 border-primary'
                    : 'underline underline-offset-4'
                  : 'opacity-50'
              }`}
              onClick={() => handleSetActiveTab(3)}
            >
              {third}
            </button>
          )}
          {fourth && (
            <button
              className={`flex font-semibold text-sm ${
                props.navbar ? 'py-2 px-4' : 'p-2'
              } hover:text-darkBlueHim ${
                activeTab === 4
                  ? props.navbar
                    ? 'text-primary border-b-2 border-primary'
                    : 'underline underline-offset-4'
                  : 'opacity-50'
              }`}
              onClick={() => handleSetActiveTab(4)}
            >
              {fourth}
            </button>
          )}
          {fifth && (
            <button
              className={`flex font-semibold text-sm ${
                props.navbar ? 'py-2 px-4' : 'p-2'
              } hover:text-darkBlueHim ${
                activeTab === 5
                  ? props.navbar
                    ? 'text-primary border-b-2 border-primary'
                    : 'underline underline-offset-4'
                  : 'opacity-50'
              }`}
              onClick={() => handleSetActiveTab(5)}
            >
              {fifth}
            </button>
          )}
          {sixth && (
            <button
              className={`flex font-semibold text-sm ${
                props.navbar ? 'py-2 px-4' : 'p-2'
              } hover:text-darkBlueHim ${
                activeTab === 6
                  ? props.navbar
                    ? 'text-primary border-b-2 border-primary'
                    : 'underline underline-offset-4'
                  : 'opacity-50'
              }`}
              onClick={() => handleSetActiveTab(6)}
            >
              {sixth}
            </button>
          )}
          {seventh && (
            <button
              className={`flex font-semibold text-sm ${
                props.navbar ? 'py-2 px-4' : 'p-2'
              } hover:text-darkBlueHim ${
                activeTab === 7
                  ? props.navbar
                    ? 'text-primary border-b-2 border-primary'
                    : 'underline underline-offset-4'
                  : 'opacity-50'
              }`}
              onClick={() => handleSetActiveTab(7)}
            >
              {seventh}
            </button>
          )}
        </div>
      </div>
      <div className='outlet-coach-profile'>{handleContent()}</div>
    </>
  );
};

export default Tabs;
