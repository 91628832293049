import { useTranslation } from 'react-i18next';
import noCertificate from '../../../assets/img/without-sessions.svg';

// Interfaces
import { Certifications } from 'src/interfaces/coachesInterface';
import IndividualCertification from '../admin/coaches/IndividualCertification';

const CertificationsCard = (certificates: any) => {
  const { t } = useTranslation(['coaches']);

  return (
    <div className='roundedCard p-4 '>
      <div className='flex items-center'>
        <span className='text-base font-semibold text-left mb-4'>{t('cert')}</span>
      </div>
      <div
        className={`flex flex-col overflow-auto h-36 justify-start ${
          certificates.certificates && certificates?.certificates?.length !== 0
            ? 'items-start'
            : 'items-center'
        }`}
      >
        {(!certificates.certificates || certificates.certificates.length === 0) && (
          <div className='flex flex-col justify-center items-center h-64'>
            <img className='w-14' src={noCertificate} alt='avatar coach' />
            <h1 className='my-5'>{t('noCert')}</h1>
          </div>
        )}
        {certificates.certificates &&
          certificates.certificates.map((certificate: Certifications) => (
            <IndividualCertification
              key={certificate.id}
              certificate={certificate}
              isEditing={false}
            />
          ))}
      </div>
    </div>
  );
};

export default CertificationsCard;
