import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import StoriesImg from '../../../assets/img/stories.svg';
import { Link } from 'react-router-dom';
import { WritableDraft } from 'immer/dist/internal';

// Redux
import { setStoryIsPublished } from 'src/redux/slices/stories.slice';

// Interfaces
import { StoryNodeFormI } from 'src/interfaces/storiesInterface';
import { EntryNodeI } from 'src/redux/state.types';

// Services
import {
  addStoryNodeUseCase,
  deleteLocalizedStoryUseCase,
  deleteNodeUseCase,
  editGeneralStoryUseCase,
  editStoryNodeUseCase,
} from 'src/services/stories/useCases';
import LocalizedStoryDetail from 'src/components/cards/admin/stories/LocalizedStoryDetail';

const findChild = (
  id: string,
  children?: Array<WritableDraft<EntryNodeI>> | null,
): WritableDraft<EntryNodeI> | null => {
  if (!children) return null;

  let child: WritableDraft<EntryNodeI> | null = children.find((child) => child.id === id) || null;

  if (!child) {
    children.forEach((c) => {
      child = child || findChild(id, c.children);
    });
  }

  return child || null;
};

const EditLocalizedStoryDetail = (props: { data: any }) => {
  const storyView = useAppSelector((state) => state.stories.storyView);
  const params = useParams<{ storyId: string }>();
  localStorage.setItem('currentStoryId', JSON.stringify(params.storyId));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['localizedStory']);
  const [showEditGeneral, setShowEditGeneral] = useState<boolean>(false);
  const [showAddNode, setShowAddNode] = useState<boolean>(false);
  const [showEditNode, setShowEditNode] = useState<boolean>(false);
  const [showEditResponse, setShowEditResponse] = useState<boolean>(false);
  const [showDeleteNodePopup, setShowDeleteNodePopup] = useState<boolean>(false);
  const [showDeleteLocalizedStory, setShowDeleteLocalizedStory] = useState<boolean>(false);
  const [previous, setPrevious] = useState<Array<string>>([]);
  const [currentId, setCurrentId] = useState<string>('');
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentResponseId, setCurrentResponseId] = useState<string>('');

  const handleGeneralEditSuccess = async (values: any) => {
    await dispatch(editGeneralStoryUseCase({ data: props.data, newValues: values }));
    setShowEditGeneral(false);
  };

  const findNode = () => {
    if (props.data?.id) {
      const intro = props.data.entryNode;
      if (currentId) {
        return currentId === intro.id ? intro : findChild(currentId, intro.children);
      } else {
        return props.data;
      }
    }
    return null;
  };

  const current = findNode();

  const handleNodeSuccess = (values: StoryNodeFormI) => {
    if (values.isEditing && !values.isResponse) {
      dispatch(editStoryNodeUseCase({ id: currentId, data: values, current: current }));
      setShowEditNode(false);
    } else if (values.isEditing && values.isResponse) {
      dispatch(editStoryNodeUseCase({ id: currentResponseId, data: values, current: current }));
      setShowEditResponse(false);
      setCurrentResponseId('');
    } else if (!values.isEditing) {
      if (values.medium) {
        const nodeMediaFormData = new FormData();
        nodeMediaFormData.append('file', values.medium);
        nodeMediaFormData.append('thumbnail', values.thumbnail);
        dispatch(addStoryNodeUseCase({ data: values, id: current.id, media: nodeMediaFormData }));
        setShowAddNode(false);
      } else {
        dispatch(addStoryNodeUseCase({ data: values, id: current.id }));
        setShowAddNode(false);
      }
    }
  };

  const handleApprove = () => {
    dispatch(deleteNodeUseCase(currentId));
    const prev = previous.pop();
    setPrevious(previous);
    if (prev) {
      setCurrentId(prev);
    }
    setShowDeleteNodePopup(false);
  };

  const handleDeleteLocalizedStory = () => {
    setShowDeleteLocalizedStory(false);
    dispatch(deleteLocalizedStoryUseCase(current.id, current.locale));
  };

  const fetchChildren = (children?: any) => {
    return children?.filter((node: any) => !node.isDeleted);
  };

  const handleStoryHeader = () => {
    if (current.entryNode) {
      return (
        <p>
          <b className='font-medium pr-1'>{t('title')}</b>
          {`${current.title}`}
        </p>
      );
    } else {
      if (current.type === 'LearningMoment') {
        return (
          <p>
            <b className='font-medium pr-1'>{t('type')}</b>
            {`Learning moment`}
          </p>
        );
      } else
        return (
          <p>
            <b className='font-medium pr-1'>{t('type')}</b>
            {`${current.type}`}
          </p>
        );
    }
  };
  useEffect(() => {
    if (props.data.isPublished) {
      dispatch(setStoryIsPublished(true));
    } else dispatch(setStoryIsPublished(false));
  }, [dispatch, props.data.isPublished]);

  useEffect(() => {
    setCurrentId('');
    setPrevious([]);
  }, [props.data.locale]);

  useEffect(() => {
    if (storyView === 'list') {
      navigate('/admin/stories');
    }
  }, [navigate, storyView]);

  if (!current || props.data.isDeleted) {
    return (
      <div className='flex flex-col items-center justify-center'>
        <img src={StoriesImg} alt='no story' width={150} />
        <div className='mt-8 text-2xl font-medium'>{t('noStory')}</div>
        <Link
          to={`/admin/stories/${params.storyId}/add-localized-story`}
          className='linkBtn w-auto h-8 px-4 mt-8'
        >
          {t('createStory')}
        </Link>
      </div>
    );
  }

  return (
    <LocalizedStoryDetail
      current={current}
      handleStoryHeader={handleStoryHeader()}
      setIsPlaying={setIsPlaying}
      setShowAddNode={setShowAddNode}
      setShowDeleteLocalizedStory={setShowDeleteLocalizedStory}
      setCurrentId={setCurrentId}
      setCurrentResponseId={setCurrentResponseId}
      setPrevious={setPrevious}
      setShowDeleteNodePopup={setShowDeleteNodePopup}
      setShowEditGeneral={setShowEditGeneral}
      setShowEditNode={setShowEditNode}
      setShowEditResponse={setShowEditResponse}
      showEditGeneral={showEditGeneral}
      showAddNode={showAddNode}
      isPlaying={isPlaying}
      fetchChildren={fetchChildren}
      showDeleteNodePopup={showDeleteNodePopup}
      showDeleteLocalizedStory={showDeleteLocalizedStory}
      previous={previous}
      handleApprove={handleApprove}
      showEditNode={showEditNode}
      showEditResponse={showEditResponse}
      handleNodeSuccess={handleNodeSuccess}
      handleDeleteLocalizedStory={handleDeleteLocalizedStory}
      handleGeneralEditSuccess={handleGeneralEditSuccess}
      currentResponseId={currentResponseId}
    />
  );
};

export default EditLocalizedStoryDetail;
