// Components
import UserDetail from './UserDetail';

const ReviewUser = () => {
  return (
    <div className='flex flex-col'>
      <UserDetail review={true} />
    </div>
  );
};

export default ReviewUser;
