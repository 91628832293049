import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import getLocation from '../../assets/countries.json';
import { useTranslation } from 'react-i18next';

// Redux
import { setEditCurrentCoachView } from 'src/redux/slices/currentCoach.slice';

// Components
import CoachGeneralForm from 'src/components/forms/admin/coaches/CoachGeneralForm';

// Interfaces
import { CoachFormikFormProps } from 'src/interfaces/ui/formInterface';

// Services
import { getCurrentCoachUseCase, updateCurrentCoachUseCase } from 'src/services/wellness/useCases';
import ConfirmationPopup from 'src/ui/popups/ConfirmationPopup';

const EditCurrentCoach = () => {
  const currentCoach = useAppSelector((state) => state.currentCoach.currentCoach);
  const editCurrentCoachView = useAppSelector((state) => state.currentCoach.editCurrentCoachView);
  const [editInfo, setEditInfo] = useState({});
  const [showEditCurrentCoach, setShowEditCurentCoach] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['addEditCoachForm']);
  const navigate = useNavigate();
  const location = getLocation.states;
  let stateEditing: any = '';

  const findState = (stateName: any) => {
    const state = location.find((state) => state.state_code === stateName);
    stateEditing = state;
    return state?.id;
  };

  const findCity = (cityName: any) => {
    if (stateEditing) {
      const city = stateEditing.cities.find((city: any) => city.name === cityName);
      return city?.id;
    }
  };

  const handleSuccess = async (values: CoachFormikFormProps) => {
    const editLocation = {
      stateId: values.location?.stateId,
      cityId: values.location?.cityId,
    };

    const editValues: any = {};
    if (currentCoach.fullName !== values.fullName) editValues.fullName = values.fullName;
    if (currentCoach.email !== values.email) editValues.email = values.email;
    if (currentCoach.phone !== values.phone) editValues.phone = values.phone;
    if (values.location?.stateId) {
      if (findState(currentCoach.state) !== Number(values.location?.stateId))
        editValues.location = editLocation;
      if (findCity(currentCoach.city) !== Number(values.location?.cityId))
        editValues.location = editLocation;
    }
    if (currentCoach.description !== values.description)
      editValues.description = values.description;
    const updateCoachGeneralInfo = Object.assign({}, editValues);
    if (
      updateCoachGeneralInfo.fullName ||
      updateCoachGeneralInfo.email ||
      updateCoachGeneralInfo.phone ||
      updateCoachGeneralInfo.description ||
      updateCoachGeneralInfo.location
    ) {
      setEditInfo(updateCoachGeneralInfo);
      setShowEditCurentCoach(true);
    } else {
      dispatch(setEditCurrentCoachView('additional-info'));
    }
  };

  const handleEdit = () => {
    dispatch(updateCurrentCoachUseCase({ updatedCoach: editInfo }));
  };

  useEffect(() => {
    if (editCurrentCoachView === 'additional-info') {
      navigate(`/coach/edit-profile/additional-info`);
    }
  }, [editCurrentCoachView, navigate]);

  useEffect(() => {
    dispatch(getCurrentCoachUseCase());
  }, [dispatch]);

  return (
    <>
      <CoachGeneralForm onSuccess={handleSuccess} data={currentCoach} isEditing={true} />
      <ConfirmationPopup
        isVisible={showEditCurrentCoach}
        title={t('editAlert.title')}
        subtitle={t('editAlert.subtitleCurrent')}
        submitBtn={t('editAlert.confirm')}
        handleSubmit={handleEdit}
        onClose={() => setShowEditCurentCoach(false)}
      />
    </>
  );
};

export default EditCurrentCoach;
