// Interfaces
import { LoginI } from 'src/interfaces/generalInterface';

// Redux
import {
  setChangePasswordView,
  setRefreshToken,
  setResetPasswordView,
  setRole,
  setToken,
} from 'src/redux/slices/auth.slice';
import { setIsLoading, setResponsePopup } from 'src/redux/slices/generalSlice';
import { AppThunk } from 'src/redux/store';

// Services
import {
  changePasswordAction,
  forgotPasswordAction,
  loginAction,
  refreshTokenAction,
  resetPasswordAction,
  validateCodeAction,
} from './requests';

export const changePasswordUseCase =
  (props: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    role: string;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    changePasswordAction(props)
      .then((res) => {
        if (res.data.message === 'Password changed successfully') {
          localStorage.removeItem('him-auth-token');
          dispatch(setToken(''));
          dispatch(setChangePasswordView('updated'));
          dispatch(setRole(''));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'Password changed successfully',
              submitBtn: 'Ok',
            }),
          );
        }
        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.title === 'Code.InvalidCurrentPassword') {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Ups',
              subtitle: 'Current password is not valid',
              submitBtn: 'Ok',
            }),
          );
        }
      });
  };

// Validate code use case
export const validateCodeUseCase =
  (props: { email: string; recoveryCode: string; role: string }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await validateCodeAction({
      email: props.email,
      recoveryCode: props.recoveryCode,
      role: props.role,
    })
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status == 200) {
          localStorage.setItem('role', JSON.stringify(props.role));
          localStorage.setItem('email', JSON.stringify(props.email));
          localStorage.setItem('code', JSON.stringify(props.recoveryCode));
          dispatch(setResetPasswordView('change-password'));
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Ups!',
              subtitle: 'Invalid code. Please review it and try again.',
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// forgot password use case
export const forgotPasswordUseCase =
  (props: { email: string; role: string }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await forgotPasswordAction({ email: props.email, role: props.role })
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status == 200) {
          dispatch(setResetPasswordView('login'));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'Please check your email, we have sent you a link.',
              submitBtn: 'Ok',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.title === 'User.NotFound') {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Ups!',
              subtitle: 'The user could not be found, please check your email and try again.',
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// reset password use case
export const resetPasswordUseCase =
  (props: { code: string; email: string; role: string; newPassword: string }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await resetPasswordAction(props)
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status == 200) {
          dispatch(setResetPasswordView('login'));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'Your password has been successfully updated.',
              submitBtn: 'Ok',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'error',
            header: 'Ups!',
            subtitle: err.response.data.detail,
            submitBtn: 'Ok',
          }),
        );
      });
    return data;
  };

// Login use case
export const loginUseCase =
  (user: LoginI): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await loginAction(user)
      .then((res) => {
        dispatch(setIsLoading(false));
        localStorage.setItem('him-auth-token', JSON.stringify(res.data.data.access_token));
        dispatch(setToken(res.data.data.access_token));
        dispatch(setRefreshToken(res.data.data.refresh_token));
        if (user.role === 'Admin') {
          dispatch(setRole('admin'));
          localStorage.setItem('him-role', JSON.stringify('admin'));
        } else {
          dispatch(setRole('coach'));
          localStorage.setItem('him-role', JSON.stringify('coach'));
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.detail === 'Your user is deactivated, you cannot login.') {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Ups!',
              subtitle: 'Your acccount is deactivated.',
              submitBtn: 'Ok',
            }),
          );
        } else if (err.response.data.detail === 'The user could not be found or does not exists') {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Ups!',
              subtitle: 'Invalid email.',
              submitBtn: 'Ok',
            }),
          );
        } else if (err.response.data.detail === 'One or more validation failures have occurred.') {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Ups!',
              subtitle: 'Invalid password.',
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// refresh token use case
export const refreshTokenUseCase =
  (refreshToken: any): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    refreshTokenAction(refreshToken)
      .then((res) => {
        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        console.log('error refreshingt token', err);
      });
  };
