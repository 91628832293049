import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Services
import {
  setLocalizedStoryEnUseCase,
  setLocalizedStoryEsUseCase,
} from 'src/services/stories/useCases';

// Components
import ViewStoryDetail from 'src/components/cards/admin/stories/ViewStoryDetail';
import Tabs from 'src/ui/Tabs';

const StoryDetail = () => {
  const localizedStoryDetailEn = useAppSelector((state) => state.stories.localizedStoryDetailEn);
  const localizedStoryDetailEs = useAppSelector((state) => state.stories.localizedStoryDetailEs);
  const params = useParams<{ storyId: string; activeLang: string }>();
  const [activeTab, setActiveTab] = useState(
    params.activeLang && params.activeLang === '1' ? 1 : 2,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (params.storyId) {
      if (activeTab === 1) dispatch(setLocalizedStoryEnUseCase(params.storyId, 'en-US'));
      else dispatch(setLocalizedStoryEsUseCase(params.storyId, 'es-US'));
    }
  }, [dispatch, params.storyId, activeTab]);

  return (
    <div
      className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary overflow-auto'
      style={{ height: '90vh' }}
    >
      <Tabs
        availability={false}
        navbar={true}
        backBtn={true}
        data={{ first: 'EN', second: 'ES' }}
        content={{
          one: <ViewStoryDetail data={localizedStoryDetailEn} initialLang={'EN'} />,
          two: <ViewStoryDetail data={localizedStoryDetailEs} initialLang={'ES'} />,
        }}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

export default StoryDetail;
