import { Link } from 'react-router-dom';
import Portal from '../Portal';
import { TableOptionsI } from 'src/interfaces/generalInterface';
import { useTranslation } from 'react-i18next';

const TableOptions = (props: TableOptionsI) => {
  const {
    setPopperElement,
    setShowFree,
    setShowDelete,
    setShowStatus,
    access,
    id,
    user,
    thirdOption,
    currentCoachStatus,
    currentUserStatus,
    popperAttributes,
    popperStlye,
    currentUser,
  } = props;
  const { t } = useTranslation('tableDropdown');

  return (
    <Portal>
      <div
        ref={setPopperElement}
        style={popperStlye}
        {...popperAttributes}
        className={`absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48`}
      >
        <Link
          to={`${
            thirdOption
              ? user
                ? `/admin/users/${id}/edit-user`
                : `/admin/coaches/${id}/edit-coach`
              : `/admin/stories/${id}/edit-story`
          }`}
          className='dropdown'
        >
          {t('edit')}
        </Link>
        <button className='dropdown' onClick={() => setShowDelete(true)}>
          {t('delete')}
        </button>
        {thirdOption && (
          <button className='dropdown' onClick={() => setShowStatus(true)}>
            {user &&
              (currentUserStatus === 'Registered' || currentUserStatus === 'PendingReview'
                ? t('UpperDeactivate')
                : t('UpperActivate'))}
            {!user &&
              (currentCoachStatus === 'Registered' || currentCoachStatus === 'PendingReview'
                ? t('UpperDeactivate')
                : t('UpperActivate'))}
          </button>
        )}
        {access && (
          <button className='dropdown' onClick={() => setShowFree(true)}>
            {t('free')}
          </button>
        )}
        {currentUser?.status === 'PendingReview' && (
          <Link to={`/admin/users/${id}/review-profile`} className='dropdown'>
            {t('reviewProfile')}
          </Link>
        )}
      </div>
    </Portal>
  );
};

export default TableOptions;
