import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Components
import CurrentCoachAdditionalInfoForm from 'src/components/forms/wellness/CurrentCoachAdditionalInfoForm';

// Services
import {
  getCurrentCoachUseCase,
  updateCurrentCoachAdditionalInfoUseCase,
} from 'src/services/wellness/useCases';

// Redux
import { setEditCurrentCoachView } from 'src/redux/slices/currentCoach.slice';

const EditCurrentCoachAdditionalInfo = () => {
  const currentCoach = useAppSelector((state) => state.currentCoach.currentCoach);
  const editCurrentCoachView = useAppSelector((state) => state.currentCoach.editCurrentCoachView);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSuccess = async (values: { profilePicture: any; certificates: [] }) => {
    const formData = new FormData();
    formData.append('profilePicture', values.profilePicture);
    dispatch(
      updateCurrentCoachAdditionalInfoUseCase({
        profilePicture: formData,
        certificates: values.certificates,
      }),
    );
    dispatch(setEditCurrentCoachView('finish'));
  };

  useEffect(() => {
    if (editCurrentCoachView === 'finish') {
      navigate(`/coach/profile`);
    }
  }, [editCurrentCoachView, navigate]);

  useEffect(() => {
    dispatch(getCurrentCoachUseCase());
  }, [dispatch]);

  return (
    <CurrentCoachAdditionalInfoForm
      onSuccess={handleSuccess}
      data={currentCoach}
      isEditing={true}
    />
  );
};

export default EditCurrentCoachAdditionalInfo;
