import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import Icon from 'src/ui/Icon';

// Components
import CoachRow from '../../../rows/CoachRow';

// Redux
import { setCoachDetailView, setEditCoachDetailView } from 'src/redux/slices/coaches.slice';

// Interfaces
import { CoachesResponse } from 'src/redux/state.types';
import { Coaches, CoachesTableProps } from 'src/interfaces/coachesInterface';

// Services
import { getCoachesListUseCase } from 'src/services/coaches/useCases';

const CoachesCard = (props: CoachesTableProps) => {
  const coaches: CoachesResponse = useAppSelector((state) => state.coaches.coaches);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useAppDispatch();
  const { color } = props;
  const { t } = useTranslation(['coaches']);

  const coachTableHeaders = [
    { title: t('firstCol'), id: 1, space: '20%' },
    { title: t('secondCol'), id: 2, space: '20%' },
    { title: t('thirdCol'), id: 3, space: '15%' },
    { title: t('fourthCol'), id: 4, space: '15%' },
    { title: t('fifthCol'), id: 5, space: '15%' },
    { title: t('sixthCol'), id: 6, space: '15%' },
  ];

  useEffect(() => {
    dispatch(getCoachesListUseCase({ page: currentPage, limit: 10 }));
    dispatch(setCoachDetailView(''));
    dispatch(setEditCoachDetailView(''));
  }, [dispatch, currentPage]);

  return (
    <div
      className={`relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary "
          ${color === 'light' ? 'bg-white' : 'bg-sky-900 text-white'}`}
    >
      <div className='rounded-t mb-0 px-4 py-3 border-0'>
        <div className='flex flex-wrap items-center'>
          <div className='flex justify-between relative w-full px-4 max-w-full flex-grow flex-1'>
            <span className='flex'>
              <div className={`him-coaches mr-2 text-lg`}>{t('header')}</div>
              <Link to='/admin/coaches/add-coach' className='linkBtn'>
                +
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className='block w-full overflow-x-auto'>
        <div className='items-center bg-transparent border-collapse'>
          <div className='flex'>
            {coachTableHeaders.map((header) => (
              <div
                key={header.id}
                style={{ width: header.space }}
                className={`usersTableHeader " +
                    ${
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-sky-800 text-sky-300 border-sky-700'
                    })`}
              >
                {header.title}
              </div>
            ))}
          </div>
          <div className='card-body'>
            {coaches.data.map((coach: Coaches) => (
              <CoachRow
                key={coach.id}
                profilePicture={coach.profilePicture}
                fullName={coach.fullName}
                email={coach.email}
                phone={coach.phone}
                address={coach.address}
                id={coach.id}
                status={coach.status}
                rating={coach.rating}
              />
            ))}
          </div>
          <div className='him-pagination'>
            <Icon
              icon='left'
              extraClass={`mr-4 ${
                coaches.metadata?.totalCount === 0 || currentPage === 1 ? '' : null
              }`}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={coaches.metadata?.totalCount === 0 || currentPage === 1}
            />
            <Icon
              icon='right'
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={
                coaches.metadata?.totalCount === 0 || currentPage === coaches.metadata?.pageCount
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachesCard;
