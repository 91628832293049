import { Action, configureStore, ThunkAction, getDefaultMiddleware } from '@reduxjs/toolkit';
import authSlice from './slices/auth.slice';
import coachesSlice from './slices/coaches.slice';
import currentCoachSlice from './slices/currentCoach.slice';
import generalSlice from './slices/generalSlice';
import storiesSlice from './slices/stories.slice';
import usersSlice from './slices/users.slice';
import wellnessSlice from './slices/wellness.slice';

const store = configureStore({
  reducer: {
    users: usersSlice,
    coaches: coachesSlice,
    auth: authSlice,
    general: generalSlice,
    wellness: wellnessSlice,
    stories: storiesSlice,
    currentCoach: currentCoachSlice,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
