import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Redux
import { setAddUserView, setEditUserView } from 'src/redux/slices/users.slice';

// Components
import UserPhotosForm from 'src/components/forms/admin/users/UserPhotosForm';

// Interfaces
import { userDetailGeneral, UsersDetailAllInfo } from 'src/interfaces/usersInterface';

// Services
import { getUserDetailUseCase, uploadAllUserPhotosAction } from 'src/services/users/useCases';

const EditUserPhotos = () => {
  const params = useParams<{ userId: string }>();
  const userDetail: userDetailGeneral = useAppSelector((state) => state.users.userDetail);
  const editUserView = useAppSelector((state) => state.users.editUserView);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleNavigation = () => {
    dispatch(setAddUserView('profile-picture'));
    dispatch(setEditUserView('profile-picture'));
    navigate(-1);
  };

  const handleSuccess = async (values: UsersDetailAllInfo) => {
    if (values.photos) {
      await dispatch(uploadAllUserPhotosAction(values.photos));
    }
    dispatch(setEditUserView('finish'));
  };

  useEffect(() => {
    if (editUserView === 'finish') {
      navigate('/admin/users');
    }
  }, [editUserView, navigate, params.userId]);

  useEffect(() => {
    if (params.userId) {
      dispatch(getUserDetailUseCase(params.userId));
    }
  }, [dispatch, params.userId]);

  return (
    <UserPhotosForm
      onSuccess={handleSuccess}
      data={userDetail}
      isEditing={true}
      handleNav={handleNavigation}
    />
  );
};

export default EditUserPhotos;
