import { createSlice } from '@reduxjs/toolkit';
import { GeneralState } from '../state.types';

const initialState: GeneralState = {
  isLoading: false,
  responsePopup: {
    isVisible: false,
    type: '',
    header: '',
    subtitle: '',
    submitBtn: '',
  },
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setResponsePopup: (state, action) => {
      state.responsePopup = action.payload;
    },
  },
});

export const { setIsLoading, setResponsePopup } = generalSlice.actions;

export default generalSlice.reducer;
