import Resizer from 'react-image-file-resizer';

const sizes = {
  'image-width': 2048,
  'image-height': 2048,
};

export const ResizerImage = (file: File) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      sizes['image-width'] - 1,
      sizes['image-height'] - 1,
      'JPEG',
      90,
      0,
      (uri: any) => {
        resolve(uri);
      },
      'file',
    );
  });

export const validateImageDimension = (image: File) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = function (value) {
      const img = new Image();
      img.src = value.target?.result as string;
      img.onload = function () {
        resolve(img.width < sizes['image-width'] && img.height < sizes['image-height']);
      };
    };
  });
