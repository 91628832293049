// Interfaces
import { selectProps } from '../interfaces/ui/formInterface';

const Select = (props: selectProps) => {
  const {
    placeholder,
    name,
    value,
    haveError,
    onBlur,
    onChange,
    extraClass,
    reference,
    disabled,
    options,
    opDisabled,
  } = props;
  return (
    <select
      name={name}
      className={`${haveError !== undefined ? 'border border-alert' : ''} ${extraClass || ''} ${
        disabled ? 'qp-inp-disabled' : ''
      } border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary transition-all duration-150`}
      onChange={(e) => onChange(e)}
      onBlur={() => onBlur}
      ref={reference}
      disabled={disabled}
      value={value}
    >
      <option value='' disabled={true}>
        {placeholder}
      </option>
      {options.map((option: any) => (
        <option disabled={opDisabled} value={option.value} key={option.id}>
          {option.value}
        </option>
      ))}
    </select>
  );
};

export default Select;
