import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';
import notFound from '../assets/img/404.svg';

// Components
import Button from 'src/ui/Button';

const NotFound = () => {
  const navigate = useNavigate();
  const role = useAppSelector((state) => state.auth.role);
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const { t } = useTranslation(['notFound']);

  if (!isAuthenticated) {
    return <Navigate to='/' />;
  }

  return (
    <div className='flex-col items-center justify-center w-full mx-auto h-screen flex bg-background'>
      <div className='text-center'>
        <img src={notFound} className='mx-auto max-w-xl' alt='page not found' />
        <div className='text-primary text-3xl w-full text-center font-semibold'>{t('info')}</div>
        <Button
          extraClass='w-44 px-4 py-3 font-semibold normal-case mt-8'
          onClick={() => (role === 'admin' ? navigate('/admin/users') : navigate('/coach/profile'))}
        >
          {t('btn')}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
