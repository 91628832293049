import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import adminImg from '../../assets/img/adminFinal.png';
import coachImg from '../../assets/img/coachFinal.png';
import { usePopper } from 'react-popper';
import Portal from '../Portal';
import useOutsideClick from 'src/hooks/ClickOutside';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Redux
import { setRole, setToken } from 'src/redux/slices/auth.slice';
import { useTranslation } from 'react-i18next';

const UserDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  null;
  const [popperElement, setPopperElement] = useState<any | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });
  const role = useAppSelector((state) => state.auth.role);
  const menuRef: any = useRef();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['navbar']);

  const closePopover = () => {
    setIsOpen(false);
  };
  const ref: any = useOutsideClick(closePopover);

  const handleLogOut = () => {
    localStorage.removeItem('him-auth-token');
    dispatch(setToken(''));
    navigate('/auth');
    dispatch(setRole(''));
  };

  return (
    <div ref={ref}>
      <div className='relative' ref={setReferenceElement}>
        <button
          ref={menuRef}
          className='w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full'
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        >
          <img
            alt='role'
            className='w-full h-full rounded-full align-middle border-none shadow-lg object-cover bg-background'
            src={role === 'admin' ? adminImg : coachImg}
          />
        </button>
      </div>
      {isOpen && (
        <Portal>
          <div
            id='popover'
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={`absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48`}
          >
            <button
              type='button'
              className='text-center text-sm py-1 px-2 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 hover:bg-background'
              onClick={handleLogOut}
            >
              {t('logOut')}
            </button>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default UserDropdown;
