import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import StarRatings from 'react-star-ratings';
import noDescription from '../../../assets/img/noDescription.svg';
import noPhoto from '../../../assets/img/noPhoto.svg';
import { Link } from 'react-router-dom';

// Components
import CertificationsCard from 'src/components/cards/shared/CertificationsCard';
import Icon from 'src/ui/Icon';

// Services
import { getCoachDetailUseCase } from 'src/services/coaches/useCases';
import DetailInfo from 'src/components/cards/shared/DetailInfo';

const CoachProfile = () => {
  const params = useParams<{ coachId: string }>();
  const coachDetail = useAppSelector((state) => state.coaches.coachDetail);
  const role = useAppSelector((state) => state.auth.role);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['coaches']);

  useEffect(() => {
    if (role === 'admin' && params.coachId) {
      dispatch(getCoachDetailUseCase(params.coachId));
    }
  }, [dispatch, params.coachId, role]);

  return (
    <div className='overflow-auto w-full'>
      <div className='flex w-full'>
        <div className='coachInfo'>
          <div className='flex '>
            <div className='flex justify-center w-2/4'>
              <div className='coach-picture'>
                <div
                  className={`coach-preview w-48 h-48 ${
                    coachDetail.profilePicture ? 'bg-cover' : ''
                  }`}
                  style={{
                    backgroundImage: `url(${coachDetail.profilePicture || noPhoto})`,
                  }}
                />
              </div>
              <div className='coach-contact-info'>
                <div className='flex mb-2'>
                  <span className='text-xl self-center w-3/5'>{coachDetail.fullName}</span>{' '}
                  {role === 'coach' && (
                    <Link
                      className=' h-2/4 flex items-center bg-secondary text-white border-2 border-white border-solid active:bg-blueGray-600 text-sm font-medium p-2 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150'
                      to='/coach/edit-profile/1'
                    >
                      {t('coachProfile.edit')}
                    </Link>
                  )}
                </div>
                <div className='mb-2'>
                  <StarRatings
                    rating={coachDetail.rating}
                    starRatedColor='#9362C7'
                    numberOfStars={5}
                    name='rating'
                    starDimension='20px'
                    starSpacing='2px'
                  />
                </div>
                <DetailInfo info={coachDetail.email}>
                  <Icon icon='email' />
                </DetailInfo>
                <DetailInfo info={coachDetail.phone}>
                  <Icon icon='phone' />
                </DetailInfo>
                <DetailInfo
                  info={coachDetail.address ? coachDetail.address : t('coachProfile.noLocation')}
                >
                  <Icon icon='location' />
                </DetailInfo>
              </div>
            </div>
            <div className='certificationsInfo pt-0 w-2/4'>
              <CertificationsCard certificates={coachDetail.certifications} />
            </div>
          </div>
          <div className='flex'>
            <div className='certificationsInfo w-full'>
              {coachDetail.description && (
                <span className='coach-description'>{coachDetail.description}</span>
              )}
              {!coachDetail.description && (
                <div className='flex flex-col justify-center items-center h-64'>
                  <img className='w-14' src={noDescription} alt='description' />
                  <h1 className='my-5'>{t('coachProfile.noDesc')}</h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachProfile;
