// Interfaces
import { IndividualCert } from 'src/interfaces/coachesInterface';

// Components
import Icon from 'src/ui/Icon';

const IndividualCertification = (props: IndividualCert) => {
  const { certificate, isEditing, setCurrentCertificate, setShowDeleteCertificate } = props;

  const deleteCertificateConfirmation = (certificate: any) => {
    setCurrentCertificate?.(certificate);
    setShowDeleteCertificate?.(true);
  };

  return (
    <div
      className={`flex justify-center relative mb-2 ${isEditing ? 'h-full' : 'w-full'}`}
      key={certificate.id}
    >
      <img
        src={isEditing ? certificate.urlPreview : certificate.fileUrl}
        alt='certificate'
        className='w-8 h-6 mr-4 rounded-t-lg'
      />
      <a
        className={`underline text-sm whitespace-nowrap overflow-hidden text-ellipsis ${
          isEditing ? 'w-72' : 'w-11/12'
        }`}
        href={isEditing ? certificate.urlPreview : certificate.fileUrl}
        target='_blank'
        rel='noopener noreferrer'
      >
        {isEditing ? certificate.urlPreview : certificate.fileUrl}
      </a>
      {isEditing && (
        <Icon
          icon='delete'
          extraClass='coach-delete-certification'
          onClick={() => deleteCertificateConfirmation(certificate)}
        />
      )}
    </div>
  );
};

export default IndividualCertification;
