import { useTranslation } from 'react-i18next';

// Interfaces
import { ChooseItemProps } from 'src/interfaces/generalInterface';
import { RejectProfileReasonsProps } from 'src/interfaces/usersInterface';

const ChooseItem = (props: ChooseItemProps) => {
  const { options, name, onChange } = props;
  const { t } = useTranslation(['users']);
  const handleClick = (value: string | number) => {
    onChange(value);
  };
  const handleOptionText = (option: any) => {
    if (option.option === 'ProfilePhoto') {
      return t('profPhoto');
    } else if (option.option === 'ProfileImages') {
      return t('profImges');
    } else if (option.option === 'ProfileDescription') {
      return t('profDesc');
    } else return option.option;
  };
  return (
    <div className='w-9/12'>
      {options &&
        options.map((option: RejectProfileReasonsProps, index: number) => (
          <div
            role={'button'}
            className={`options-card ${option.checked ? 'him-active-option' : ''}`}
            key={index}
          >
            <label
              className={`text-primary relative capitalize block w-full py-4 pl-4 cursor-pointer hover:opacity-80 text-left`}
              htmlFor={`${option.option}-${index}`}
            >
              {handleOptionText(option)}
              <input
                type={'checkbox'}
                name={name}
                id={`${option.option}-${index}`}
                onChange={() => handleClick(option.id)}
                value={option.option}
                checked={option.checked || false}
                className='invisible'
              />
              <span className='absolute top-0 right-5 bottom-0 w-10 h-10 m-auto rounded-full border border-secondary p-2 flex items-center justify-center cursor-pointer'>
                {option.checked && (
                  <svg
                    width='22'
                    height='18'
                    viewBox='0 0 22 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M7.68897 17.182L0.181641 9.38316L1.62278 7.88606L7.68897 14.1878L20.5587 0.818359L21.9998 2.31546L7.68897 17.182Z'
                      fill='#9362C7'
                    />
                  </svg>
                )}
              </span>
            </label>
          </div>
        ))}
    </div>
  );
};

export default ChooseItem;
