// Components
import Button from '../Button';

// Interfaces
import { DecisionFooterI } from 'src/interfaces/generalInterface';

const DecisionFooter = (props: DecisionFooterI) => {
  const { extraClass, btnWitdh, onApprove, approve, cancel, disabled, onCancel } = props;
  return (
    <>
      <hr className={`w-full border border-white ${extraClass ? extraClass : ''}`} />
      <div className='w-full review-footer'>
        <div className={`${btnWitdh || 'w-3/12'} mr-8`}>
          <button className='him-cancel' onClick={onCancel} type='button'>
            {cancel}
          </button>
        </div>
        <div className={`${btnWitdh || 'w-3/12'}`}>
          <Button
            extraClass='p-2 normal-case'
            onClick={onApprove}
            type='submit'
            disabled={disabled}
          >
            {approve}
          </Button>
        </div>
      </div>
    </>
  );
};

export default DecisionFooter;
