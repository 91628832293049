import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';

export default function Auth() {
  const role = useAppSelector((state) => state.auth.role);
  return (
    <main>
      <section className='relative w-full py-40 h-screen'>
        <div
          className='absolute top-0 w-full h-full bg-primary bg-no-repeat  opacity-80 bg-full'
          style={{
            backgroundImage:
              role === 'coach'
                ? 'linear-gradient(rgb(83, 154, 188), #9362E7)'
                : 'linear-gradient(#EA9651,#9362E7)',
          }}
        />
        <Outlet />
      </section>
    </main>
  );
}
