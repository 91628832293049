import { createSlice } from '@reduxjs/toolkit';
import { CoachAvailabilityNewI, Coaches } from 'src/interfaces/coachesInterface';
import { CoachesState } from '../state.types';
import { CoachAvailabilityI } from '../../interfaces/coachesInterface';
import moment from 'moment';

const initialState: CoachesState = {
  coaches: {
    isSuccess: false,
    statusCode: '',
    message: '',
    metadata: {
      page: 1,
      perPage: 10,
      pageCount: null,
      totalCount: null,
    },
    data: [],
  },
  coachDetail: {
    fullName: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    rating: 0,
    address: '',
    profilePicture: '',
    description: '',
    certifications: [],
  },
  availability: [],
  currentAvailabilityId: '',
  currentAvailability: '',
  coachDetailView: '',
  editCoachDetailView: '',
};

const coachesSlice = createSlice({
  name: 'coaches',
  initialState,
  reducers: {
    setCoaches: (state, action) => {
      state.coaches.data = action.payload;
    },
    deleteCoach: (state, action) => {
      state.coaches.data = state.coaches.data.filter(
        (coach: Coaches) => coach.id !== action.payload,
      );
    },
    setCoachDetail: (state, action) => {
      state.coachDetail = action.payload;
    },
    deactivateActivateCoach: (state, action) => {
      const coach = state.coaches.data.find((c: Coaches) => c.id === action.payload.coachId);
      if (coach) {
        coach.status = action.payload.status;
      }
      return state;
    },
    createCoach: (state, action) => {
      state.coaches.data.unshift(action.payload);
    },
    updateCoach(state, action) {
      state.coaches.data = state.coaches.data.map((coach: Coaches) => {
        if (coach.id === action.payload) {
          coach = action.payload;
        }
        return coach;
      });
    },
    deleteCoachProfilePicture: (state, action) => {
      const coach = state.coaches.data.find((coach: Coaches) => coach.id === action.payload);
      coach?.profilePicture === '';
    },
    setMetadata: (state, action) => {
      state.coaches.metadata = action.payload;
    },
    setCoachAvailability: (state, action) => {
      state.availability = action.payload;
    },
    addCoachAvailability: (state, action) => {
      const newStartDate = moment(
        `${action.payload.startDate} ${action.payload.startHour}`,
        'YYYY-MM-DD HH:mm',
      );
      const findDiff = state.availability.filter(
        (av: any) =>
          newStartDate.diff(
            moment(`${av.startDate} ${av.startHour}`, 'YYYY-MM-DD HH:mm'),
            'minutes',
          ) > 0,
      );
      state.availability.splice(findDiff.length, 0, action.payload);
    },
    deleteCoachAvailability: (state, action) => {
      const findAv = state.availability.find(
        (av: any) =>
          av.startDate === action.payload.startDate &&
          av.endDate === action.payload.endDate &&
          av.startHour === action.payload.startHour &&
          av.endHour === action.payload.endHour,
      );
      state.availability = state.availability.filter(
        (availability: CoachAvailabilityNewI) => availability !== findAv,
      );
    },
    updateCoachAvailability: (state, action) => {
      const index = state.availability.findIndex(
        (availability: CoachAvailabilityI) => availability.id === action.payload.id,
      );
      state.availability[index] = action.payload.data;
      return state;
    },
    setCurrentAvailabilityId: (state, action) => {
      state.currentAvailabilityId = action.payload;
    },
    setCurrentAvailability: (state, action) => {
      state.currentAvailability = action.payload;
    },
    setCoachDetailView: (state, action) => {
      state.coachDetailView = action.payload;
    },
    setEditCoachDetailView: (state, action) => {
      state.editCoachDetailView = action.payload;
    },
  },
});

export const {
  setCoaches,
  deleteCoach,
  deactivateActivateCoach,
  createCoach,
  setCoachDetail,
  updateCoach,
  deleteCoachProfilePicture,
  setMetadata,
  setCoachAvailability,
  addCoachAvailability,
  deleteCoachAvailability,
  setCurrentAvailabilityId,
  updateCoachAvailability,
  setCoachDetailView,
  setEditCoachDetailView,
  setCurrentAvailability,
} = coachesSlice.actions;

export default coachesSlice.reducer;
