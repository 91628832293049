export const weekdays = [
  { value: 'Monday', id: 1 },
  { value: 'Tuesday', id: 2 },
  { value: 'Wednesday', id: 3 },
  { value: 'Thursday', id: 4 },
  { value: 'Friday', id: 5 },
  { value: 'Saturday', id: 6 },
  { value: 'Sunday', id: 7 },
];

export const times = [
  { name: '00:00', value: '00:00', id: 1 },
  { name: '00:30', value: '00:30', id: 2 },
  { name: '01:00', value: '01:00', id: 3 },
  { name: '00:30', value: '01:30', id: 4 },
  { name: '02:00', value: '02:00', id: 5 },
  { name: '02:30', value: '02:30', id: 6 },
  { name: '03:00', value: '03:00', id: 7 },
  { name: '03:30', value: '03:30', id: 8 },
  { name: '04:00', value: '04:00', id: 9 },
  { name: '04:30', value: '04:30', id: 10 },
  { name: '05:00', value: '05:00', id: 11 },
  { name: '05:30', value: '05:30', id: 12 },
  { name: '06:00', value: '06:00', id: 13 },
  { name: '06:30', value: '06:30', id: 14 },
  { name: '07:00', value: '07:00', id: 15 },
  { name: '07:30', value: '07:30', id: 16 },
  { name: '08:00', value: '08:00', id: 17 },
  { name: '08:30', value: '08:30', id: 18 },
  { name: '09:00', value: '09:00', id: 19 },
  { name: '09:30', value: '09:30', id: 20 },
  { name: '10:00', value: '10:00', id: 21 },
  { name: '10:30', value: '10:30', id: 22 },
  { name: '11:00', value: '11:00', id: 23 },
  { name: '11:30', value: '11:30', id: 24 },
  { name: '12:00', value: '12:00', id: 25 },
  { name: '12:30', value: '12:30', id: 26 },
  { name: '13:00', value: '13:00', id: 27 },
  { name: '13:30', value: '13:30', id: 28 },
  { name: '14:00', value: '14:00', id: 29 },
  { name: '14:30', value: '14:30', id: 30 },
  { name: '15:00', value: '15:00', id: 31 },
  { name: '15:30', value: '15:30', id: 32 },
  { name: '16:00', value: '16:00', id: 33 },
  { name: '16:30', value: '16:30', id: 34 },
  { name: '17:00', value: '17:00', id: 35 },
  { name: '17:30', value: '17:30', id: 36 },
  { name: '18:00', value: '18:00', id: 37 },
  { name: '18:30', value: '18:30', id: 38 },
  { name: '19:00', value: '19:00', id: 39 },
  { name: '19:30', value: '19:30', id: 40 },
  { name: '20:00', value: '20:00', id: 41 },
  { name: '20:30', value: '20:30', id: 42 },
  { name: '21:00', value: '21:00', id: 43 },
  { name: '21:30', value: '21:30', id: 44 },
  { name: '22:00', value: '22:00', id: 45 },
  { name: '22:30', value: '22:30', id: 46 },
  { name: '23:00', value: '23:00', id: 47 },
  { name: '23:30', value: '23:30', id: 48 },
  { name: '24:00', value: '23:58', id: 49 },
];

export const newTimes = [
  { name: '12:00 AM', value: '00:00', id: 1 },
  { name: '12:15 AM', value: '00:15', id: 2 },
  { name: '12:30 AM', value: '00:30', id: 3 },
  { name: '12:45 AM', value: '00:45', id: 4 },
  { name: '1:00 AM', value: '01:00', id: 5 },
  { name: '1:15 AM', value: '01:15', id: 6 },
  { name: '1:30 AM', value: '01:30', id: 7 },
  { name: '1:45 AM', value: '01:45', id: 8 },
  { name: '2:00 AM', value: '02:00', id: 9 },
  { name: '2:15 AM', value: '02:15', id: 10 },
  { name: '2:30 AM', value: '02:30', id: 11 },
  { name: '2:45 AM', value: '02:45', id: 12 },
  { name: '3:00 AM', value: '03:00', id: 13 },
  { name: '3:15 AM', value: '03:15', id: 14 },
  { name: '3:30 AM', value: '03:30', id: 15 },
  { name: '3:45 AM', value: '03:45', id: 16 },
  { name: '4:00 AM', value: '04:00', id: 17 },
  { name: '4:15 AM', value: '04:15', id: 18 },
  { name: '4:30 AM', value: '04:30', id: 19 },
  { name: '4:45 AM', value: '04:45', id: 20 },
  { name: '5:00 AM', value: '05:00', id: 21 },
  { name: '5:15 AM', value: '05:15', id: 22 },
  { name: '5:30 AM', value: '05:30', id: 23 },
  { name: '5:45 AM', value: '05:45', id: 24 },
  { name: '6:00 AM', value: '06:00', id: 25 },
  { name: '6:15 AM', value: '06:15', id: 26 },
  { name: '6:30 AM', value: '06:30', id: 27 },
  { name: '6:45 AM', value: '06:45', id: 28 },
  { name: '7:00 AM', value: '07:00', id: 29 },
  { name: '7:15 AM', value: '07:15', id: 30 },
  { name: '7:30 AM', value: '07:30', id: 31 },
  { name: '7:45 AM', value: '07:45', id: 32 },
  { name: '8:00 AM', value: '08:00', id: 33 },
  { name: '8:15 AM', value: '08:15', id: 34 },
  { name: '8:30 AM', value: '08:30', id: 35 },
  { name: '8:45 AM', value: '08:45', id: 36 },
  { name: '9:00 AM', value: '09:00', id: 37 },
  { name: '9:15 AM', value: '09:15', id: 38 },
  { name: '9:30 AM', value: '09:30', id: 39 },
  { name: '9:45 AM', value: '09:45', id: 40 },
  { name: '10:00 AM', value: '10:00', id: 41 },
  { name: '10:15 AM', value: '10:15', id: 42 },
  { name: '10:30 AM', value: '10:30', id: 43 },
  { name: '10:45 AM', value: '10:45', id: 44 },
  { name: '11:00 AM', value: '11:00', id: 45 },
  { name: '11:15 AM', value: '11:15', id: 46 },
  { name: '11:30 AM', value: '11:30', id: 47 },
  { name: '11:45 AM', value: '11:45', id: 48 },
  { name: '12:00 PM', value: '12:00', id: 49 },
  { name: '12:15 PM', value: '12:15', id: 50 },
  { name: '12:30 PM', value: '12:30', id: 51 },
  { name: '12:45 PM', value: '12:45', id: 52 },
  { name: '1:00 PM', value: '13:00', id: 53 },
  { name: '1:15 PM', value: '13:15', id: 54 },
  { name: '1:30 PM', value: '13:30', id: 55 },
  { name: '1:45 PM', value: '13:45', id: 56 },
  { name: '2:00 PM', value: '14:00', id: 57 },
  { name: '2:15 PM', value: '14:15', id: 58 },
  { name: '2:30 PM', value: '14:30', id: 59 },
  { name: '2:45 PM', value: '14:45', id: 60 },
  { name: '3:00 PM', value: '15:00', id: 61 },
  { name: '3:15 PM', value: '15:15', id: 62 },
  { name: '3:30 PM', value: '15:30', id: 63 },
  { name: '3:45 PM', value: '15:45', id: 64 },
  { name: '4:00 PM', value: '16:00', id: 65 },
  { name: '4:15 PM', value: '16:15', id: 66 },
  { name: '4:30 PM', value: '16:30', id: 67 },
  { name: '4:45 PM', value: '16:45', id: 68 },
  { name: '5:00 PM', value: '17:00', id: 69 },
  { name: '5:15 PM', value: '17:15', id: 70 },
  { name: '5:30 PM', value: '17:30', id: 71 },
  { name: '5:45 PM', value: '17:45', id: 72 },
  { name: '6:00 PM', value: '18:00', id: 73 },
  { name: '6:15 PM', value: '18:15', id: 74 },
  { name: '6:30 PM', value: '18:30', id: 75 },
  { name: '6:45 PM', value: '18:45', id: 76 },
  { name: '7:00 PM', value: '19:00', id: 77 },
  { name: '7:15 PM', value: '19:15', id: 78 },
  { name: '7:30 PM', value: '19:30', id: 79 },
  { name: '7:45 PM', value: '19:45', id: 80 },
  { name: '8:00 PM', value: '20:00', id: 81 },
  { name: '8:15 PM', value: '20:15', id: 82 },
  { name: '8:30 PM', value: '20:30', id: 83 },
  { name: '8:45 PM', value: '20:45', id: 84 },
  { name: '9:00 PM', value: '21:00', id: 85 },
  { name: '9:15 PM', value: '21:15', id: 86 },
  { name: '9:30 PM', value: '21:30', id: 87 },
  { name: '9:45 PM', value: '21:45', id: 88 },
  { name: '10:00 PM', value: '22:00', id: 89 },
  { name: '10:15 PM', value: '22:15', id: 90 },
  { name: '10:30 PM', value: '22:30', id: 91 },
  { name: '10:45 PM', value: '22:45', id: 92 },
  { name: '11:00 PM', value: '23:00', id: 93 },
  { name: '11:15 PM', value: '23:15', id: 94 },
  { name: '11:30 PM', value: '23:30', id: 95 },
  { name: '11:45 PM', value: '23:45', id: 96 },
  { name: '12:00 AM', value: '23:59', id: 97 },
];
