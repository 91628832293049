import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import admin from '../../../assets/img/adminFinal.png';
import coach from '../../../assets/img/coachFinal.png';
import { useNavigate } from 'react-router-dom';

// Interfaces
import { ForgotPassworFormI } from 'src/interfaces/generalInterface';

// Components
import Input from 'src/ui/Input';
import DecisionFooter from 'src/ui/layout/DecisionFooter';

const ForgotPasswordForm = (props: ForgotPassworFormI) => {
  const { onSuccess } = props;
  const navigate = useNavigate();
  const { t } = useTranslation(['forgotPassword']);
  const formik = useFormik({
    initialValues: {
      email: '',
      role: '',
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      email: Yup.string().email(t('emailValid')).required(t('emailError')),
      role: Yup.string().required(t('roleError')),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className='mt-8' style={{ width: '85%' }}>
      {formik.touched.role && formik.errors.role ? (
        <div className='text-alert font-medium mt-2 flex justify-center'>{formik.errors.role}</div>
      ) : (
        <span className='text-primary font-medium mt-2 flex justify-center'>{t('chooseRole')}</span>
      )}
      <div className='flex justify-center'>
        <div className='flex flex-col justify-center items-center mr-4'>
          <img
            src={admin}
            alt='admin'
            width={'100px'}
            onClick={() => {
              formik.setFieldValue('role', 'Admin');
            }}
            aria-hidden='true'
            className={`${
              formik.values.role === 'Admin' ? 'border-2 border-secondary' : ''
            } login-user`}
          ></img>
          <span className={`${formik.values.role === 'Admin' ? 'text-secondary' : ''}`}>
            {t('roleOne')}
          </span>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <img
            src={coach}
            alt='admin'
            width={'100px'}
            onClick={() => {
              formik.setFieldValue('role', 'Coach');
            }}
            aria-hidden='true'
            className={`${
              formik.values.role === 'Coach' ? 'border-2 border-secondary' : ''
            } login-user`}
          ></img>
          <span className={`${formik.values.role === 'Coach' ? 'text-secondary' : ''}`}>
            {t('roleTwo')}
          </span>
        </div>
      </div>
      {formik.touched.email && formik.errors.email ? (
        <div className='text-alert ml-2 font-medium text-left'>{formik.errors.email}</div>
      ) : (
        <span className='text-primary ml-2 font-medium text-left'>{t('inputOne')}</span>
      )}
      <div className='relative'>
        <Input
          type='email'
          name='email'
          value={formik.values.email}
          onChange={(e) => formik.handleChange(e)}
          onBlur={formik.handleBlur}
          extraClass='w-full mb-4'
          haveError={formik.touched.email && formik.errors.email}
          placeholder={t('inputOne')}
        />
      </div>

      <DecisionFooter
        cancel={t('loginBtn')}
        approve={t('submtBtn')}
        onCancel={() => navigate('/auth')}
      />
    </form>
  );
};

export default ForgotPasswordForm;
