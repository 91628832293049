import { useTranslation } from 'react-i18next';

// Interfaces
import { CoachAdditionalInfoFormI } from 'src/interfaces/coachesInterface';

// Components
import IndividualCertification from 'src/components/cards/admin/coaches/IndividualCertification';
import Button from 'src/ui/Button';
import Icon from 'src/ui/Icon';

const CoachAdditionalInfoForm = (props: CoachAdditionalInfoFormI) => {
  const {
    formik,
    setPreview,
    setProfilePicture,
    setShowDeletePhoto,
    fileInputRef,
    preview,
    profilePicture,
    certificates,
    handleChange,
    certificatesInputRef,
    isEditing,
    data,
    setCurrentCertificate,
    setShowDeleteCertificate,
  } = props;
  const { t } = useTranslation(['addEditCoachAdditionalInfoForm']);

  return (
    <form onSubmit={formik.handleSubmit} className='h-full'>
      <div className='flex px-8' style={{ height: '80%' }}>
        <div className='flex justify-center items-center relative w-6/12 p-8'>
          <div className='w-80 flex flex-col items-center'>
            {formik.touched.profilePicture && formik.errors.profilePicture && (
              <div className='text-alert font-medium text-xs text-center absolute top-5 transition-all mb-4'>
                {formik.errors.profilePicture}
              </div>
            )}
            <div className='block text-primary mb-4 mt-8 text-base text-left'>{t('picture')}</div>
            {preview ? (
              <div className='relative'>
                <img
                  src={preview}
                  alt='preview'
                  className='coach-profile-picture'
                  aria-hidden='true'
                />
                <Icon
                  icon='delete'
                  extraClass='coach-delete-picture'
                  onClick={() => setShowDeletePhoto(true)}
                />
              </div>
            ) : (
              <button
                type='button'
                className='border-2 border-primary border-solid rounded-3xl w-full p-2 mt-4 mb-12'
                onClick={(e) => {
                  e.preventDefault();
                  fileInputRef.current?.click();
                }}
              >
                {t('pictureBtn')}
              </button>
            )}
            <input
              ref={fileInputRef}
              className='hidden'
              type='file'
              name='file'
              accept='image/*'
              onBlur={formik.handleBlur}
              onChange={(event) => {
                formik.setFieldValue(
                  'profilePicture',
                  event.currentTarget.files ? event.currentTarget.files[0] : 'no file',
                );
                if (event.target.files && event.target.files[0].type.substring(0, 5) === 'image') {
                  const iFile = {
                    id: 1,
                    inServer: false,
                    file: event.target.files[0],
                    urlPreview: URL.createObjectURL(event.target.files[0]),
                  };
                  setProfilePicture(iFile);
                  setPreview(iFile.urlPreview);
                }
              }}
            />
          </div>
        </div>
        <div
          className={`flex relative ${
            certificates.length > 0 ? 'justify-center' : 'justify-center'
          } items-center w-6/12 p-8`}
        >
          <div className='w-80 flex flex-col items-center'>
            {formik.touched.certificates && formik.errors.certificates && (
              <div className='text-alert font-medium text-xs text-center absolute top-5 transition-all mb-4'>
                {formik.errors.certificates as string}
              </div>
            )}
            <div className='formUsersHeader mb-4 mt-8 text-base text-center'>
              {t('certifications')}
            </div>
            <input
              type='file'
              onChange={handleChange}
              multiple
              ref={certificatesInputRef}
              className='hidden'
              name='certificates'
              accept='image/*'
            />
            <button
              type='submit'
              className='border-2 border-primary border-solid rounded-3xl w-full p-2 my-4'
              onClick={(e) => {
                e.preventDefault();
                certificatesInputRef.current?.click();
              }}
            >
              {`${certificates.length > 0 ? t('certiMoreBtn') : t('certiBtn')}`}
            </button>
            <div className='flex flex-col w-full p-4 overflow-auto max-h-48'>
              {certificates.map((certificate) => (
                <IndividualCertification
                  certificate={certificate}
                  key={certificate.id}
                  isEditing={true}
                  setCurrentCertificate={setCurrentCertificate}
                  setShowDeleteCertificate={setShowDeleteCertificate}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <hr className='w-full border' />
      <div className='flex justify-center mt-4'>
        {isEditing ? (
          <Button type='submit' extraClass='font-semibold w-52 p-2 mt-4'>
            {data?.certificates !== certificates || data.profilePicture !== profilePicture
              ? t('save')
              : t('skip')}
          </Button>
        ) : (
          <Button type='submit' extraClass='font-semibold w-52 p-2 mt-4'>
            {certificates.length > 0 || profilePicture ? t('save') : t('skip')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default CoachAdditionalInfoForm;
