import questionImg from '../../assets/img/question.svg';

// Components
import { ConfirmationPopupI } from 'src/interfaces/generalInterface';
import PopUp from '../Popup';
import DecisionFooter from '../layout/DecisionFooter';

const ConfirmationPopup = (props: ConfirmationPopupI) => (
  <PopUp
    extraClass='max-w-lg'
    extraClassesChildren='flex flex-col items-center'
    isVisible={props.isVisible}
    onClose={props.onClose}
  >
    <div className='flex flex-col w-full items-center'>
      <img src={questionImg} alt='magnifying glass' className='my-4' />
      <div className='text-primary text-3xl w-full text-center font-normal'>{props.title}</div>
      <div className='block text-center'>
        <p className='text-base mt-12 mb-4 mx-8'>
          {props.subtitle}
          {props.boldSubtitle && <b className='text-primary font-semibold'>{props.boldSubtitle}</b>}
        </p>
      </div>
      <DecisionFooter
        cancel={props.cancelBtn || 'Cancel'}
        approve={props.submitBtn}
        onApprove={props.handleSubmit}
        onCancel={props.onClose}
        btnWitdh='w-4/12'
      />
    </div>
  </PopUp>
);

export default ConfirmationPopup;
