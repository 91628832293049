import { createSlice } from '@reduxjs/toolkit';
import { WellnessState } from '../state.types';

const initialState: WellnessState = {
  upcomingSessions: [],
  upcomingSessionsMetadata: {
    page: -1,
    perPage: -1,
    pageCount: -1,
    totalCount: -1,
  },
  pastSessions: [],
  pastSessionMetadata: {
    page: -1,
    perPage: -1,
    pageCount: -1,
    totalCount: -1,
  },
};

const wellnessSlice = createSlice({
  name: 'wellness',
  initialState,
  reducers: {
    setUpcomingSessions(state, action) {
      state.upcomingSessions = action.payload;
    },
    setUpcomingSessionsMetadata(state, action) {
      state.upcomingSessionsMetadata = action.payload;
    },
    setPastSessions(state, action) {
      state.pastSessions = action.payload;
    },
    setPastSessionsMetadata(state, action) {
      state.pastSessionMetadata = action.payload;
    },
  },
});

export const {
  setUpcomingSessions,
  setUpcomingSessionsMetadata,
  setPastSessions,
  setPastSessionsMetadata,
} = wellnessSlice.actions;

export default wellnessSlice.reducer;
