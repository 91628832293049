import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Redux
import { setEditCoachDetailView } from 'src/redux/slices/coaches.slice';

// Components
import CoachAdditionalInfo from 'src/components/cards/admin/coaches/AdditionalInfo';

// Services
import {
  getCoachDetailUseCase,
  uploadAllCertificationsAction,
  uploadCoachPictureUseCase,
} from 'src/services/coaches/useCases';

const EditCoachAdditionalInfo = () => {
  const params = useParams<{ coachId: string }>();
  const coachDetail = useAppSelector((state) => state.coaches.coachDetail);
  const editCoachDetailView = useAppSelector((state) => state.coaches.editCoachDetailView);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSuccess = async (values: { profilePicture: string; certificates: [] }) => {
    const formData = new FormData();
    formData.append('profilePicture', values.profilePicture);
    if (values.profilePicture) {
      await dispatch(
        uploadCoachPictureUseCase({ coachId: params.coachId, profilePicture: formData }),
      );
    }
    if (values.certificates) {
      await dispatch(uploadAllCertificationsAction(values.certificates));
    }
    dispatch(setEditCoachDetailView('finish'));
  };
  useEffect(() => {
    if (editCoachDetailView === 'finish') {
      navigate('/admin/coaches');
    }
  }, [editCoachDetailView, navigate]);

  useEffect(() => {
    if (params.coachId) {
      dispatch(getCoachDetailUseCase(params.coachId));
    }
  }, [dispatch, params.coachId]);

  return <CoachAdditionalInfo onSuccess={handleSuccess} data={coachDetail} isEditing={true} />;
};

export default EditCoachAdditionalInfo;
