import Photos from '../../assets/img/photos.svg';

const UploadIcon = () => {
  return (
    <div className='container-icon-upload'>
      <img src={Photos} alt='pictures' className='cursor-pointer' />
    </div>
  );
};

export default UploadIcon;
