import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import useOutsideClick from 'src/hooks/ClickOutside';

// Interfaces
import { tableDropdownProps } from 'src/interfaces/usersInterface';
import { Coaches } from 'src/interfaces/coachesInterface';

// Services
import { deleteCoachUseCase, updateCoachStatusUseCase } from 'src/services/coaches/useCases';
import { deleteUserUseCase, updateUserStatusUseCase } from 'src/services/users/useCases';
import { deleteStoryUseCase, setAsFreeStoryUseCase } from 'src/services/stories/useCases';

// Components
import ConfirmationPopup from 'src/ui/popups/ConfirmationPopup';
import TableOptions from './TableOptions';

const TableDropdown = (props: tableDropdownProps) => {
  const users = useAppSelector((state) => state.users.users.data);
  const coaches = useAppSelector((state) => state.coaches.coaches.data);
  const [showDelete, setShowDelete] = useState(false);
  const [showFree, setShowFree] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const { user, id, access, thirdOption } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('tableDropdown');
  const dispatch = useAppDispatch();
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<any | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });
  const menuRef: any = useRef();
  const handleDelete = () => {
    if (thirdOption) {
      if (user) {
        dispatch(deleteUserUseCase(id));
      } else if (!user) {
        dispatch(deleteCoachUseCase(Number(id)));
      }
    } else {
      dispatch(deleteStoryUseCase(Number(id)));
    }
    setShowDelete(false);
  };

  const handleFree = () => {
    dispatch(setAsFreeStoryUseCase(Number(id)));
    setShowFree(false);
  };

  const currentUser = users.find((user) => user.publicId === id);
  const currentUserStatus = currentUser?.status;

  const currentCoach = coaches.find((coach: Coaches) => coach.id === Number(id));
  const currentCoachStatus = currentCoach?.status;

  const handleDeactivate = () => {
    if (user) {
      dispatch(
        updateUserStatusUseCase({
          publicId: id,
          status:
            currentUserStatus === 'Registered' || currentUserStatus === 'PendingReview'
              ? false
              : true,
        }),
      );
    } else {
      dispatch(
        updateCoachStatusUseCase({
          coachId: id,
          status:
            currentCoachStatus === 'Registered' || currentCoachStatus === 'PendingReview'
              ? false
              : true,
        }),
      );
    }
    setShowStatus(false);
  };

  const closePopover = () => {
    setIsOpen(false);
  };
  const ref: any = useOutsideClick(closePopover);

  return (
    <>
      <div ref={ref}>
        <div className='relative text-blueGray-500 py-1 px-3' ref={setReferenceElement}>
          <button ref={menuRef} onClick={() => setIsOpen((isOpen) => !isOpen)}>
            <i className='fas fa-ellipsis-v' />
          </button>
        </div>
        {isOpen && (
          <TableOptions
            setPopperElement={setPopperElement}
            setShowDelete={setShowDelete}
            setShowFree={setShowFree}
            setShowStatus={setShowStatus}
            popperAttributes={attributes.popper}
            popperStlye={styles.popper}
            id={id}
            user={user}
            thirdOption={thirdOption}
            access={access}
            currentCoachStatus={currentCoachStatus}
            currentUserStatus={currentUserStatus}
            currentUser={currentUser}
          />
        )}
      </div>
      <ConfirmationPopup
        isVisible={showDelete}
        title={t('questionTitle')}
        subtitle={t('deleteSubtitle')}
        submitBtn={t('confirmDeleteBtn', {
          prop: !thirdOption ? t('story') : user ? t('user') : t('coach'),
        })}
        handleSubmit={handleDelete}
        onClose={() => setShowDelete(false)}
      />
      <ConfirmationPopup
        isVisible={showFree}
        title={t('questionTitle')}
        subtitle={t('freeSubtitle')}
        submitBtn={t('confirmSetAsFree')}
        handleSubmit={handleFree}
        onClose={() => setShowFree(false)}
      />
      <ConfirmationPopup
        isVisible={showStatus}
        title={t('questionTitle')}
        subtitle={t('deactivateSubtitle', {
          status: user
            ? currentUserStatus === 'Deactivated'
              ? t('activate')
              : t('deactivate')
            : currentCoachStatus === 'Deactivated'
            ? t('activate')
            : t('deactivate'),
          prop: user ? t('user') : t('coach'),
        })}
        submitBtn={t('statusConfirmation', {
          status: user
            ? currentUserStatus === 'Deactivated'
              ? t('activate')
              : t('deactivate')
            : currentCoachStatus === 'Deactivated'
            ? t('activate')
            : t('deactivate'),
          prop: user ? t('user') : t('coach'),
        })}
        handleSubmit={handleDeactivate}
        onClose={() => setShowStatus(false)}
      />
    </>
  );
};

export default TableDropdown;
