import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import getLocation from '../../../assets/countries.json';
import { useTranslation } from 'react-i18next';

// Components
import CoachGeneralForm from 'src/components/forms/admin/coaches/CoachGeneralForm';
import ConfirmationPopup from 'src/ui/popups/ConfirmationPopup';

// Interfaces
import { CoachFormikFormProps } from 'src/interfaces/ui/formInterface';

// Redux
import { setEditCoachDetailView } from 'src/redux/slices/coaches.slice';

// Services
import { getCoachDetailUseCase, updateCoachUseCase } from 'src/services/coaches/useCases';

const EditCoach = () => {
  const params = useParams<{ coachId: string }>();
  const role = useAppSelector((state) => state.auth.role);
  const coachDetail = useAppSelector((state) => state.coaches.coachDetail);
  const editCoachDetailView = useAppSelector((state) => state.coaches.editCoachDetailView);
  const [editInfo, setEditInfo] = useState({});
  const [showEditCoach, setShowEditCoach] = useState<boolean>(false);
  const { t } = useTranslation(['addEditCoachForm']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = getLocation.states;
  localStorage.setItem('current-coach', JSON.stringify(params.coachId));
  let stateEditing: any = '';

  const findState = (stateName: any) => {
    const state = location.find((state) => state.state_code === stateName);
    stateEditing = state;
    return state?.id;
  };

  const findCity = (cityName: any) => {
    if (stateEditing) {
      const city = stateEditing.cities.find((city: any) => city.name === cityName);
      return city?.id;
    }
  };

  const handleSuccess = async (values: CoachFormikFormProps) => {
    const editLocation = {
      stateId: values.location?.stateId,
      cityId: values.location?.cityId,
    };
    const editValues: any = {};
    if (coachDetail.fullName !== values.fullName) editValues.fullName = values.fullName;
    if (coachDetail.email !== values.email) editValues.email = values.email;
    if (coachDetail.phone !== values.phone) editValues.phone = values.phone;
    if (values.location?.stateId) {
      if (findState(coachDetail.state) !== Number(values.location?.stateId))
        editValues.location = editLocation;
      if (findCity(coachDetail.city) !== Number(values.location?.cityId))
        editValues.location = editLocation;
    }
    if (coachDetail.description !== values.description) editValues.description = values.description;
    const updateCoachGeneralInfo = Object.assign({}, editValues);
    if (
      updateCoachGeneralInfo.fullName ||
      updateCoachGeneralInfo.email ||
      updateCoachGeneralInfo.phone ||
      updateCoachGeneralInfo.description ||
      updateCoachGeneralInfo.location
    ) {
      setEditInfo(updateCoachGeneralInfo);
      setShowEditCoach(true);
    } else {
      dispatch(setEditCoachDetailView('edit-additional-info'));
    }
  };

  const handleEditCoach = () => {
    dispatch(
      updateCoachUseCase({
        coachId: params.coachId,
        updatedCoach: editInfo,
      }),
    );
  };

  useEffect(() => {
    if (editCoachDetailView === 'edit-additional-info') {
      navigate(`/admin/coaches/${params.coachId}/edit-coach/additional-info`);
    }
  }, [editCoachDetailView, navigate, params.coachId, role]);

  useEffect(() => {
    if (params.coachId) {
      dispatch(getCoachDetailUseCase(params.coachId));
    }
  }, [dispatch, params.coachId]);

  return (
    <>
      <CoachGeneralForm onSuccess={handleSuccess} data={coachDetail} isEditing={true} />
      <ConfirmationPopup
        isVisible={showEditCoach}
        title={t('editAlert.title')}
        subtitle={t('editAlert.subtitle')}
        submitBtn={t('editAlert.confirm')}
        handleSubmit={handleEditCoach}
        onClose={() => setShowEditCoach(false)}
      />
    </>
  );
};

export default EditCoach;
