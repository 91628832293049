import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import Icon from 'src/ui/Icon';
import Item from 'src/ui/Item';
import noCalendar from '../../../../assets/img/calendar.svg';
import moment from 'moment';

// Interfaces
import { AvList, CoachAvailabilityNewI } from 'src/interfaces/coachesInterface';

// Redux
import { setCurrentAvailability } from 'src/redux/slices/coaches.slice';

// Services
import { getCoachNewAvailabilityUseCase } from 'src/services/coaches/useCases';
import { useTranslation } from 'react-i18next';
import { getMyAvailabilityUseCase } from 'src/services/wellness/useCases';

const AvailabilityList = (props: AvList) => {
  const { setShowDeleteAv, setIsEditing, setShowForm } = props;
  const currentRole = useAppSelector((state) => state.auth.role);
  const params = useParams<{ coachId: string }>();
  const { t } = useTranslation(['coachAvailability']);
  const availabilities: CoachAvailabilityNewI[] = useAppSelector(
    (state) => state.coaches.availability,
  );
  const myAvailability: CoachAvailabilityNewI[] = useAppSelector(
    (state) => state.currentCoach.myAvailability,
  );
  const coachDetail = useAppSelector((state) => state.coaches.coachDetail);
  const currentCoach = useAppSelector((state) => state.currentCoach.currentCoach);
  const dispatch = useAppDispatch();

  const handleDelete = (av: CoachAvailabilityNewI) => {
    setShowForm?.(false);
    setShowDeleteAv?.(true);
    dispatch(setCurrentAvailability(av));
  };
  const handleEdit = (av: CoachAvailabilityNewI) => {
    setIsEditing?.(true);
    setShowForm?.(true);
    dispatch(setCurrentAvailability(av));
  };

  useEffect(() => {
    if (currentRole === 'admin' && params.coachId) {
      dispatch(getCoachNewAvailabilityUseCase({ coachId: params.coachId }));
    } else {
      dispatch(getMyAvailabilityUseCase());
    }
  }, [dispatch, params.coachId, currentRole]);

  return (
    <div
      className={`avList block flex-col justify-center p-4 ${
        currentRole === 'admin' ? 'w-2/5' : 'w-3/6'
      }`}
    >
      <div className='availabilitiesContainer'>
        <div className={`font-semibold pb-2 text-center text-xl`}>
          {`${currentRole === 'admin' ? coachDetail.fullName : currentCoach.fullName}${t(
            'avHeader',
          )}`}
        </div>
        <div className='avContainer'>
          {(currentRole === 'admin' ? availabilities : myAvailability).length === 0 && (
            <div className='flex flex-col justify-center items-center mt-20'>
              <img src={noCalendar} alt='No calendar' />
              <div className='no-availability'>{t('noAvailability')}</div>
            </div>
          )}
          {(currentRole === 'admin' ? availabilities : myAvailability).map(
            (av: CoachAvailabilityNewI, i) => {
              return (
                <Item key={`${i}-key`} itemExtraClass='cursor-default'>
                  <div className='flex items-center w-full'>
                    <div className='w-5/6 flex flex-col items-start ml-0 sm:ml-4 mr-4'>
                      <p className='text-left text-xs sm:text-base'>
                        <b className='font-medium pr-1'>{t('date')}</b>
                        {`${moment(av.startDate).format('MM/DD/YY')} - ${moment(av.endDate).format(
                          'MM/DD/YY',
                        )}`}
                      </p>
                      <p className='text-left text-xs sm:text-base'>
                        <b className='font-medium pr-1'>{t('from')}</b>
                        {moment.utc(av.startHour, 'HH:mm').format('hh:mm A')}
                        <span className='mx-0 sm:mx-1'> - </span>
                        <b className='font-medium pr-1'>{t('to')}</b>{' '}
                        {moment.utc(av.endHour, 'HH:mm').format('hh:mm A')}
                      </p>
                      {av.appointmentsCount > 0 && (
                        <div
                          className={`text-left text-xs sm:text-sm text-secondary italic font-medium`}
                        >
                          {`${t('bookedSessionsOne')} ${av.appointmentsCount} ${t(
                            'bookedSessionsTwo',
                          )}${av.appointmentsCount === 1 ? '' : 's'}`}
                        </div>
                      )}
                    </div>
                    {currentRole === 'admin' && (
                      <div className='flex justify-center'>
                        <Icon icon='edit' onClick={() => handleEdit(av)} />
                        <Icon icon='delete' onClick={() => handleDelete(av)} />
                      </div>
                    )}
                  </div>
                </Item>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default AvailabilityList;
