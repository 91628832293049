// components
import UsersCard from '../../../components/cards/admin/users/UsersCard';

const Users = () => (
  <div className='flex flex-wrap mt-4'>
    <div className='w-full mb-12 px-4'>
      <UsersCard color='light' />
    </div>
  </div>
);

export default Users;
