import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import languages from 'src/common/constants/languages';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import nodeTypes from 'src/common/constants/nodeTypes';
import acceptedImageFormats from 'src/common/constants/imgFormats';

// Components
import Button from 'src/ui/Button';
import Select from 'src/ui/Select';
import Input from 'src/ui/Input';
import Loading from 'src/ui/loading/Loading';

// Interfaces
import { StoriesFormI } from 'src/interfaces/storiesInterface';

// Services
import { getStoryLanguagesDetailUseCase } from 'src/services/stories/useCases';

const StoryForm = (props: StoriesFormI) => {
  const { onSuccess, isEditing } = props;
  const { t } = useTranslation(['stories']);
  const dispatch = useAppDispatch();
  const generalInputRef = React.createRef<HTMLInputElement>();
  const generalThumbnailRef = React.createRef<HTMLInputElement>();
  const introInputRef = React.createRef<HTMLInputElement>();
  const introThumbnailRef = React.createRef<HTMLInputElement>();
  const params = useParams<{ storyId: string }>();
  const [generalFileName, setGeneralFileName] = useState<string>(t('trailer'));
  const [generalThumbName, setGeneralThumbName] = useState<string>(t('thumbnail'));
  const [introFileName, setIntroFileName] = useState<string>(t('media'));
  const [introThumbName, setIntroThumbName] = useState<string>(t('thumbnail'));
  const existsEn = useAppSelector((state) => state.stories.existsEn);
  const existsEs = useAppSelector((state) => state.stories.existsEs);

  const formik = useFormik({
    initialValues: {
      locale:
        (!existsEn && existsEs ? 'en-US' : '') || (existsEn && !existsEs ? 'es-US' : '') || '',
      title: '',
      description: '',
      generalFile: null,
      generalThumbnail: '',
      introType: t('intro'),
      introDesc: '',
      introFile: '',
      introThumbnail: '',
      isEditing: isEditing,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      locale: Yup.string().required(t('errorRequiredOne')),
      title: Yup.string().max(64, t('maxTitle')).required(t('errorRequiredTwo')),
      description: Yup.string().required(t('errorRequiredThree')),
      generalFile: Yup.mixed()
        .test('format', t('fileExtErr'), (video: any) => {
          if (video && video.type !== 'video/mp4') {
            return false;
          }
          return true;
        })
        .test('size', t('fileSizeErr'), (video: any) => {
          if (video && video.size > 62914560) {
            return false;
          }
          return true;
        })
        .required(t('errorGeneralMedia')),
      generalThumbnail: Yup.mixed()
        .test('imgFormat', t('fileFormatErr'), (image: any) => {
          if (image && !acceptedImageFormats.includes(image.type)) {
            return false;
          }
          return true;
        })
        .required(t('errorGeneralThumb')),
      introFile: Yup.mixed()
        .test('format', t('fileExtErr'), (video: any) => {
          if (video && video.type !== 'video/mp4') {
            return false;
          }
          return true;
        })
        .test('size', t('fileSizeErr'), (video: any) => {
          if (video && video.size > 62914560) {
            return false;
          }
          return true;
        })
        .required(t('errorIntroMedia')),
      introThumbnail: Yup.mixed()
        .test('imgFormat', t('fileFormatErr'), (image: any) => {
          if (image && !acceptedImageFormats.includes(image.type)) {
            return false;
          }
          return true;
        })
        .required(t('errorIntroThumb')),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  useEffect(() => {
    if (params.storyId) {
      dispatch(getStoryLanguagesDetailUseCase(params.storyId as string));
    }
  }, [dispatch, params.storyId]);

  return (
    <div className='w-full overflow-y-auto'>
      <form onSubmit={formik.handleSubmit}>
        <div className='py-9 px-20'>
          <div className='general-story'>
            <span className='font-medium text-lg pb-4 mt-8 capitalize'>{t('generalHeader')}</span>
            {!isEditing && (
              <div className='pt-4 w-full relative mr-8 flex justify-end'>
                {formik.touched.locale && formik.errors.locale && (
                  <div className='formUsersError'>{formik.errors.locale}</div>
                )}
                <select
                  className={`border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary transition-all duration-150 w-1/5 ${
                    formik.touched.locale && formik.errors.locale ? 'error' : ''
                  }`}
                  name='locale'
                  onChange={(e) => formik.handleChange(e)}
                  value={formik.values.locale}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>{t('firstSelectPlaceholder')}</option>
                  {languages.map((lang: any) => (
                    <option value={lang.value} key={lang.id}>
                      {lang.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className='flex-1 py-4 items-center relative'>
              {formik.touched.title && formik.errors.title && (
                <div className='formUsersError'>{formik.errors.title}</div>
              )}
              <div className='block text-primary text-sm mr-4'>{t('requiredOne')}</div>
              <Input
                extraClass='w-full bg-sweetPurple'
                type='text'
                name='title'
                value={formik.values.title}
                placeholder={t('requiredOnePlaceholder')}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.title && formik.errors.title}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                  !/^[A-Za-z\s]*$/.test(e.key) && e.preventDefault()
                }
              />
            </div>
            <div className='flex-1 py-4 items-center relative'>
              {formik.touched.description && formik.errors.description && (
                <div className='formUsersError'>{formik.errors.description}</div>
              )}
              <div className='block text-primary text-sm mr-4'>{t('requiredTwo')}</div>
              <textarea
                name='description'
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                value={formik.values.description}
                placeholder={t('requiredTwoPlaceholder')}
                className={`h-24 formUsersInput rounded w-full ease-linear bg-sweetPurple ${
                  formik.touched.description && formik.errors.description
                    ? 'border border-red border-red-500'
                    : ''
                }`}
              />
            </div>
            <div className='pt-4 flex justify-center w-full'>
              <div className={`relative w-2/4 pt-4 flex justify-center`}>
                {formik.touched.generalFile && formik.errors.generalFile && (
                  <div className='formUsersError'>{formik.errors.generalFile}</div>
                )}
                <button
                  type='submit'
                  className={`border-2 border-primary border-solid rounded-3xl py-2 px-4 text-sm ${
                    formik.touched.generalFile && formik.errors.generalFile ? 'border-0 error' : ''
                  } w-2/6 overflow-hidden text-ellipsis whitespace-nowrap`}
                  onClick={(e) => {
                    e.preventDefault();
                    generalInputRef.current?.click();
                  }}
                >
                  {generalFileName}
                </button>
              </div>
              <div className=' relative w-2/4 pt-4 flex justify-center'>
                {formik.touched.generalThumbnail && formik.errors.generalThumbnail && (
                  <div className='formUsersError'>{formik.errors.generalThumbnail}</div>
                )}
                <button
                  type='submit'
                  className={`border-2 ml-4 border-primary border-solid rounded-3xl py-2 px-4 text-sm ${
                    formik.touched.generalThumbnail && formik.errors.generalThumbnail
                      ? 'border-0 error'
                      : ''
                  } w-2/6 overflow-hidden text-ellipsis whitespace-nowrap`}
                  onClick={(e) => {
                    e.preventDefault();
                    generalThumbnailRef.current?.click();
                  }}
                >
                  {generalThumbName}
                </button>
              </div>
            </div>
            <div className='flex py-4 items-center justify-center'>
              <div className='flex mr-4 w-full justify-center'>
                <input
                  type='file'
                  onChange={(event) => {
                    if (event.target.files) {
                      setGeneralFileName(event.target.files[0].name);
                      formik.setFieldValue('generalFile', event.target.files[0]);
                    }
                  }}
                  multiple
                  ref={generalInputRef}
                  className='hidden'
                  name='generalFile'
                  accept='.mp4'
                />
                <input
                  type='file'
                  onChange={(event) => {
                    if (event.target.files) {
                      setIntroFileName(event.target.files[0].name);
                      formik.setFieldValue('introFile', event.target.files[0]);
                    }
                  }}
                  multiple
                  ref={introInputRef}
                  className='hidden'
                  name='introFile'
                  accept='.mp4'
                />
                <input
                  type='file'
                  onChange={(event) => {
                    if (event.target.files) {
                      setGeneralThumbName(event.target.files[0].name);
                      formik.setFieldValue('generalThumbnail', event.target.files[0]);
                    }
                  }}
                  multiple
                  ref={generalThumbnailRef}
                  className='hidden'
                  name='generalThumbnail'
                  accept='image/png, image/gif, image/jpeg, image/jpg, image/heic, image/raw'
                />
                <input
                  type='file'
                  onChange={(event) => {
                    if (event.target.files) {
                      setIntroThumbName(event.target.files[0].name);
                      formik.setFieldValue('introThumbnail', event.target.files[0]);
                    }
                  }}
                  multiple
                  ref={introThumbnailRef}
                  className='hidden'
                  name='introThumbnail'
                  accept='image/png, image/gif, image/jpeg, image/jpg, image/heic, image/raw'
                />
              </div>
            </div>
          </div>
          <hr className='w-full border' />
          <div className='intro-node pt-6'>
            <div>
              <span className='font-medium text-lg pb-4 mt-8 capitalize'>{t('introHeader')}</span>
              <div className='flex py-4 items-center relative'>
                <div className='pt-4 w-full flex justify-between'>
                  <Select
                    extraClass='mx-2 w-2/6 opacity-70'
                    value={formik.values.locale}
                    name='introType'
                    placeholder={t('secondSelectPlaceholder')}
                    options={nodeTypes}
                    onChange={(e) => formik.handleChange(e)}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className='pt-4 flex justify-center w-full'>
              <div className={`relative w-2/4 pt-4 flex justify-center`}>
                {formik.touched.introFile && formik.errors.introFile && (
                  <div className='formUsersError'>{formik.errors.introFile}</div>
                )}
                <button
                  type='submit'
                  className={`border-2 border-primary border-solid rounded-3xl py-2 px-4 text-sm ${
                    formik.touched.introFile && formik.errors.introFile ? 'border-0 error' : ''
                  } w-2/6 overflow-hidden text-ellipsis whitespace-nowrap`}
                  onClick={(e) => {
                    e.preventDefault();
                    introInputRef.current?.click();
                  }}
                >
                  {introFileName}
                </button>
              </div>
              <div className=' relative w-2/4 pt-4 flex justify-center'>
                {formik.touched.introThumbnail && formik.errors.introThumbnail && (
                  <div className='formUsersError'>{formik.errors.introThumbnail}</div>
                )}
                <button
                  type='submit'
                  className={`border-2 ml-4 border-primary border-solid rounded-3xl py-2 px-4 text-sm ${
                    formik.touched.generalThumbnail && formik.errors.introThumbnail
                      ? 'border-0 error'
                      : ''
                  } w-2/6 overflow-hidden text-ellipsis whitespace-nowrap`}
                  onClick={(e) => {
                    e.preventDefault();
                    introThumbnailRef.current?.click();
                  }}
                >
                  {introThumbName}
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr className='w-full border' />
        <div className='flex items-center justify-end relative p-9'>
          <Button type='submit' extraClass='w-1/5 p-2'>
            {t('createBtn')}
          </Button>
        </div>
      </form>
      <Loading />
    </div>
  );
};

export default StoryForm;
