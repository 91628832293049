import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Redux
import { setEditUserView } from 'src/redux/slices/users.slice';

// Interfaces
import { UsersDetailAllInfo } from 'src/interfaces/usersInterface';

// Components
import UserProfilePictureForm from 'src/components/forms/admin/users/UserProfilePictureForm';

// Services
import { getUserDetailUseCase, uploadProfilePictureUseCase } from 'src/services/users/useCases';

const EditUserProfilePicture = () => {
  const params = useParams<{ userId: string }>();
  const userDetail: UsersDetailAllInfo = useAppSelector((state) => state.users.userDetail);
  const editUserView = useAppSelector((state) => state.users.editUserView);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSuccess = async (values: { profilePicture: string }) => {
    const formData = new FormData();
    formData.append('profilePicture', values.profilePicture);
    if (values.profilePicture) {
      await dispatch(
        uploadProfilePictureUseCase({ publicId: params.userId, profilePicture: formData }),
      );
    }
    dispatch(setEditUserView('photos'));
  };

  useEffect(() => {
    if (editUserView === 'photos') {
      navigate(`/admin/users/${params.userId}/edit-user/photos`);
    }
  }, [editUserView, navigate, params.userId]);

  useEffect(() => {
    if (params.userId) {
      dispatch(getUserDetailUseCase(params.userId));
    }
  }, [dispatch, params.userId]);

  return <UserProfilePictureForm onSuccess={handleSuccess} data={userDetail} isEditing={true} />;
};

export default EditUserProfilePicture;
