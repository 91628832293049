import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Interfaces
import { RejectProfileReasonsProps } from 'src/interfaces/usersInterface';

// Services
import {
  getRejectReasonsUseCase,
  getUserDetailUseCase,
  handleApproveRejectUserProfileUseCase,
} from 'src/services/users/useCases';
import UserDetailCard from 'src/components/cards/admin/users/UserDetailCard';

const UserDetail = (props: { review: boolean }) => {
  const rejectOptions = useAppSelector((state) => state.users.rejectOptions);
  const [options, setOptions] = useState([]);
  const params = useParams<{ userId: string }>();
  const userDetailData = useAppSelector((state) => state.users.userDetail);
  const navigate = useNavigate();
  const [showPopupReject, setShowPopupReject] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [index, setIndex] = useState(-1);
  let galleryImages: any[] = [];

  userDetailData.media?.forEach((img: any) => {
    galleryImages = [
      ...galleryImages,
      {
        src: img.url,
        original: img.url,
      },
    ];
  });

  const currentImage = galleryImages[index];
  const nextIndex = (index + 1) % galleryImages.length;
  const nextImage = galleryImages[nextIndex] || currentImage;
  const prevIndex = (index + galleryImages.length - 1) % galleryImages.length;
  const prevImage = galleryImages[prevIndex] || currentImage;
  const handleClick = (index: number) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  const [showPopupApprove, setShowPopupApprove] = useState<boolean>(false);
  const editUserView = useAppSelector((state) => state.users.editUserView);
  const checkedOptions = options.filter(
    (option: RejectProfileReasonsProps) => option?.checked === true,
  );

  const handleReject = async () => {
    const optionsIds: any[] = [];
    checkedOptions.forEach((option: RejectProfileReasonsProps) => {
      optionsIds.push(option.id);
    });
    setShowPopupReject(false);
    dispatch(
      handleApproveRejectUserProfileUseCase({
        publicId: params.userId,
        data: { isAValidProfile: false, reasonsList: optionsIds },
      }),
    );
  };

  const handleApprove = async () => {
    setShowPopupApprove(false);
    dispatch(
      handleApproveRejectUserProfileUseCase({
        publicId: params.userId,
        data: { isAValidProfile: true, reasonsList: [] },
      }),
    );
  };

  const handleCheckboxs = (value: string | number) => {
    const listOptions: any = options.map((op: RejectProfileReasonsProps) => {
      if (op.id === value) {
        op.checked = !op.checked;
      }
      return op;
    });
    setOptions(listOptions);
  };

  useEffect(() => {
    if (editUserView === 'finish') {
      navigate('/admin/users');
    }
  }, [editUserView, navigate, params.userId]);

  useEffect(() => {
    if (params.userId) {
      dispatch(getUserDetailUseCase(params.userId));
    }
  }, [dispatch, params.userId]);

  useEffect(() => {
    if (showPopupReject) {
      dispatch(getRejectReasonsUseCase());
    }
  }, [showPopupReject, dispatch]);

  useEffect(() => {
    const optionList: any = rejectOptions.map((opt: any) => ({
      ...opt,
      checked: false,
    }));
    setOptions(optionList);
  }, [rejectOptions]);

  return (
    <UserDetailCard
      review={props.review}
      userDetail={userDetailData}
      galleryImages={galleryImages}
      handleClick={handleClick}
      handleApprove={handleApprove}
      handleCheckboxs={handleCheckboxs}
      handleClose={handleClose}
      handleMoveNext={handleMoveNext}
      handleMovePrev={handleMovePrev}
      handleReject={handleReject}
      currentImage={currentImage}
      prevImage={prevImage}
      nextImage={nextImage}
      setShowPopupApprove={setShowPopupApprove}
      setShowPopupReject={setShowPopupReject}
      showPopupApprove={showPopupApprove}
      showPopupReject={showPopupReject}
      options={options}
      checkedOptions={checkedOptions}
    />
  );
};

export default UserDetail;
