import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Components
import ChangePasswordForm from 'src/components/forms/admin/settings/ChangePasswordForm';

// Interfaces
import { ChangePasswordFormValuesI } from 'src/interfaces/generalInterface';

// Services
import { changePasswordUseCase } from 'src/services/auth/useCases';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['settings']);
  const navigate = useNavigate();
  const role = useAppSelector((state) => state.auth.role);
  const changePasswordView = useAppSelector((state) => state.auth.changePasswordView);
  const handleChange = (values: ChangePasswordFormValuesI) => {
    dispatch(
      changePasswordUseCase({
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
        currentPassword: values.currentPassword,
        role: values.role,
      }),
    );
  };

  useEffect(() => {
    if (changePasswordView === 'updated') {
      navigate('/auth');
    }
  }, [changePasswordView, navigate]);

  return (
    <div
      className={`relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary`}
    >
      <div
        className={`mb-0 px-4 py-3 border-0 ${
          role === 'admin' ? '' : 'bg-background'
        } rounded-t-2xl`}
      >
        <div className='flex flex-wrap items-center'>
          <div className=' flex justify-between relative w-full  px-4 max-w-full flex-grow flex-1'>
            <span className='flex'>
              <div
                className={`${
                  role === 'admin' ? 'him-settings text-lg' : ' font-semibold uppercase text-sm'
                } mr-2 `}
              >
                {t('settingsHeader')}
              </div>
            </span>
          </div>
        </div>
      </div>
      <hr />
      <div className='block w-full overflow-x-auto h-full'>
        <ChangePasswordForm onSuccess={handleChange} />
      </div>
    </div>
  );
};

export default Settings;
