import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Redux
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Components
import Icon from 'src/ui/Icon';
import StoryForm from 'src/components/forms/admin/stories/StoryForm';

// Interfaces
import { CreateStoryPetitionI } from 'src/interfaces/storiesInterface';

// Services
import { createStoryUseCase } from 'src/services/stories/useCases';

const AddLocalizedStory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['stories']);
  const createStoryView = useAppSelector((state) => state.stories.createStoryView);

  const handleSuccess = (values: CreateStoryPetitionI) => {
    const sendData = Object.assign({}, values);
    delete sendData.generalFile;
    const generalformData = new FormData();
    generalformData.append('file', values.generalFile);
    generalformData.append('thumbnail', values.generalThumbnail);

    const introFormData = new FormData();
    introFormData.append('file', values.introFile);
    introFormData.append('thumbnail', values.introThumbnail);
    sendData.generalFile = generalformData;

    dispatch(
      createStoryUseCase({
        data: sendData,
        generalMedia: generalformData,
        introMedia: introFormData,
        isNew: true,
      }),
    );
  };

  useEffect(() => {
    if (createStoryView === 'created') {
      navigate('/admin/stories');
    }
  }, [createStoryView, navigate]);

  return (
    <div className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary overflow-auto'>
      <div className='flex items-center p-3 bg-background'>
        <Icon icon='backBtn' onClick={() => navigate(-1)} />
        <span className='flex font-semibold text-sm mx-2 uppercase'>{t('headerAdd')}</span>
      </div>
      <StoryForm onSuccess={handleSuccess} isEditing={false} />
    </div>
  );
};

export default AddLocalizedStory;
