import React from 'react';
import getLocation from '../../../../assets/countries.json';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';

// Interfaces
import { CoachFormikFormProps } from 'src/interfaces/ui/formInterface';
import { CoachGeneralFormProps } from 'src/interfaces/coachesInterface';

// Components
import Input from 'src/ui/Input';
import Button from 'src/ui/Button';
import Icon from 'src/ui/Icon';
import Phone from 'src/ui/Phone';

const CoachGeneralForm = (props: CoachGeneralFormProps) => {
  const { onSuccess, data, isEditing } = props;
  const role = useAppSelector((state) => state.auth.role);
  const navigate = useNavigate();
  const { t } = useTranslation(['addEditCoachForm']);
  const location = getLocation.states;
  let stateEditing: any = '';
  const findState: any = () => {
    if (formik.values.location.stateId) {
      const state = location.find((state) => state.id === Number(formik.values.location.stateId));
      return state;
    }
  };
  const findState2 = (stateId: number | undefined | string) => {
    const state = location.find((state) => state.id === stateId);
    stateEditing = state;
    return state?.id;
  };

  const findCity = (cityId: number | undefined | string) => {
    if (stateEditing) {
      const city = stateEditing.cities.find((city: any) => city.id === cityId);
      return city?.id;
    } else return '';
  };
  const formik = useFormik<CoachFormikFormProps>({
    initialValues: {
      fullName: data?.fullName || '',
      email: data?.email || '',
      phone: data?.phone || '',
      location: {
        stateId: data?.stateId !== 0 ? findState2(data?.stateId) : '',
        cityId: data?.cityId !== 0 ? findCity(data?.cityId) : '',
      },
      description: data?.description || '',
      preferredLocale: 'en-US',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required(t('errorRequiredOne'))
        .min(3, t('errorCharactersOne'))
        .trim()
        .matches(/^[A-Za-z]*(\s[A-Za-z]*)+$/, t('errorCharactersTwo')),
      email: Yup.string().email(t('errorValidTwo')).required(t('errorRequiredTwo')),
      phone: Yup.number().required(t('errorRequiredThree')),
      location: Yup.object().shape(
        {
          stateId: Yup.string().when('cityId', {
            is: (cityId: string) => cityId && cityId.length > 0,
            then: Yup.string().required(t('stateReq')),
            otherwise: Yup.string().nullable(),
          }),
          cityId: Yup.string().when('stateId', {
            is: (stateId: string) => stateId && stateId.length > 0,
            then: Yup.string().required(t('cityReq')),
            otherwise: Yup.string().nullable(),
          }),
        },
        [['cityId', 'stateId']],
      ),
      description: Yup.string().max(1000, t('errorOptionalTwo')),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  const handleHeader = () => {
    if (role === 'admin') {
      if (data) {
        return t('headerEdit');
      } else return t('headerAdd');
    } else if (role === 'coach') {
      return t('headerEditCoach');
    }
  };

  return (
    <div
      className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary'
      style={{ height: '75vh' }}
    >
      <div className='flex items-center p-3 bg-background rounded-t-2xl'>
        <Icon icon='backBtn' onClick={() => navigate(-1)} />
        <span className='flex font-semibold text-sm mx-2 uppercase'>{handleHeader()}</span>
      </div>
      <hr />
      <div className='coach-container h-full py-4'>
        <div className='coach-top flex w-full h-full'>
          <div className='coach-general w-full overflow-auto'>
            <form onSubmit={formik.handleSubmit}>
              <div className='flex px-8'>
                <div className='py-4 relative mr-8' style={{ width: isEditing ? '50%' : '33%' }}>
                  <div className='formUsersHeader'>{t('requiredOne')}</div>
                  {formik.touched.fullName && formik.errors.fullName && (
                    <div className='formUsersError'>{formik.errors.fullName}</div>
                  )}
                  <Input
                    extraClass='bg-sweetPurple w-full'
                    type='text'
                    name='fullName'
                    value={formik.values.fullName}
                    placeholder='John'
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.handleChange(e)}
                    haveError={formik.touched.fullName && formik.errors.fullName}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      !/^[A-Za-z\s]*$/.test(e.key) && e.preventDefault()
                    }
                  />
                </div>
                {!isEditing && (
                  <div className='py-4 relative mr-8' style={{ width: '33%' }}>
                    <div className='formUsersHeader'>{t('requiredTwo')}</div>
                    {formik.touched.email && formik.errors.email && (
                      <div className='formUsersError'>{formik.errors.email}</div>
                    )}
                    <Input
                      extraClass='bg-sweetPurple w-full'
                      type='email'
                      name='email'
                      value={formik.values.email}
                      placeholder='johndoe@example.com'
                      onBlur={formik.handleBlur}
                      onChange={(e) => formik.handleChange(e)}
                      haveError={formik.touched.email && formik.errors.email}
                    />
                  </div>
                )}
                <div className='py-4 relative' style={{ width: isEditing ? '50%' : '33%' }}>
                  <div className='formUsersHeader'>{t('requiredThree')}</div>
                  {formik.touched.phone && formik.errors.phone && (
                    <div className='formUsersError'>{formik.errors.phone}</div>
                  )}
                  <Phone formik={formik} />
                </div>
              </div>
              <div className='mt-8'>
                <hr />
              </div>
              <div className='font-medium text-lg p-4 mt-4 px-8 capitalize'>
                {t('headerOptional')}
              </div>
              <div>
                <div className='flex px-8'>
                  <div className='flex-1 mr-8 w-4/12 relative pt-4'>
                    <div className='formUsersHeader font-medium'>{t('location')}</div>
                    <div className='flex-1 mb-4'>
                      <div className='formUsersHeader'>{t('optionalEight')}</div>
                      {formik.touched.location?.stateId && formik.errors.location?.stateId && (
                        <div className='formUsersError'>{formik.errors.location?.stateId}</div>
                      )}
                      <select
                        className='w-full formUsersInput bg-sweetPurple'
                        name='location.stateId'
                        onChange={(e) => formik.handleChange(e)}
                        value={formik.values.location?.stateId as string}
                        onBlur={formik.handleBlur}
                      >
                        <option value=''>{t('selectThree')}</option>
                        {location.map((state) => (
                          <option value={state.id} key={state.id}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='flex-1'>
                      <div className='formUsersHeader'>{t('optionalNine')}</div>
                      {formik.touched.location?.cityId && formik.errors.location?.cityId && (
                        <div className='formUsersError'>{formik.errors.location?.cityId}</div>
                      )}
                      <select
                        className='w-full formUsersInput bg-sweetPurple'
                        name='location.cityId'
                        onChange={(e) => formik.handleChange(e)}
                        value={formik.values.location?.cityId as string}
                        onBlur={formik.handleBlur}
                      >
                        <option value=''>{t('selectFour')}</option>
                        {findState()?.cities.map((city: any) => (
                          <option value={city.id} key={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='w-8/12 relative py-4'>
                    <div className='formUsersHeader font-medium'>{t('desc')}</div>
                    {formik.touched.description && formik.errors.description && (
                      <div className='formUsersError'>{formik.errors.description}</div>
                    )}
                    <textarea
                      name='description'
                      onBlur={formik.handleBlur}
                      onChange={(e) => formik.handleChange(e)}
                      value={formik.values.description}
                      placeholder={t('optionalTwoPlaceholder')}
                      className='h-40 formUsersInput rounded w-full ease-linear'
                    />
                  </div>
                </div>
                <div className='flex justify-center'>
                  <Button type='submit' extraClass='w-2/12 p-2 mt-8'>
                    {`${data ? t('nextBtn') : t('submitBtn')}`}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachGeneralForm;
