import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Services
import { createCoachUseCase } from 'src/services/coaches/useCases';

// Components
import CoachGeneralForm from '../../../components/forms/admin/coaches/CoachGeneralForm';

const AddCoach = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const coachDetailView = useAppSelector((state) => state.coaches.coachDetailView);
  const handleSuccess = (values: any) => {
    const postCoachGeneralInfo = Object.assign({}, values);
    if (!values.location?.stateId) {
      delete postCoachGeneralInfo?.location;
    }
    if (!values.description) {
      delete postCoachGeneralInfo?.description;
    }
    dispatch(createCoachUseCase(postCoachGeneralInfo));
  };

  useEffect(() => {
    if (coachDetailView === 'additional-info') {
      navigate('additional-info');
    }
  }, [coachDetailView, navigate]);

  return <CoachGeneralForm onSuccess={handleSuccess} isEditing={false} />;
};

export default AddCoach;
