import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useTranslation } from 'react-i18next';
import Icon from 'src/ui/Icon';

// Redux
import {
  resetLocalizedStoryDetailData,
  setCreateStoryView,
  setExistsEn,
  setExistsEs,
  setStoryView,
} from 'src/redux/slices/stories.slice';

// Interfaces
import { StoriesState } from 'src/interfaces/storiesInterface';

// Components
import StoryRow from 'src/components/rows/StoryRow';

// Services
import { getStoriesUseCase } from 'src/services/stories/useCases';

const StoriesCard = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('stories');
  const [currentPage, setCurrentPage] = useState(1);
  const stories = useAppSelector((state) => state.stories.stories.data);
  const metadata = useAppSelector((state) => state.stories.stories.metadata);
  const storiesTableHeaders = [
    { title: t('firstCol'), id: 1, space: '40%' },
    { title: t('secondCol'), id: 2, space: '30%' },
    { title: t('thirdCol'), id: 3, space: '30%' },
  ];
  useEffect(() => {
    dispatch(getStoriesUseCase({ page: currentPage, limit: 10 }));
  }, [dispatch, currentPage]);

  useEffect(() => {
    dispatch(resetLocalizedStoryDetailData());
    dispatch(setCreateStoryView(''));
    dispatch(setExistsEn(false));
    dispatch(setExistsEs(false));
    dispatch(setStoryView(''));
  }, [dispatch]);

  return (
    <div
      className={`relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary`}
    >
      <div className='rounded-t mb-0 px-4 py-3 border-0'>
        <div className='flex flex-wrap items-center'>
          <div className=' flex justify-between relative w-full px-4 max-w-full flex-grow flex-1'>
            <span className='flex'>
              <div className={`him-stories mr-2 text-lg`}>{t('header')}</div>
              <Link to='/admin/stories/add-localized-story' className='linkBtn'>
                +
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className='block w-full overflow-x-auto h-full'>
        <div className='items-center bg-transparent border-collapse'>
          <div className='flex pl-12 bg-blueGray-50'>
            {storiesTableHeaders.map((header) => (
              <div
                key={header.id}
                style={{ width: header.space }}
                className={`usersTableHeader flex justify-start bg-blueGray-50 text-blueGray-500 border-blueGray-100`}
              >
                {header.title}
              </div>
            ))}
          </div>
          <div className='card-body [&>*:nth-child(odd)]:bg-tableRow [&>*:nth-child(even)]:bg-white'>
            {stories.map((story: StoriesState) => (
              <StoryRow
                key={Number(story.id)}
                id={Number(story.id)}
                titles={story.titles}
                access={story.subscriptionRequired}
                languages={story.languages}
                isDeleted={story.isDeleted}
                isPublished={story.isPublished}
              />
            ))}
          </div>
          <div className='him-pagination'>
            <Icon
              icon='left'
              extraClass={`mr-4 ${metadata?.totalCount === 0 || currentPage === 1 ? '' : null}`}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={metadata?.totalCount === 0 || currentPage === 1}
            />
            <Icon
              icon='right'
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={metadata?.totalCount === 0 || currentPage === metadata?.pageCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoriesCard;
