import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/hooks';

// Components
import Tabs from 'src/ui/Tabs';
import CoachProfile from 'src/views/admin/coaches/CoachProfile';
import NewCoachAv from 'src/views/admin/coaches/NewCoachAv';

const CoachDetail = () => {
  const role = useAppSelector((state) => state.auth.role);
  const [activeTab, setActiveTab] = useState(1);
  const { t } = useTranslation(['coachDetail']);

  return (
    <div className='coach-detail' style={{ height: '75vh' }}>
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        navbar={true}
        backBtn={true}
        availability={false}
        data={{
          first: role === 'admin' ? t('header') : t('currentCoach'),
          second: t('av'),
        }}
        content={{ one: <CoachProfile />, two: <NewCoachAv /> }}
      />
    </div>
  );
};

export default CoachDetail;
