import PhoneInput from 'react-phone-input-2';

const Phone = (props: any) => {
  const { formik } = props;
  return (
    <PhoneInput
      country='us'
      value={formik.values.phone}
      inputProps={{
        name: 'phone',
        required: true,
        autoFocus: true,
        placeholder: 'phone',
      }}
      inputStyle={{
        width: '100%',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        paddingTop: '1.3rem',
        paddingRight: '1.3rem',
        paddingBottom: '1.3rem',
        boxShadow:
          'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
      }}
      inputClass='active:ring-0 active:outline-2 active:border active:border-secondary ease-linear transition-all duration-150 !important'
      buttonStyle={{
        backgroundColor: 'inherit',
      }}
      containerStyle={{
        borderTopLeftRadius: '25px',
        borderBottomLeftRadius: '25px',
      }}
      onChange={(phone) =>
        formik.handleChange({
          target: { name: 'phone', value: phone },
        })
      }
    />
  );
};

export default Phone;
