import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import PaswordVisibilityToggle from 'src/hooks/PasswordVisibilityToggle';
import admin from '../../../assets/img/adminFinal.png';
import coach from '../../../assets/img/coachFinal.png';
import { useAppDispatch } from 'src/hooks';

// Interfaces
import { LoginFormI } from 'src/interfaces/ui/formInterface';

// Redux
import { setRole } from 'src/redux/slices/auth.slice';

// Components
import Input from '../../../ui/Input';

const LoginForm = (props: LoginFormI) => {
  const { onSuccess } = props;
  const { t } = useTranslation(['login']);
  const dispatch = useAppDispatch();
  const [inputTypeOne, ToggleIconOne] = PaswordVisibilityToggle();
  const formik = useFormik({
    initialValues: {
      role: '',
      email: '',
      password: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      role: Yup.string().required(t('roleReq')),
      email: Yup.string().email(t('invalidEmail')).required(t('emailReq')),
      password: Yup.string()
        .matches(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/, t('passMatch'))
        .min(9, t('passMin'))
        .required(t('passReq')),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} autoComplete='off'>
      {formik.touched.role && formik.errors.role ? (
        <div className='text-alert font-medium mt-2 flex justify-center'>{formik.errors.role}</div>
      ) : (
        <span className='text-primary font-medium mt-2 flex justify-center'>{t('chooseRole')}</span>
      )}
      <div className='flex justify-center'>
        <div className='flex flex-col justify-center items-center mr-4'>
          <img
            src={admin}
            alt='admin'
            width={'100px'}
            onClick={() => {
              formik.setFieldValue('role', 'admin');
              dispatch(setRole('admin'));
            }}
            aria-hidden='true'
            className={`${
              formik.values.role === 'admin' ? 'border-2 border-secondary' : ''
            } login-user`}
          ></img>
          <span className={`${formik.values.role === 'admin' ? 'text-secondary' : ''}`}>
            {t('roleOne')}
          </span>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <img
            src={coach}
            alt='admin'
            width={'100px'}
            onClick={() => {
              formik.setFieldValue('role', 'coach');
              dispatch(setRole('coach'));
            }}
            aria-hidden='true'
            className={`${
              formik.values.role === 'coach' ? 'border-2 border-secondary' : ''
            } login-user`}
          ></img>
          <span className={`${formik.values.role === 'coach' ? 'text-secondary' : ''}`}>
            {t('roleTwo')}
          </span>
        </div>
      </div>
      <div className='relative w-full mb-3'>
        {formik.touched.email && formik.errors.email ? (
          <div className='text-sm text-alert ml-2 font-medium text-left'>{formik.errors.email}</div>
        ) : (
          <div className='text-sm text-primary ml-2 font-medium text-left'>{t('firstInput')}</div>
        )}
        <Input
          type='text'
          name='email'
          value={formik.values.email}
          onChange={(e: any) => formik.handleChange(e)}
          onBlur={formik.handleBlur}
          extraClass='w-full'
          haveError={formik.touched.email && formik.errors.email}
          placeholder={t('firstInput')}
        />
      </div>
      <div className='relative w-full mb-3'>
        {formik.touched.password && formik.errors.password ? (
          <div className='text-sm text-alert ml-2 font-medium text-left'>
            {formik.errors.password}
          </div>
        ) : (
          <div className='text-sm text-primary ml-2 font-medium text-left'>{t('secondInput')}</div>
        )}
        <div className='relative'>
          <Input
            type={inputTypeOne}
            name='password'
            value={formik.values.password}
            onChange={(e: any) => formik.handleChange(e)}
            onBlur={formik.handleBlur}
            extraClass='w-full pr-12'
            haveError={formik.touched.password && formik.errors.password}
            placeholder={t('secondInput')}
          />
          {ToggleIconOne}
        </div>
      </div>
      <div className='text-center'>
        <button
          type='submit'
          className={`bg-secondary text-white active:bg-blueGray-600 text-sm font-medium capitalize rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 w-full p-2 mt-4 ${
            formik.values.email.length === 0 && formik.values.email.length === 0
              ? 'opacity-70'
              : 'opacity-100'
          }`}
          disabled={formik.values.email.length === 0 && formik.values.email.length === 0}
        >
          {t('btn')}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
