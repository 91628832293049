// Interfaces
import { IFile } from 'src/interfaces/generalInterface';

// Redux
import {
  setCurrentCoach,
  setEditCurrentCoachView,
  setMyAvailability,
} from 'src/redux/slices/currentCoach.slice';
import { setIsLoading, setResponsePopup } from 'src/redux/slices/generalSlice';
import {
  setPastSessions,
  setPastSessionsMetadata,
  setUpcomingSessions,
  setUpcomingSessionsMetadata,
} from 'src/redux/slices/wellness.slice';
import { AppThunk } from 'src/redux/store';

// Services
import {
  deleteCurrentCoachProfilePictureAction,
  getCurrentCoach,
  getMyAppointments,
  getMyAvailability,
  updateCurrentCoachAction,
  uploadCurrentCoachCertificationsAction,
  uploadCurrentCoachPictureAction,
} from './requests';

// Delete current coach profile picture use case
export const deleteCurrentCoachProfilePictureUseCase = (): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  const data = await deleteCurrentCoachProfilePictureAction()
    .then((res) => {
      dispatch(setIsLoading(false));
      if (res.status === 200) {
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'success',
            header: 'Done!',
            subtitle: 'The photo has been deleted.',
            submitBtn: 'Ok',
          }),
        );
      }
    })
    .catch((err) => {
      dispatch(setIsLoading(false));
      if (err.response.data.status === 409) {
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'error',
            header: 'Error',
            subtitle: err.response.data.detail,
            submitBtn: 'Ok',
          }),
        );
      }
    });
  return data;
};

// Get past sessions
export const getPastSessionsUseCase =
  (page: number, endDateTime: string, isDescendingOrder: boolean): AppThunk =>
  async (dispatch) => {
    return getMyAppointments({ page, endDateTime, isDescendingOrder })
      .then((res) => {
        dispatch(setPastSessions(res.data.data));
        dispatch(setPastSessionsMetadata(res.data.metadata));
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'error',
            header: 'Error',
            subtitle: err.response.data.detail,
            submitBtn: 'Ok',
          }),
        );
      });
  };

// Get upcomming sessions
export const getUpcomingSessionsUseCase =
  (page: number, startDateTime: string, isDescendingOrder: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await getMyAppointments({ page, startDateTime, isDescendingOrder })
      .then((res) => {
        dispatch(setIsLoading(false));
        dispatch(setUpcomingSessions(res.data.data));
        dispatch(setUpcomingSessionsMetadata(res.data.metadata));
      })
      .catch((err: any) => {
        dispatch(setIsLoading(false));
        console.log(err);
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'error',
            header: 'Error',
            subtitle: err.response.data.detail,
            submitBtn: 'Ok',
          }),
        );
      });
    return data;
  };

// Get my availability
export const getMyAvailabilityUseCase = (): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  const data = await getMyAvailability()
    .then((res) => {
      dispatch(setIsLoading(false));
      dispatch(setMyAvailability(res.data.data));
    })
    .catch((err: any) => {
      dispatch(setIsLoading(false));
      console.log(err);
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle: err.response.data.detail,
          submitBtn: 'Ok',
        }),
      );
    });
  return data;
};

// Get current coach profile data use case
export const getCurrentCoachUseCase = (): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  const data = await getCurrentCoach()
    .then((res) => {
      dispatch(setIsLoading(false));
      dispatch(setCurrentCoach(res.data.data));
    })
    .catch((err: any) => {
      dispatch(setIsLoading(false));
      console.log(err);
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle: err.response.data.detail,
          submitBtn: 'Ok',
        }),
      );
    });
  return data;
};

// Updatecurrent coach profile
export const updateCurrentCoachUseCase =
  (props: { updatedCoach: any }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await updateCurrentCoachAction(props)
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(setEditCurrentCoachView('additional-info'));
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'success',
            header: 'Done!',
            subtitle: 'Your information has been edited.',
            submitBtn: 'Ok',
          }),
        );
      })
      .catch((err: any) => {
        dispatch(setIsLoading(false));
        console.log(err);
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'error',
            header: 'Error',
            subtitle: err.response.data.detail,
            submitBtn: 'Ok',
          }),
        );
      });
    return data;
  };

// Upload curent coach profile picture
export const uploadCurrentCoachPictureUseCase =
  (props: { profilePicture: FormData }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await uploadCurrentCoachPictureAction(props)
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(setEditCurrentCoachView('finish'));
      })
      .catch((err: any) => {
        dispatch(setIsLoading(false));
        console.log(err);
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'error',
            header: 'Error',
            subtitle: err.response.data.detail,
            submitBtn: 'Ok',
          }),
        );
      });
    return data;
  };

// Upload current coach certifications use case
export const uploadCurrentCoachCertificationsUseCase = async (certificates: IFile[]) => {
  const send = new FormData();
  certificates.forEach((certificate) => {
    if (!certificate.inServer) send.append('certificates', certificate.file);
  });
  const count = Array.from(
    send.entries(),
    (
      [key, prop], // validate if send images length
    ) => ({
      [key]: {
        ContentLength: typeof prop === 'string' ? prop.length : prop.size,
      },
    }),
  );
  if (count.length > 0) {
    return await uploadCurrentCoachCertificationsAction({ certificates: send });
  } else {
    return true;
  }
};

// Update current coach additional info use case
export const updateCurrentCoachAdditionalInfoUseCase =
  (data: { profilePicture: FormData; certificates: IFile[] }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    if (data.profilePicture.get('profilePicture') !== '') {
      await uploadCurrentCoachPictureAction({ profilePicture: data.profilePicture })
        .then((resProfilePicture) => {
          if (resProfilePicture.status === 200) {
            if (data.certificates !== null) {
              uploadCurrentCoachCertificationsUseCase(data.certificates)
                .then(() => {
                  dispatch(setIsLoading(false));
                  dispatch(setEditCurrentCoachView('finish'));
                })
                .catch((err) => {
                  dispatch(setIsLoading(false));
                  dispatch(
                    setResponsePopup({
                      isVisible: true,
                      type: 'error',
                      header: 'Error',
                      subtitle: err.response.data.detail,
                      submitBtn: 'Ok',
                    }),
                  );
                });
            }
            dispatch(setIsLoading(false));
            dispatch(setEditCurrentCoachView('finish'));
          }
        })
        .catch((err) => {
          dispatch(setIsLoading(false));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        });
    } else {
      uploadCurrentCoachCertificationsUseCase(data.certificates)
        .then(() => {
          dispatch(setIsLoading(false));
          dispatch(setEditCurrentCoachView('finish'));
        })
        .catch((err) => {
          dispatch(setIsLoading(false));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        });
    }
  };
