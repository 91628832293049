import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';
import { SidelinkI } from 'src/interfaces/generalInterface';

const Sidelink = (props: SidelinkI) => {
  const { text, imgSrc, classes, imgClass, section } = props;
  const role = useAppSelector((state) => state.auth.role);

  return (
    <li className='items-center'>
      <NavLink
        className={`sidebar-item hover:bg-sidebarHover`}
        to={role === 'admin' ? `/admin/${section}` : `/coach/${section}`}
        style={({ isActive }) => {
          return {
            backgroundColor: isActive ? '#fbebec' : '',
            borderRadius: isActive ? '1rem' : '',
          };
        }}
      >
        <img src={imgSrc} alt={section} className={imgClass ? imgClass : 'w-5 mr-2'} />
        <span className={classes ? classes : `him-${section}`}>{text}</span>
      </NavLink>
    </li>
  );
};

export default Sidelink;
