import urls, { BuildUrl } from 'src/common/constants/urls';

// Services
import { axiosClient } from 'src/common/services/httpService';

// Interfaces
import { localizedStoryI, StoryNode } from 'src/interfaces/storiesInterface';

// Get stories
export const getStoriesAction = (stories?: { page?: number; limit?: number }) =>
  axiosClient.get(urls['stories-info'], {
    params: {
      page: stories?.page,
      limit: stories?.limit || 10,
    },
  });

// Create general story
export const createGeneralStory = async () => {
  const resp = await axiosClient.post(urls['stories-info'], { duration: 50 });
  return resp;
};

// Create story media
export const createStoryMedia = async (data: FormData) => {
  const media = await axiosClient.post(urls['story-media'], data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return media;
};

// Create localized story
export const createLocalizedStory = async (props: { data: localizedStoryI; id: any }) => {
  const localizedStory = await axiosClient.post(
    BuildUrl(urls['localized-story'], props.id),
    props.data,
  );
  return localizedStory;
};

// Edit story general info
export const editStoryGeneralInfo = async (props: { id: number; data: any }) => {
  const generalInfo = await axiosClient.patch(
    BuildUrl(urls['patch-localized-story'], props.id),
    props.data,
  );
  return generalInfo;
};

// Edit story visibility
export const editStoryVisibility = async (props: { publishStory: any; id: any }) => {
  const editLocalizedStory = await axiosClient.patch(
    BuildUrl(urls['story-visibility'], props.id),
    props.publishStory,
  );
  return editLocalizedStory;
};

// Edit story node
export const editStoryNode = async (props: { data: any; id: any }) => {
  const storyNode = await axiosClient.patch(BuildUrl(urls['story-node'], props.id), props.data);
  return storyNode;
};

// Create story node
export const createStoryNode = async (props: { data: StoryNode; id: any }) => {
  const storyNode = await axiosClient.post(BuildUrl(urls['story-node'], props.id), props.data);
  return storyNode;
};

//Delete story node
export const deleteNodeAction = (id: string) =>
  axiosClient.delete(BuildUrl(urls['story-node'], id));

// Delete localized story action
export const deleteLocalizedStoryAction = (id: string, locale: string) =>
  axiosClient.delete(BuildUrl(urls['patch-localized-story'], id));

// Get localized story detail action
export const getLocalizedStorydetailAction = (id: string, locale: string) => {
  const params = { locale: locale };
  return axiosClient.get(BuildUrl(urls['localized-story'], id), { params });
};

// Delete story simple
export const deleteStoryAction = (id: number) =>
  axiosClient.delete(BuildUrl(urls['story-update'], id));

// Get story both languages detail
export const getStoryLanguagesDetailAction = (id: string) =>
  axiosClient.get(BuildUrl(urls['story-update'], id));

// Set free story
export const setAsFreeStory = (id: number) => axiosClient.patch(BuildUrl(urls['free-story'], id));
