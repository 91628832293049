import { useFormik } from 'formik';
import * as Yup from 'yup';
import forward from '../../../assets/img/forward.svg';
import PaswordVisibilityToggle from 'src/hooks/PasswordVisibilityToggle';
import { useTranslation } from 'react-i18next';

// Interfaces
import { ChangePasswordI } from 'src/interfaces/ui/formInterface';

// Components
import Input from 'src/ui/Input';
import Button from 'src/ui/Button';

const ResetPasswordForm = (props: ChangePasswordI) => {
  const { onSuccess } = props;
  const { t } = useTranslation(['changePassword']);
  const [inputTypeOne, ToggleIconOne] = PaswordVisibilityToggle();
  const [inputTypeTwo, ToggleIconTwo] = PaswordVisibilityToggle();
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      password: Yup.string()
        .min(9, t('shortPassError'))
        .test('lower-case', t('minLowerCaseError'), (val: any) => {
          if (val) {
            const hasLowerCase = /[a-z]/;
            return hasLowerCase.test(val);
          }
          return true;
        })
        .test('upper-case', t('minUpperCaseError'), (val: any) => {
          if (val) {
            const hasUpperCase = /[A-Z]/;
            return hasUpperCase.test(val);
          }
          return true;
        })
        .test('number', t('minNumberError'), (val: any) => {
          if (val) {
            const hasNumber = /[0-9]/;
            return hasNumber.test(val);
          }
          return true;
        })
        .required(t('required')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], t('match'))
        .required(t('required2')),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-col justify-start'>
          {formik.touched.password && formik.errors.password ? (
            <div className='text-alert ml-2 font-medium text-left'>{formik.errors.password}</div>
          ) : (
            <span className='text-primary ml-2 font-medium text-left'>{t('inputOne')}</span>
          )}
          <div className='relative'>
            <Input
              type={inputTypeOne}
              name='password'
              value={formik.values.password}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              extraClass='w-full mb-4'
              haveError={formik.touched.password && formik.errors.password}
              placeholder={t('inputOne')}
            />
            {ToggleIconOne}
          </div>

          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className='text-alert ml-2 font-medium text-left'>
              {formik.errors.confirmPassword}
            </div>
          ) : (
            <span className={`${'text-primary'} ml-2 font-medium text-left`}>{t('inputTwo')}</span>
          )}
          <div className='relative'>
            <Input
              type={inputTypeTwo}
              name='confirmPassword'
              value={formik.values.confirmPassword}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              extraClass='w-full'
              haveError={formik.touched.confirmPassword && formik.errors.confirmPassword}
              placeholder={t('inputTwo')}
            />
            {ToggleIconTwo}
          </div>
        </div>
        <div className='him-footer'>
          <Button
            type='submit'
            extraClass='p-2 w-5/12 capitalize flex justify-center relative rounded-md border border-white'
          >
            <span>{t('submtBtn')}</span>
            <img src={forward} alt='forward' className='absolute right-4' />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
