import { setToken } from 'src/redux/slices/auth.slice';
import { refreshTokenUseCase } from 'src/services/auth/useCases';

import { axiosClient } from './httpService';

const setup = (store: any) => {
  axiosClient.interceptors.request.use(
    (config) => {
      const { token } = store.getState().auth;
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  axiosClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response.status === 401) {
        if (store.getState().auth.isAuthenticated && !originalConfig._retry) {
          try {
            const res: any = await refreshTokenUseCase({
              refreshToken: store.getState().auth.refreshToken,
            });
            store.dispatch(setToken(res.data.access_token));
            localStorage.setItem('him-auth-token', JSON.stringify(res.data));
            originalConfig._retry = true;
            return axiosClient(originalConfig);
          } catch (err) {
            console.error(err);
            localStorage.removeItem('him-auth-token');
            window.location.reload();
          }
        }
      }
      return Promise.reject(err);
    },
  );
};
export default setup;
