import { useAppSelector } from 'src/hooks';

export default function HeaderStats() {
  const role = useAppSelector((state) => state.auth.role);
  return (
    <>
      {/* Header */}
      <div
        className='relative bg-primary md:pt-32 pb-32 pt-12'
        style={{
          height: '156px',
          backgroundImage:
            role === 'admin'
              ? 'linear-gradient(#EA9651,#9362C7)'
              : 'linear-gradient(rgb(83, 154, 188), #9362E7',
        }}
      >
        <div className='px-4 md:px-10 mx-auto w-full'>
          <div>{/* Card stats */}</div>
        </div>
      </div>
    </>
  );
}
