import { createSlice } from '@reduxjs/toolkit';
import { CurrentCoachState } from '../state.types';

const currentCoach =
  localStorage.getItem('him-coach-logged') !== null
    ? JSON.parse(localStorage.getItem('him-coach-logged')!)
    : '';

const initialState: CurrentCoachState = {
  currentCoach: currentCoach,
  myAppointments: '',
  myAvailability: [],
  profilePicture: '',
  editCurrentCoachView: '',
};

const authSlice = createSlice({
  name: 'currentCoach',
  initialState,
  reducers: {
    setCurrentCoach: (state, action) => {
      state.currentCoach = action.payload;
    },
    setMyAppointments: (state, action) => {
      state.myAppointments = action.payload;
    },
    setMyAvailability: (state, action) => {
      state.myAvailability = action.payload;
    },
    setEditCurrentCoachView: (state, action) => {
      state.editCurrentCoachView = action.payload;
    },
  },
});

export const { setCurrentCoach, setMyAppointments, setMyAvailability, setEditCurrentCoachView } =
  authSlice.actions;

export default authSlice.reducer;
