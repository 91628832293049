import moment from 'moment';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import SessionsImg from '../../assets/img/sessions.svg';
import { useTranslation } from 'react-i18next';

// Components
import Item from 'src/ui/Item';
import Paginate from 'src/ui/pagination/Paginate';
import Session from 'src/components/cards/wellness/Session';

// Redux
import { setChangePasswordView } from 'src/redux/slices/auth.slice';

// Services
import { getPastSessionsUseCase, getUpcomingSessionsUseCase } from 'src/services/wellness/useCases';

const Appointments = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['wellness']);
  const [currentPagePastSession, setCurrentPagePastSession] = useState<number>(1);
  const [currentPageUpcomingSession, setCurrentPageUpcomingSession] = useState<number>(1);
  const newDate = moment().format('YYYY-MM-DDTHH:mm:ss');
  const { upcomingSessions, upcomingSessionsMetadata, pastSessionMetadata, pastSessions } =
    useAppSelector((state) => state.wellness);

  useEffect(() => {
    dispatch(getPastSessionsUseCase(currentPagePastSession, newDate, true));
  }, [currentPagePastSession, dispatch, newDate]);

  useEffect(() => {
    dispatch(getUpcomingSessionsUseCase(currentPageUpcomingSession, newDate, false));
  }, [currentPageUpcomingSession, dispatch, newDate]);

  useEffect(() => {
    dispatch(setChangePasswordView(''));
  }, [dispatch]);

  return (
    <div
      className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary'
      style={{ height: '75vh' }}
    >
      <div className='flex items-center p-3 bg-background rounded-t-2xl'>
        <span className='flex font-semibold text-sm mx-2 uppercase'>{t('mySessions.header')}</span>
      </div>
      <hr />
      <div className='flex h-full'>
        <div className='w-2/4 flex items-center justify-center'>
          <div className='sessions-card flex flex-col items-center'>
            <h1 className='text-left text-xl mb-5 w-full'>{t('mySessions.pastHeader')}</h1>
            {pastSessions.length > 0 && (
              <>
                <div className='max-h-64 overflow-y-scroll w-full'>
                  {pastSessions.map((session: any) => (
                    <Item key={session.appointmentId}>
                      <Session session={session} />
                    </Item>
                  ))}
                </div>
                {pastSessionMetadata.pageCount > 1 && (
                  <Paginate
                    currentPage={currentPagePastSession}
                    totalPage={pastSessionMetadata.pageCount}
                    setCurrentPage={setCurrentPagePastSession}
                  />
                )}
              </>
            )}
            {pastSessions.length === 0 && (
              <div className='flex flex-col items-center w-3/5 mt-8'>
                <img src={SessionsImg} alt='No sessions' width={80} />
                <span className='mt-8 text-2xl text-center text-primary font-medium'>
                  {t('mySessions.noPast')}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className='w-2/4 flex items-center justify-center'>
          <div className='sessions-card flex flex-col items-center'>
            <h1 className='text-left text-xl mb-5 w-full'>{t('mySessions.upcommingHeader')}</h1>
            {upcomingSessions.length > 0 && (
              <>
                <div className='max-h-64 overflow-y-scroll w-full'>
                  {upcomingSessions.map((session: any) => (
                    <Item key={session.appointmentId}>
                      <Session session={session} />
                    </Item>
                  ))}
                </div>
                <div
                  className={`${upcomingSessionsMetadata.pageCount > 1 ? 'visible' : 'invisible'}`}
                >
                  <Paginate
                    currentPage={currentPageUpcomingSession}
                    totalPage={upcomingSessionsMetadata.pageCount}
                    setCurrentPage={setCurrentPageUpcomingSession}
                  />
                </div>
              </>
            )}
            {upcomingSessions.length === 0 && (
              <div className='flex flex-col items-center w-3/5 mt-8'>
                <img src={SessionsImg} alt='No sessions' width={80} />
                <span className='mt-8 text-2xl text-center text-primary font-medium'>
                  {t('mySessions.noUpcomming')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointments;
