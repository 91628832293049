import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import key from '../../assets/img/key.svg';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useEffect } from 'react';

// Components
import OtpForm from 'src/components/forms/auth/OtpForm';
import Loading from 'src/ui/loading/Loading';

// Interfaces
import { OtpFormValuesI } from 'src/interfaces/ui/formInterface';

// Services
import { validateCodeUseCase } from 'src/services/auth/useCases';

const Otp = () => {
  const resetPasswordView = useAppSelector((state) => state.auth.resetPasswordView);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['otp']);
  const location = useLocation();
  const role = new URLSearchParams(location.search).get('role');
  const email: any = new URLSearchParams(location.search).get('email');

  const handleSuccess = async (values: OtpFormValuesI) => {
    if (role && email) {
      await dispatch(
        validateCodeUseCase({
          email: atob(email),
          role: role,
          recoveryCode: Object.values(values).join(''),
        }),
      );
    }
  };

  useEffect(() => {
    if (resetPasswordView === 'change-password') {
      navigate('/auth/change-password');
    }
  }, [resetPasswordView, navigate]);

  return (
    <div className='flex content-center items-center justify-center h-full'>
      <div className='w-full lg:w-4/12 px-4'>
        <div className='p-4 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-background border-0'>
          <div className='w-full flex flex-col items-center'>
            <img src={key} alt='key' width={'50px'} />
            <span className='text-primary text-3xl mt-8'>{t('otpHeader')}</span>
            <span className='text-primary mt-8'>{t('otpSubtitle')}</span>
            <div className='mt-12 mx-3'>
              <OtpForm onSuccess={(e) => handleSuccess(e)} />
            </div>
          </div>
        </div>
      </div>
      <Loading />
    </div>
  );
};

export default Otp;
