import { useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useTranslation } from 'react-i18next';
import { newTimes } from 'src/common/constants/availabilityOptions';

// Components
import Button from 'src/ui/Button';
import ConfirmationPopup from 'src/ui/popups/ConfirmationPopup';
import AvailabilityList from 'src/components/cards/admin/coaches/AvailabilityList';
import NewAvailabilityForm from 'src/components/forms/admin/coaches/NewAvailabilityForm';

// Interfaces
import {
  IndividualAv,
  NewAvFormValues,
  UpdateAvailabilitySendData,
} from 'src/interfaces/coachesInterface';

// Redux
import { setIsLoading, setResponsePopup } from 'src/redux/slices/generalSlice';

// Services
import {
  addNewAvailabilityBlockUseCase,
  deleteAvailabilityBlockUseCase,
  editSpecificDeleteAvBlockUseCase,
  updateAvailabilityBlockUseCase,
} from 'src/services/coaches/useCases';
import { getAppointments } from 'src/services/coaches/requests';

const NewCoachAv = () => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteAv, setShowDeleteAv] = useState<boolean>(false);
  const [showDeleteWithApp, setShowDeleteWithApp] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isEditingSpecific, setIsEditingSpecific] = useState<boolean>(false);
  const [formValues, setFormValues]: any = useState();
  const currentAvailability = useAppSelector((state) => state.coaches.currentAvailability);
  const params = useParams<{ coachId: string }>();
  const { t } = useTranslation(['coachAvailability']);
  const dispatch = useAppDispatch();

  const findStartDate = currentAvailability.availabilityList?.filter(
    (av: IndividualAv) => av.date === formValues?.dateValues.startDate,
  );
  const findEndDate = currentAvailability.availabilityList?.filter(
    (av: IndividualAv) => av.date === formValues?.dateValues.endDate,
  );

  const handleNewAv = () => {
    setShowForm(true);
    setIsEditing(false);
  };

  const handleCancel = () => {
    if (isEditingSpecific) {
      setIsEditingSpecific(false);
      setIsEditing(true);
    } else {
      setShowForm(false);
      setIsEditing(false);
      setIsEditingSpecific(false);
    }
  };

  const handleDelete = () => {
    dispatch(
      deleteAvailabilityBlockUseCase({
        coachId: params.coachId,
        data: currentAvailability,
        firstAvailabilityIdToDelete: currentAvailability?.availabilityList[0].id,
        lastAvailabilityIdToDelete:
          currentAvailability.availabilityList?.[currentAvailability?.availabilityList.length - 1]
            .id,
        availabilityList: currentAvailability?.availabilityList,
      }),
    );
    setShowDeleteAv(false);
  };

  const checkExisitingApp = async (values: NewAvFormValues) => {
    dispatch(setIsLoading(true));
    await getAppointments({
      coachId: Number(params.coachId),
      startDateTime: moment.utc(`${values.dateValues.startDate} ${values.startHour}`).format(),
      endDateTime: moment.utc(`${values.dateValues.endDate} ${values.endHour}`).format(),
    })
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.data.metadata.totalCount > 0) {
          setShowDeleteWithApp(true);
        } else {
          setShowDeleteAv(true);
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'error',
            header: 'Error',
            subtitle: err.response.data.detail,
            submitBtn: 'Ok',
          }),
        );
      });
  };
  const removeSpecificSlot = () => {
    const data: UpdateAvailabilitySendData = {
      timeOffset: 0,
      firstAvailabilityIdToDelete: findStartDate[0].id,
      lastAvailabilityIdToDelete: findEndDate[0].id,
      availabilitiesToCreate: [],
    };

    if (formValues.startHour !== currentAvailability.startHour) {
      data.availabilitiesToCreate.push({
        startHour: currentAvailability.startHour,
        endHour: formValues.startHour,
        startDate: formValues.dateValues.startDate,
        endDate: formValues.dateValues.endDate,
      });
    }
    if (formValues.endHour !== currentAvailability.endHour) {
      data.availabilitiesToCreate.push({
        endHour: currentAvailability.endHour,
        startHour: formValues.endHour,
        startDate: formValues.dateValues.startDate,
        endDate: formValues.dateValues.endDate,
      });
    }
    const checkStartRange = newTimes.slice(
      newTimes.findIndex((time) => time.value === currentAvailability.startHour),
      newTimes.findIndex((time) => time.value === formValues.startHour) + 1,
    );

    const checkEndRange = newTimes.slice(
      newTimes.findIndex((time) => time.value === formValues.endHour),
      newTimes.findIndex((time) => time.value === currentAvailability.endHour) + 1,
    );

    const checkData =
      (checkStartRange.length === 1 || checkStartRange.length >= 9) &&
      (checkEndRange.length === 1 || checkEndRange.length >= 9);

    if (checkData) {
      if (data.availabilitiesToCreate.length > 0) {
        dispatch(
          updateAvailabilityBlockUseCase({
            coachId: Number(params.coachId),
            data: data,
          }),
        );
      } else {
        if (
          formValues.dateValues.startDate === currentAvailability.startDate &&
          formValues.dateValues.endDate === currentAvailability.endDate
        ) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: t('errDeleteComplete'),
              submitBtn: 'Ok',
            }),
          );
        } else {
          dispatch(
            editSpecificDeleteAvBlockUseCase({
              coachId: params.coachId,
              firstAvailabilityIdToDelete: findStartDate[0].id,
              lastAvailabilityIdToDelete: findEndDate[0].id,
            }),
          );
        }
      }
    } else {
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle: t('errAvMaxTime'),
          submitBtn: 'Ok',
        }),
      );
    }

    setShowDeleteWithApp(false);
    setShowDeleteAv(false);
    setIsEditingSpecific(false);
    setShowForm(false);
  };

  const handleSuccess = async (values: NewAvFormValues) => {
    setFormValues(values);
    const currentAvList = currentAvailability?.availabilityList;
    if (isEditing && isEditingSpecific) {
      await checkExisitingApp(values);
    } else if (isEditing && !isEditingSpecific) {
      dispatch(
        updateAvailabilityBlockUseCase({
          coachId: Number(params.coachId),
          data: {
            timeOffset: 0,
            firstAvailabilityIdToDelete: currentAvList[0].id,
            lastAvailabilityIdToDelete: currentAvList[currentAvList.length - 1].id,
            availabilitiesToCreate: [
              {
                endHour: values.endHour,
                startHour: values.startHour,
                startDate: values.dateValues.startDate,
                endDate: values.dateValues.endDate,
              },
            ],
          },
        }),
      );
      setShowForm(false);
    } else {
      dispatch(
        addNewAvailabilityBlockUseCase({
          coachId: params.coachId as string,
          data: {
            timeOffset: 0,
            availabilities: [
              {
                startDate: values.dateValues.startDate,
                endDate: values.dateValues.endDate,
                startHour: values.startHour,
                endHour: values.endHour,
              },
            ],
          },
        }),
      );
    }
  };

  return (
    <div className='flex w-full'>
      <div
        className={`individualAv ${
          isEditingSpecific ? 'w-full' : 'w-3/5'
        } px-4 pr-4 pl-8 flex flex-col justify-center items-center`}
      >
        {!showForm && (
          <Button extraClass='normal-case py-2 px-12 rounded-lg w-2/5' onClick={handleNewAv}>
            {t('newAv')}
          </Button>
        )}
        {showForm && (
          <NewAvailabilityForm
            isEditing={isEditing}
            isEditingSpecific={isEditingSpecific}
            handleCancel={handleCancel}
            handleDeleteSpecific={() => setIsEditingSpecific(true)}
            setShowForm={setShowForm}
            onSuccess={(e) => handleSuccess(e)}
          />
        )}
      </div>
      {!isEditingSpecific && (
        <AvailabilityList
          setShowForm={setShowForm}
          setIsEditing={setIsEditing}
          setShowDeleteAv={setShowDeleteAv}
        />
      )}
      <ConfirmationPopup
        isVisible={showDeleteAv}
        title={t('deleteComplete.title')}
        subtitle={`${
          isEditingSpecific
            ? t('deleteComplete.subtitleNoApp')
            : t('deleteComplete.subtitleNoAppOne')
        }${currentAvailability.appointmentsCount === 0 ? '?' : ''}`}
        boldSubtitle={
          currentAvailability.appointmentsCount === 0 ? '' : t('deleteComplete.subtitleNoAppTwo')
        }
        submitBtn={t('deleteComplete.yes')}
        cancelBtn={t('deleteComplete.cancel')}
        handleSubmit={isEditingSpecific ? removeSpecificSlot : handleDelete}
        onClose={() => setShowDeleteAv(false)}
      />
      <ConfirmationPopup
        isVisible={showDeleteWithApp}
        title={t('deleteComplete.title')}
        subtitle={t('deleteComplete.subtitleAppOne')}
        boldSubtitle={t('deleteComplete.subtitLeAppTwo')}
        submitBtn={t('deleteComplete.yes')}
        cancelBtn={t('deleteComplete.cancel')}
        handleSubmit={removeSpecificSlot}
        onClose={() => setShowDeleteWithApp(false)}
      />
    </div>
  );
};

export default NewCoachAv;
