import { useEffect, useState } from 'react';
import Icon from './Icon';

// Interfaces
import { PopUpProps } from 'src/interfaces/generalInterface';

const PopUp = (props: PopUpProps) => {
  const { children, isVisible, extraClassesChildren, onClose, extraClass } = props;
  const [showPopUp, setShowPopUp] = useState(isVisible);

  const handleClose = () => {
    setShowPopUp(!showPopUp);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setShowPopUp(isVisible);
  }, [isVisible]);

  return (
    <div className={`him-popup ${showPopUp && 'him-bg-modal'}`}>
      {showPopUp && (
        <div className={`him-container-modal ${extraClass ? extraClass : ''}`}>
          <div className='him-close'>
            <Icon icon='close' onClick={handleClose} />
          </div>
          <div className={`him-modal-body ${extraClassesChildren || ''} `}>{children}</div>
        </div>
      )}
    </div>
  );
};

export default PopUp;
