import { iconProps } from '../interfaces/ui/iconsInterface';
import { ReactComponent as BackBtn } from '../assets/img/back.svg';
import { ReactComponent as Info } from '../assets/img/info.svg';
import { ReactComponent as Email } from '../assets/img/email.svg';
import { ReactComponent as Phone } from '../assets/img/phone.svg';
import { ReactComponent as Location } from '../assets/img/location.svg';
import { ReactComponent as Stars } from '../assets/img/stars.svg';
import { ReactComponent as Trash } from '../assets/img/delete.svg';
import { ReactComponent as Close } from '../assets/img/close-button.svg';
import { ReactComponent as Forward } from '../assets/img/forward.svg';
import { ReactComponent as EyeOpen } from '../assets/img/eyeOpen.svg';
import { ReactComponent as EyeClosed } from '../assets/img/eyeClosed.svg';
import { ReactComponent as RightArrow } from '../assets/img/right.svg';
import { ReactComponent as LeftArrow } from '../assets/img/left.svg';
import { ReactComponent as Delete } from '../assets/img/delete.svg';
import { ReactComponent as Add } from '../assets/img/add.svg';
import { ReactComponent as DeleteAll } from '../assets/img/deleteAll.svg';
import { ReactComponent as Photos } from '../assets/img/photos.svg';
import { ReactComponent as Edit } from '../assets/img/edit.svg';
import { ReactComponent as Paid } from '../assets/img/paid.svg';
import { ReactComponent as Free } from '../assets/img/free.svg';
import { ReactComponent as Published } from '../assets/img/published.svg';
import { ReactComponent as NotPublished } from '../assets/img/notPublished.svg';
import { ReactComponent as PlusSign } from '../assets/img/addBtn.svg';

const Icon = (props: iconProps) => {
  const { icon, extraClass, onClick, disabled } = props;

  const renderIcon = (iconType: string) => {
    switch (iconType) {
      case 'backBtn':
        return <BackBtn />;
      case 'info':
        return <Info />;
      case 'email':
        return <Email />;
      case 'phone':
        return <Phone />;
      case 'location':
        return <Location />;
      case 'stars':
        return <Stars />;
      case 'trash':
        return <Trash />;
      case 'close':
        return <Close />;
      case 'forward':
        return <Forward />;
      case 'eyeOpen':
        return <EyeOpen />;
      case 'eyeClosed':
        return <EyeClosed />;
      case 'right':
        return <RightArrow />;
      case 'left':
        return <LeftArrow />;
      case 'delete':
        return <Delete />;
      case 'add':
        return <Add />;
      case 'deleteAll':
        return <DeleteAll />;
      case 'photos':
        return <Photos />;
      case 'edit':
        return <Edit />;
      case 'paid':
        return <Paid />;
      case 'free':
        return <Free />;
      case 'published':
        return <Published />;
      case 'notPublished':
        return <NotPublished />;
      case 'plusSign':
        return <PlusSign />;
      default:
        <BackBtn />;
    }
    return null;
  };

  return (
    <button onClick={onClick} className={`${extraClass || ''}`} disabled={disabled} type='button'>
      {renderIcon(icon)}
    </button>
  );
};

export default Icon;
