import { Outlet } from 'react-router-dom';

// components
import AdminNavbar from '../components/navbars/AdminNavbar';
import Sidebar from '../components/sidebar/Sidebar';
import HeaderStats from '../components/headers/HeaderStats';

const Dash = () => {
  return (
    <>
      <Sidebar />
      <div className='bg-container'>
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className='px-4 md:px-10 mx-auto w-full -m-24 bg-background pb-12 h-full'>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Dash;
