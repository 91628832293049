import Button from '../Button';
import PopUp from '../Popup';
import successImg from '../../assets/img/success.svg';
import errorImg from '../../assets/img/error.svg';
import questionImg from '../../assets/img/question.svg';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Interfaces
import { ResponsePopupI } from 'src/interfaces/generalInterface';

// Redux
import { setResponsePopup } from 'src/redux/slices/generalSlice';

const ResponsePopup = () => {
  const resPopup: ResponsePopupI = useAppSelector((state) => state.general.responsePopup);
  const dispatch = useAppDispatch();

  const handleImg = () => {
    if (resPopup.type === 'success') {
      return successImg;
    } else if (resPopup.type === 'error') {
      return errorImg;
    } else return questionImg;
  };

  const handleCloseFromOutside = () => {
    resPopup.onSubmit;
    handleClose;
  };

  const handleClose = () => {
    dispatch(
      setResponsePopup({
        isVisible: false,
        type: '',
        header: '',
        subtitle: '',
        submitBtn: '',
        resPopup: '',
        boldSubtitle: '',
        onSubmit: '',
      }),
    );
  };
  return (
    <PopUp
      extraClass='max-w-lg'
      extraClassesChildren='flex flex-col items-center'
      isVisible={resPopup.isVisible}
      onClose={handleClose}
    >
      <img src={handleImg()} alt='magnifying glass' className='my-4' />
      <div className='text-primary text-3xl w-full text-center font-normal'>{resPopup.header}</div>
      <div className='block text-center'>
        <p className='text-base mt-12 mb-4 mx-8'>
          {resPopup.subtitle}
          {resPopup.boldSubtitle && (
            <b className='text-primary font-semibold'>{resPopup.boldSubtitle}</b>
          )}
        </p>
      </div>
      <div className='w-full review-footer'>
        {resPopup.cancelBtn && (
          <div className='w-1/3 mr-4'>
            <button className='him-cancel' onClick={handleClose} type='button'>
              {resPopup.cancelBtn}
            </button>
          </div>
        )}
        <Button
          extraClass='px-4 py-2 capitalize w-1/3 my-4 mb-4'
          onClick={resPopup.onSubmit ? handleCloseFromOutside : handleClose}
        >
          {resPopup.submitBtn}
        </Button>
      </div>
    </PopUp>
  );
};

export default ResponsePopup;
