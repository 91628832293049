import ReactPaginate from 'react-paginate';

const Paginate = (prop: any) => {
  const { currentPage, totalPage, setCurrentPage } = prop;

  return (
    <ReactPaginate
      forcePage={currentPage - 1}
      previousLabel={'<'}
      nextLabel={'>'}
      breakClassName={'page-link'}
      pageCount={totalPage}
      onPageChange={(e) => setCurrentPage(e.selected + 1)}
      containerClassName={'pagination flex justify-center my-2'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      activeClassName={'active'}
      activeLinkClassName={''}
      previousClassName={'page-item'}
      previousLinkClassName={'page-link'}
      nextClassName={'page-item'}
      nextLinkClassName={'page-link'}
      disabledClassName={'disabled'}
    />
  );
};

export default Paginate;
