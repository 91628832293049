import urls, { BuildUrl } from 'src/common/constants/urls';

// Services
import { axiosClient } from 'src/common/services/httpService';

// Interfaces
import { SessionParamsI } from 'src/interfaces/coach/wellness';

// Delete current coach profile picture simple
export const deleteCurrentCoachProfilePictureAction = () =>
  axiosClient.delete(urls['delete-my-profile-picture']);

// Get current coach data
export const getCurrentCoach = () => axiosClient.get(urls['current-coach']);

// Get appointments
export const getMyAppointments = (params: SessionParamsI) =>
  axiosClient.get(urls['my-appointments'], { params });

// Get my availability
export const getMyAvailability = () => axiosClient.get(urls['my-availability']);

// Update current coach
export const updateCurrentCoachAction = (props: { updatedCoach: any }) =>
  axiosClient.patch(urls['current-coach'], props.updatedCoach);

// Upload current coach profile picture
export const uploadCurrentCoachPictureAction = async (props: { profilePicture: FormData }) => {
  return await axiosClient.post(urls['add-my-profile-picture'], props.profilePicture, {
    headers: { 'Content-Type': `undefined ` },
    validateStatus: function (status) {
      return status < 500;
    },
  });
};

//Upload current coach certifications
export const uploadCurrentCoachCertificationsAction = (props: { certificates: FormData }) =>
  axiosClient.post(urls['add-my-certificates'], props.certificates);

// Delete current coach certificate
export const deleteCurrentCoachCertificateAction = (props: { certificationId: string | number }) =>
  axiosClient.delete(BuildUrl(urls['delete-my-certification'], props.certificationId));
