import defaultProfilePicture from '../../assets/img/usersProfileDefault.svg';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { dateUTCToLocale } from 'src/common/helpers/utcToLocale';
import { useTranslation } from 'react-i18next';

// Interfaces
import { Users } from 'src/interfaces/usersInterface';

// Components
import TableDropdown from '../dropdowns/TableDropdown';

const UserRow = (props: Users) => {
  const { profilePicture, fullName, email, gender, phone, lastActivity, status, publicId } = props;
  const { t } = useTranslation(['users']);

  const handleGender = () => {
    if (gender === 'NonBinary') {
      return t('userRow.nonBinary');
    } else return gender;
  };

  const handleStatus = () => {
    if (status === 'PendingReview') {
      return t('userRow.pending');
    } else if (status === 'ProfileRejected') {
      return t('userRow.rejected');
    } else return status;
  };

  return (
    <div className='hover:bg-hover flex'>
      <div
        className='usersTableCell text-left flex flex-none items-center'
        style={{ width: '20%' }}
      >
        <img
          src={profilePicture || defaultProfilePicture}
          className='h-12 w-12 bg-white  rounded-full object-cover '
          alt='...'
        />
        <ReactTooltip
          id='user-profile'
          place='top'
          className='w-32 bg-secondary text-primary rounded-full !important'
          backgroundColor='bg-secondary'
          arrowColor='#9362C7'
          textColor='white'
        />
        <i data-for='user-profile' data-tip={t('userRow.detailTooltip')}>
          <Link to={`/admin/users/${publicId}/user-detail`} className='fullName'>
            {fullName}
          </Link>
        </i>
      </div>
      <div className='usersTableCell block self-center' style={{ width: '20%' }}>
        {email}
      </div>
      <div className='usersTableCell block self-center' style={{ width: '15%' }}>
        {phone}
      </div>
      <div className='usersTableCell block self-center' style={{ width: '15%' }}>
        {handleGender()}
      </div>
      <div className='usersTableCell block self-center' style={{ width: '15%' }}>
        <i
          className={`fas fa-circle mr-2 ${
            status === 'PendingReview'
              ? 'text-back'
              : status === 'Deactivated'
              ? 'text-deactivated'
              : status === 'ProfileRejected'
              ? 'text-alert'
              : 'text-secondary'
          }`}
        />
        {handleStatus()}
      </div>
      <div className='usersTableCell block self-center' style={{ width: '15%' }}>
        <div className='flex items-center'>
          <span className='break-words'>
            {`${dateUTCToLocale(lastActivity, 'L')} ${dateUTCToLocale(lastActivity, 'LT')}`}
          </span>
          <TableDropdown user={true} id={publicId} status={Boolean(status)} thirdOption={true} />
        </div>
      </div>
    </div>
  );
};

export default UserRow;
