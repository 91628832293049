// Interfaces
import { inputProps } from '../interfaces/ui/formInterface';

const Input = (props: inputProps) => {
  const {
    type,
    placeholder,
    name,
    value,
    haveError,
    onBlur,
    onChange,
    extraClass,
    disabled,
    multiple,
    accept,
    reference,
    onKeyPress,
    checked,
    id,
  } = props;

  return (
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      className={`${haveError !== undefined ? 'error' : ''} ${extraClass || ''} ${
        disabled ? 'him-inp-disabled' : ''
      } border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary transition-all duration-150`}
      value={value}
      onChange={(e) => onChange(e)}
      onBlur={() => onBlur}
      ref={reference}
      disabled={disabled}
      multiple={multiple}
      accept={accept}
      onKeyPress={onKeyPress}
      checked={checked}
      id={id}
      autoComplete='off'
    />
  );
};

export default Input;
