// Interfaces
import { CreateStoryPetitionI, StoryNodeFormI } from 'src/interfaces/storiesInterface';

// Redux
import { setIsLoading, setResponsePopup } from 'src/redux/slices/generalSlice';
import {
  addLocalizedStoryDetail,
  deleteNode,
  deleteStory,
  editNode,
  setCreateStoryView,
  setExistsEn,
  setExistsEs,
  setFreeStory,
  setLocalizedStoryDetailEn,
  setLocalizedStoryDetailEs,
  setStories,
  setStoriesMetadata,
  setStoryDetail,
  setStoryIsPublished,
  setStoryView,
} from 'src/redux/slices/stories.slice';
import { EntryNodeI } from 'src/redux/state.types';
import { AppThunk } from 'src/redux/store';

// Services
import {
  createGeneralStory,
  createLocalizedStory,
  createStoryMedia,
  createStoryNode,
  deleteLocalizedStoryAction,
  deleteNodeAction,
  deleteStoryAction,
  editStoryGeneralInfo,
  editStoryNode,
  editStoryVisibility,
  getLocalizedStorydetailAction,
  getStoriesAction,
  getStoryLanguagesDetailAction,
  setAsFreeStory,
} from './requests';

// Create story usecase
export const createStoryUseCase =
  (props: {
    data: CreateStoryPetitionI;
    generalMedia: FormData;
    introMedia?: FormData;
    isNew: boolean;
    storyId?: string;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    let generalId = '';
    let generalMediumId = '';
    let introMediumId = 0;
    let entryNode: any = '';
    if (props.isNew) {
      const generalStory = await createGeneralStory();
      if (generalStory.status === 200) {
        generalId = generalStory.data.data.id;
      } else if (generalStory.status !== 200) {
        console.log('error creating general story');
        dispatch(setIsLoading(false));
      }
    }
    if (props.generalMedia) {
      const media = await createStoryMedia(props.generalMedia);
      if (media.status === 200) {
        generalMediumId = media.data.data.id;
      } else if (media.status !== 200) {
        console.log('error posting media');
        dispatch(setIsLoading(false));
      }
    }
    if (props.introMedia) {
      const introMedia = await createStoryMedia(props.introMedia);
      if (introMedia.status === 200) {
        introMediumId = introMedia.data.data.id;
        entryNode = {
          type: props.data.introType,
          description: props.data.introDesc,
          question: props.data.introQues,
          statement: props.data.introState,
          mediumId: introMediumId,
        };
      } else if (introMedia.status !== 200) {
        console.log('error posting media');
        dispatch(setIsLoading(false));
      }
    } else {
      entryNode = {
        type: props.data.introType,
        description: props.data.introDesc,
        question: props.data.introQues,
        statement: props.data.introState,
      };
    }
    const localizedData = {
      title: props.data.title,
      description: props.data.description,
      locale: props.data.locale,
      mediumId: generalMediumId,
      entryNode: entryNode,
    };
    const localizedStory = await createLocalizedStory({
      data: localizedData,
      id: props.isNew ? generalId : props.storyId,
    });
    if (localizedStory.status === 200) {
      dispatch(setCreateStoryView('created'));
      dispatch(setIsLoading(false));
    } else if (localizedStory.status !== 200) {
      console.log('error creating localized story');
      dispatch(setIsLoading(false));
    }
  };

// Get stories usecase
export const getStoriesUseCase =
  (stories?: { page?: number; limit?: number }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await getStoriesAction(stories)
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status === 200) {
          dispatch(setStories(res.data.data));
          dispatch(setStoriesMetadata(res.data.metadata));
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'error',
            header: 'Error',
            subtitle: err.response.data.detail,
            submitBtn: 'Ok',
          }),
        );
      });
    return data;
  };

// Edit general story use case
export const editGeneralStoryUseCase =
  (props: { data: any; newValues: any }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    let mediaId = '';
    let publishParams: any = {};
    if (props.newValues.status === 'true') {
      publishParams = { publishStory: true };
    } else {
      publishParams = { publishStory: false };
    }
    if (props.newValues.cover !== props.data.medium?.url) {
      const mediaFormData = new FormData();
      mediaFormData.append('file', props.newValues.cover);
      if (props.newValues.thumbnail !== props.data.medium?.thumbnailUrl) {
        mediaFormData.append('thumbnail', props.newValues.thumbnail);
      }
      const media = await createStoryMedia(mediaFormData);
      if (media.status === 200) {
        mediaId = media.data.data.id;
      } else if (media.status !== 200) {
        console.log('error posting media');
        dispatch(setIsLoading(false));
      }
    }
    let dataParams = {};
    if (
      props.newValues.title !== props.data.title ||
      props.newValues.description !== props.data.description ||
      props.newValues.cover !== props.data.medium?.url
    ) {
      dataParams = {
        title: props.newValues.title,
        description: props.newValues.description,
        mediumId: mediaId || props.data.mediumId,
      };
    }

    let newValues = false;
    if (props.newValues.status === 'true') {
      newValues = true;
    } else {
      newValues = false;
    }

    const storyGeneral = await editStoryGeneralInfo({
      id: props.data.id,
      data: dataParams,
    });
    if (storyGeneral.status === 200) {
      if (props.data.locale === 'en-US') {
        dispatch(setLocalizedStoryDetailEn(storyGeneral.data.data));
      } else {
        dispatch(setLocalizedStoryDetailEs(storyGeneral.data.data));
      }
      dispatch(setIsLoading(false));
    } else if (storyGeneral.status !== 200) {
      console.log('error posting storyGeneral');
      dispatch(setIsLoading(false));
    }

    if (newValues !== props.data.isPublished) {
      const visibility = await editStoryVisibility({
        publishStory: publishParams,
        id: props.data.id,
      });
      if (visibility.status === 200) {
        if (publishParams.publishStory) {
          dispatch(setStoryIsPublished(true));
        } else dispatch(setStoryIsPublished(false));
        // here
      } else if (visibility.status !== 200) {
        console.log('error posting visibility');
        dispatch(setIsLoading(false));
      }
    }
  };

// Edit story node use case
export const editStoryNodeUseCase =
  (props: { id: any; data: StoryNodeFormI; current: EntryNodeI }): AppThunk =>
  async (dispatch) => {
    let mediaId = '';
    dispatch(setIsLoading(true));
    try {
      const nodeMediaFormData = new FormData();
      nodeMediaFormData.append('file', props.data.medium);
      nodeMediaFormData.append('thumbnail', props.data.thumbnail);
      if (
        props.data.medium &&
        props.data.medium !== props.current.medium?.url
        //||(props.data.thumbnail && props.data.thumbnail !== props.current.m)
      ) {
        const media = await createStoryMedia(nodeMediaFormData);
        if (media.status !== 200) {
          dispatch(setIsLoading(false));
          throw new Error('Something went wrong');
        }
        mediaId = media.data.data.id;
      }
      const nodeData = {
        type: props.data.type,
        description: props.data.description,
        question: props.data.question,
        statement: props.data.statement,
        mediumId: mediaId || props.current?.medium?.id,
      };
      const node = await editStoryNode({ id: props.id, data: nodeData });
      dispatch(setIsLoading(false));
      dispatch(editNode({ currentId: props.id, node: node.data.data }));
    } catch (e) {
      dispatch(setIsLoading(false));
      console.log('error', e);
    }
  };

// Create story node use case
export const addStoryNodeUseCase =
  (props: { id: any; data: any; media?: any }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      let nodeData: any = '';
      if (props.media !== undefined) {
        const media = await createStoryMedia(props.media);
        if (media.status !== 200) {
          dispatch(setIsLoading(false));
          throw new Error('Something went wrong');
        }
        nodeData = {
          type: props.data.type,
          description: props.data.description,
          question: props.data.question,
          statement: props.data.statement,
          mediumId: media.data.data.id,
        };
      } else {
        nodeData = {
          type: props.data.type,
          description: props.data.description,
          question: props.data.question,
          statement: props.data.statement,
        };
      }
      const createdNode = await createStoryNode({ id: props.id, data: nodeData });
      if (createdNode.status !== 200) {
        dispatch(setIsLoading(false));
        throw new Error('Something went wrong');
      }
      dispatch(setIsLoading(false));
      dispatch(addLocalizedStoryDetail({ currentId: props.id, node: createdNode.data.data }));
    } catch (e) {
      dispatch(setIsLoading(false));
      console.log('error', e);
      throw e;
    }
  };

// Delete node use case
export const deleteNodeUseCase =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await deleteNodeAction(id)
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status === 200) {
          dispatch(deleteNode({ currentId: id }));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'Node has been successfully deleted.',
              submitBtn: 'Ok',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Delete localized story use case
export const deleteLocalizedStoryUseCase =
  (id: string, locale: string): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await deleteLocalizedStoryAction(id, locale)
      .then(() => {
        dispatch(setIsLoading(false));
        if (locale === 'en-US') {
          dispatch(setLocalizedStoryDetailEn(''));
        } else {
          dispatch(setLocalizedStoryDetailEs(''));
        }
        dispatch(setStoryView('list'));
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'success',
            header: 'Done!',
            subtitle: 'The story has been successfully deleted.',
            submitBtn: 'Ok',
          }),
        );
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        dispatch(setStoryView('list'));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

//set localized story detail EN use case
export const setLocalizedStoryEnUseCase =
  (id: string, locale: string): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await getLocalizedStorydetailAction(id, locale)
      .then((res) => {
        dispatch(setIsLoading(false));
        dispatch(setLocalizedStoryDetailEn(res.data.data));
        dispatch(setExistsEn(true));
      })
      .catch(() => {
        dispatch(setIsLoading(false));
        dispatch(setExistsEn(false));
        dispatch(setLocalizedStoryDetailEn(''));
      });
    return data;
  };

//set localized story detail ES use case
export const setLocalizedStoryEsUseCase =
  (id: string, locale: string): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await getLocalizedStorydetailAction(id, locale)
      .then((res) => {
        dispatch(setIsLoading(false));
        dispatch(setLocalizedStoryDetailEs(res.data.data));
        dispatch(setExistsEs(true));
      })
      .catch(() => {
        dispatch(setIsLoading(false));
        dispatch(setExistsEs(false));
        dispatch(setLocalizedStoryDetailEs(''));
      });
    return data;
  };

// Delete stoy use case
export const deleteStoryUseCase =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await deleteStoryAction(id)
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(deleteStory(id));
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'success',
            header: 'Done!',
            subtitle: 'The story has been successfully deleted.',
            submitBtn: 'Ok',
          }),
        );
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'error',
            header: 'Error',
            subtitle: err.response.data.detail,
            submitBtn: 'Ok',
          }),
        );
      });
    return data;
  };

// Get story languages details use case
export const getStoryLanguagesDetailUseCase =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const resp = await getStoryLanguagesDetailAction(id);
      if (resp.status !== 200) {
        dispatch(setIsLoading(false));
        throw new Error('Something went wrong');
      }
      dispatch(setIsLoading(false));
      dispatch(setStoryDetail(resp.data.data));
    } catch (error: any) {
      dispatch(setIsLoading(false));
      console.log(error, 'error');
    }
  };

// Set free story use case
export const setAsFreeStoryUseCase =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await setAsFreeStory(id)
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(setFreeStory(id));
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'success',
            header: 'Done!',
            subtitle: 'The story has been set up as free.',
            submitBtn: 'Ok',
          }),
        );
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'error',
            header: 'Error',
            subtitle: err.response.data.detail,
            submitBtn: 'Ok',
          }),
        );
      });
    return data;
  };
