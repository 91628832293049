import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import ReactTooltip from 'react-tooltip';
import defaultProfilePicture from '../../assets/img/usersProfileDefault.svg';
import { useTranslation } from 'react-i18next';

// Components
import TableDropdown from '../dropdowns/TableDropdown';

//Interface
import { Coaches } from 'src/interfaces/coachesInterface';

const CoachRow = (props: Coaches) => {
  const { id, fullName, email, address, phone, rating, profilePicture, status } = props;
  const { t } = useTranslation(['coaches']);

  return (
    <div className='hover:bg-hover flex' key={id}>
      <div
        className='usersTableCell text-left flex flex-none items-center'
        style={{ width: '20%' }}
      >
        <img
          src={profilePicture || defaultProfilePicture}
          className='h-12 w-12 bg-white  rounded-full object-cover '
          alt='...'
        />
        <ReactTooltip
          id='coach-profile'
          place='top'
          className='w-36 bg-secondary text-primary rounded-full !important'
          backgroundColor='bg-secondary'
          arrowColor='#9362C7'
          textColor='white'
        />
        <i data-for='coach-profile' data-tip={t('nameTooltip')}>
          <Link to={`/admin/coaches/${id}/coach-detail`} className='coach-name'>
            {fullName}
          </Link>
        </i>
      </div>
      <div className='usersTableCell flex flex-none  items-center' style={{ width: '20%' }}>
        {email}
      </div>
      <div className='usersTableCell flex flex-none items-center' style={{ width: '15%' }}>
        {phone}
      </div>
      <div className='usersTableCell flex flex-none items-center' style={{ width: '15%' }}>
        {address}
      </div>
      <div className='usersTableCell flex flex-none items-center' style={{ width: '15%' }}>
        {status === 'PendingReview' ? t('pending') : status}
      </div>
      <div className='usersTableCell flex flex-none items-center' style={{ width: '15%' }}>
        <div className='flex items-center'>
          <StarRatings
            rating={rating}
            starRatedColor='#9362C7'
            numberOfStars={5}
            name='rating'
            starDimension='15px'
            starSpacing='2px'
          />
          <TableDropdown
            user={false}
            id={id?.toString()}
            status={Boolean(status)}
            thirdOption={true}
          />
        </div>
      </div>
    </div>
  );
};

export default CoachRow;
