// Components
import CoachesCard from 'src/components/cards/admin/coaches/CoachesCard';

export const Coaches = () => {
  return (
    <div className='flex flex-wrap mt-4'>
      <div className='w-full mb-12 px-4'>
        <CoachesCard color='light' />
      </div>
    </div>
  );
};

export default Coaches;
