import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import key from '../../assets/img/key.svg';

// Components
import ForgotPasswordForm from 'src/components/forms/auth/ForgotPasswordForm';

// Services
import { forgotPasswordUseCase } from 'src/services/auth/useCases';

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['forgotPassword']);
  const resetPasswordView = useAppSelector((state) => state.auth.resetPasswordView);

  const handleSuccess = async (values: { email: string; role: string }) => {
    dispatch(forgotPasswordUseCase({ email: values.email, role: values.role }));
  };

  useEffect(() => {
    if (resetPasswordView === 'login') {
      navigate('/auth');
    }
  }, [resetPasswordView, navigate]);

  return (
    <div className='flex content-center items-center justify-center h-full'>
      <div className='w-full lg:w-4/12 px-4'>
        <div className='px-8 py-4 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-background border-0'>
          <div className='w-full flex flex-col items-center'>
            <img src={key} alt='key' width={'50px'} />
            <span className='text-primary text-3xl mt-8'>{t('header')}</span>
            <span className='text-primary mt-8'>{t('subtitle')}</span>
            <ForgotPasswordForm onSuccess={(e) => handleSuccess(e)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
