import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddImage from '../../../../assets/img/add.svg';
import Icon from '../../../../ui/Icon';
import Button from 'src/ui/Button';
import acceptedImageFormats from 'src/common/constants/imgFormats';
import { useAppDispatch } from 'src/hooks';

// Interfaces
import { UserFormProps } from '../../../../interfaces/ui/formInterface';
import { UserProfilePictureFormProps } from 'src/interfaces/usersInterface';

// Services
import { deleteUserProfilePictureUseCase } from 'src/services/users/useCases';
import { setAddUserView, setEditUserView } from 'src/redux/slices/users.slice';
import ConfirmationPopup from 'src/ui/popups/ConfirmationPopup';

const UserProfilePictureForm = (props: UserFormProps) => {
  const dispatch = useAppDispatch();
  const [showDeleteProfilePicture, setShowDeleteProfilePicture] = useState(false);
  const { onSuccess, data, isEditing } = props;
  const image = new Image();
  const [profilePicture, setProfilePicture] = useState<any>();
  const { t } = useTranslation(['addEditUserProfilePictureForm']);
  const currentUserId = JSON.parse(localStorage.getItem('current-user') || '');
  const [preview, setPreview] = useState<any>(data?.profilePicture || '');
  const fileInputRef = React.createRef<HTMLInputElement>();
  const navigate = useNavigate();

  const formik = useFormik<UserProfilePictureFormProps>({
    initialValues: {
      profilePicture: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      profilePicture: Yup.mixed()
        .notRequired()
        .test('type', t('errorImageFormats'), (file) => {
          if (file) {
            return acceptedImageFormats.includes(file.type);
          }
          return true;
        })
        .test('fileSize', t('errorFileSize'), (file) => {
          if (file) {
            return file.size <= 10485760;
          }
          return true;
        })
        .test('imageWidth', t('errorWidth'), (file) => {
          if (file && image.width > 2048) {
            return false;
          }
          return true;
        })
        .test('imageHeight', t('errorHeight'), (file) => {
          if (file && image.height > 2048) {
            return false;
          }
          return true;
        }),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  const handlePreviewProfile = () => {
    setProfilePicture(null);
    formik.setFieldValue('profilePicture', null);
    setPreview('');
  };

  const handleNavigation = () => {
    dispatch(setAddUserView(''));
    dispatch(setEditUserView(''));
    navigate(-1);
  };

  const handleDelete = () => {
    handlePreviewProfile();
    if (profilePicture?.inServer) {
      dispatch(deleteUserProfilePictureUseCase(currentUserId));
    }
    setShowDeleteProfilePicture(false);
  };

  useEffect(() => {
    if (data?.profilePicture) {
      const r: any = Object.assign({}, data?.profilePicture);
      r.urlPreview = data?.profilePicture;
      r.inServer = true;
      setProfilePicture(r);
      setPreview(r.urlPreview);
    }
  }, [data?.profilePicture]);

  return (
    <div
      className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary overflow-auto'
      style={{ maxHeight: '75vh' }}
    >
      <div className='flex items-center p-3 bg-background'>
        {isEditing && <Icon icon='backBtn' onClick={handleNavigation} />}
        <span className='flex font-semibold text-sm mx-2 uppercase'>
          {data ? 'Edit user profile picture' : 'Add user profile picture'}
        </span>
      </div>
      <div className='formContainer' style={{ height: '75vh' }}>
        <form
          onSubmit={formik.handleSubmit}
          className='profilePictureForm pt-4 items-center'
          style={{ height: '75vh' }}
        >
          <div className='flex justify-center pt-5 relative'>
            <div className='w-64'>
              <div className='formUsersHeader text-2xl text-center mt-8'>
                {isEditing ? data?.fullName : 'Profile picture'}
              </div>
              {formik.touched.profilePicture && formik.errors.profilePicture && (
                <div className='formUsersError mb-4'>{formik.errors.profilePicture}</div>
              )}
              {preview ? (
                <div className='relative'>
                  <img
                    src={preview}
                    alt='preview'
                    className='profile-picture'
                    //onClick={handlePreviewProfile}
                    aria-hidden='true'
                  />
                  <Icon
                    icon='delete'
                    extraClass='delete-profile-picture'
                    onClick={() => setShowDeleteProfilePicture(true)}
                  />
                </div>
              ) : (
                <button
                  type='button'
                  className='non-profile-picture bg-background'
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputRef.current?.click();
                  }}
                >
                  <img src={AddImage} alt='add' />
                </button>
              )}
              <input
                ref={fileInputRef}
                className='hidden'
                type='file'
                name='file'
                accept='image/*'
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue(
                    'profilePicture',
                    event.currentTarget.files ? event.currentTarget.files[0] : 'no file',
                  );
                  if (
                    event.target.files &&
                    event.target.files[0].type.substring(0, 5) === 'image'
                  ) {
                    const iFile = {
                      id: 1,
                      inServer: false,
                      file: event.target.files[0],
                      urlPreview: URL.createObjectURL(event.target.files[0]),
                    };
                    setProfilePicture(iFile);
                    setPreview(iFile.urlPreview);
                  }
                }}
              />
              <div className='flex justify-center mt-8'>
                <Button type='submit' extraClass='py-3.5 px-5 font-medium font-semibold capitalize'>
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ConfirmationPopup
        isVisible={showDeleteProfilePicture}
        title={t('deleteAlert.title')}
        subtitle={t('deleteAlert.subtitle')}
        submitBtn={t('deleteAlert.confirm')}
        handleSubmit={handleDelete}
        onClose={() => setShowDeleteProfilePicture(false)}
      />
    </div>
  );
};

export default UserProfilePictureForm;
