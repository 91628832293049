import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import lockImg from '../../../../assets/img/lock.svg';
import { useAppSelector } from 'src/hooks';
import PaswordVisibilityToggle from 'src/hooks/PasswordVisibilityToggle';

// Interfaces
import { ChangePasswordFormI } from 'src/interfaces/generalInterface';

// Components
import Input from 'src/ui/Input';
import Button from 'src/ui/Button';

const ChangePasswordForm = (props: ChangePasswordFormI) => {
  const role = useAppSelector((state) => state.auth.role);
  const { onSuccess } = props;
  const { t } = useTranslation(['settings']);
  const [inputTypeOne, ToggleIconOne] = PaswordVisibilityToggle();
  const [inputTypeTwo, ToggleIconTwo] = PaswordVisibilityToggle();
  const [inputTypeThree, ToggleIconThree] = PaswordVisibilityToggle();
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      role: role || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      currentPassword: Yup.string().required(t('currentPassReq')),
      newPassword: Yup.string()
        .min(9, t('shortPassError'))
        .test('lower-case', t('minLowerCaseError'), (val: any) => {
          if (val) {
            const hasLowerCase = /[a-z]/;
            return hasLowerCase.test(val);
          }
          return true;
        })
        .test('upper-case', t('minUpperCaseError'), (val: any) => {
          if (val) {
            const hasUpperCase = /[A-Z]/;
            return hasUpperCase.test(val);
          }
          return true;
        })
        .test('number', t('minNumberError'), (val: any) => {
          if (val) {
            const hasNumber = /[0-9]/;
            return hasNumber.test(val);
          }
          return true;
        })
        .required(t('newPassReq')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], t('matchError'))
        .required(t('confirmPassReq')),
      role: Yup.string().required(t('roleReq')),
    }),
    onSubmit: (values) => {
      onSuccess(values);
      formik.resetForm();
    },
  });

  return (
    <div className='flex justify-center items-center' style={{ height: '75vh' }}>
      <div className='flex w-9/12 p-4'>
        <div className='flex flex-col w-3/6 p-8 justify-center mr-8'>
          <div className='flex flex-col justify-center items-center'>
            <img src={lockImg} alt='lock' width={50} className='mb-4' />
            <span className='text-2xl text-primary mb-4'>{t('header')}</span>
          </div>
          <div>{t('instructions')}</div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className='w-3/6 px-4 pt-8 border-2 rounded-2xl bg-background'
          autoComplete='off'
        >
          <div className='flex-1 py-4 items-center relative'>
            {formik.touched.currentPassword && formik.errors.currentPassword && (
              <div className='formUsersError'>{formik.errors.currentPassword}</div>
            )}
            <div className='block text-primary text-sm mr-4'>{t('currentPass')}</div>
            <div className='relative'>
              <Input
                extraClass='w-full bg-sweetPurple'
                type={inputTypeOne}
                name='currentPassword'
                value={formik.values.currentPassword}
                placeholder={t('currentPass')}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.currentPassword && formik.errors.currentPassword}
              />
              {ToggleIconOne}
            </div>
          </div>
          <hr />
          <div className='flex-1 py-4 items-center relative'>
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='formUsersError'>{formik.errors.newPassword}</div>
            )}
            <div className='block text-primary text-sm mr-4'>{t('newPass')}</div>
            <div className='relative'>
              <Input
                extraClass='w-full bg-sweetPurple'
                type={inputTypeTwo}
                name='newPassword'
                value={formik.values.newPassword}
                placeholder={t('newPass')}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.newPassword && formik.errors.newPassword}
              />
              {ToggleIconTwo}
            </div>
          </div>
          <div className='flex-1 py-4 items-center relative'>
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='formUsersError'>{formik.errors.confirmPassword}</div>
            )}
            <div className='block text-primary text-sm mr-4'>{t('confirmPass')}</div>
            <div className='relative'>
              <Input
                extraClass='w-full bg-sweetPurple'
                type={inputTypeThree}
                name='confirmPassword'
                value={formik.values.confirmPassword}
                placeholder={t('confirmPass')}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.confirmPassword && formik.errors.confirmPassword}
              />
              {ToggleIconThree}
            </div>
          </div>
          <hr className='w-full border' />
          <div className='flex items-center justify-end relative py-9'>
            <Button type='submit' extraClass='w-auto p-2'>
              {t('changePassBtn')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
