import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AliceCarousel from 'react-alice-carousel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import acceptedImageFormats from 'src/common/constants/imgFormats';
import { validateImageDimension } from 'src/common/helpers/resizerImage';
import { useAppDispatch } from 'src/hooks';

// Redux
import { setResponsePopup } from 'src/redux/slices/generalSlice';

// Services
import { deleteUserPhoto } from 'src/services/users/requests';

// Interfaces
import { UserPhotosFormProps } from 'src/interfaces/ui/formInterface';
import { FileWithUrl, IFile } from 'src/interfaces/generalInterface';
import { UserPhotoProps } from 'src/interfaces/usersInterface';

// Components
import Button from 'src/ui/Button';
import Icon from 'src/ui/Icon';
import ConfirmationPopup from 'src/ui/popups/ConfirmationPopup';
import UploadIcon from 'src/ui/layout/UploadIcon';

const UserPhotosForm = (props: UserPhotosFormProps) => {
  const { onSuccess, data, isEditing, handleNav } = props;
  const dispatch = useAppDispatch();
  const [selectedPhotos, setSelectedPhotos] = useState<FileWithUrl[]>([]);
  const [showDeletePhotos, setShowDeletePhotos] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState<IFile>();
  const currentUserId = JSON.parse(localStorage.getItem('current-user') || '');
  const { t } = useTranslation(['addEditUserPhotosForm']);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const formik = useFormik<UserPhotoProps>({
    initialValues: {
      photos: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      photos: Yup.mixed()
        .test('length', t('maxImages'), (photos) => {
          if (photos && photos?.length > 10) {
            return false;
          }
          return true;
        })
        .test('type', t('errorImageFormats'), (photos) => {
          if (photos && photos.length > 0) {
            for (let i = 0; i < photos.length; i++) {
              if (!photos[i].inServer) {
                if (!acceptedImageFormats.includes(photos[i].file.type)) return false;
              }
            }
          }
          return true;
        })
        .test('fileSize', t('errorFileSize'), (files) => {
          let valid = true;
          if (files) {
            files.forEach((file: IFile) => {
              if (!file.inServer) {
                const size = file.file.size / 1024 / 1024;
                if (size > 10) {
                  valid = false;
                }
              }
            });
          }
          return valid;
        })
        .test('width', t('errorDimensions'), async (value) => {
          const imageArray: Array<any> = [];
          if (value) {
            value.forEach((file: IFile) => {
              if (!file.inServer) {
                const promise = validateImageDimension(file.file);
                imageArray.push(promise);
              }
            });
            const res = await Promise.all(imageArray);
            return !res.includes(false);
          }
          return true;
        }),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  const handleUpload = (upPhotos: any) => {
    let totalImgs = [];
    if (selectedPhotos.length === 10) {
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: t('maxImagesAlert.title'),
          subtitle: t('maxImagesAlert.subtitle'),
          submitBtn: t('maxImagesAlert.ok'),
        }),
      );
    } else if (upPhotos.length + selectedPhotos.length > 10) {
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: t('maxImagesUploadingAlert.title'),
          subtitle: t('maxImagesUploadingAlert.subtitle'),
          submitBtn: t('maxImagesUploadingAlert.ok'),
        }),
      );
      totalImgs = upPhotos.slice(0, upPhotos.length - selectedPhotos.length);
    } else {
      totalImgs = upPhotos;
    }
    const nArray = selectedPhotos.concat(totalImgs);
    const send = nArray.map((p, index: number) => {
      const send: any = Object.assign({}, p);
      if (!send.inServer) {
        send.id = index;
      }
      return send;
    });
    setSelectedPhotos(send);
    formik.setFieldValue('photos', send);
    formik.setFieldTouched('photos');
  };

  const deleteConfirmation = (photo: IFile) => {
    setShowDeletePhotos(true);
    setCurrentPhoto(photo);
  };

  const handleRemove = () => {
    if (currentPhoto?.inServer) {
      deleteUserPhoto({ publicId: currentUserId, photoId: currentPhoto.id }).then(() => {
        const copyPhotos = [...selectedPhotos];
        const save = copyPhotos.filter((p) => p.id !== currentPhoto.id);
        setSelectedPhotos(save);
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'success',
            header: t('deleteAlert.successHeader'),
            subtitle: t('deleteAlert.successSubtitle'),
            submitBtn: t('deleteAlert.ok'),
          }),
        );
      });
    } else {
      const copyPhotos = [...selectedPhotos];
      const save = copyPhotos.filter((p) => p.id !== currentPhoto?.id);
      setSelectedPhotos(save);
      formik.setFieldValue('photos', save);
      formik.setFieldTouched('photos');
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'success',
          header: t('deleteAlert.successHeader'),
          subtitle: t('deleteAlert.successSubtitle'),
          submitBtn: t('deleteAlert.ok'),
        }),
      );
    }
    setShowDeletePhotos(false);
  };

  const listPhotos = selectedPhotos.map((photo) => (
    <div className='image-preview' key={photo.id}>
      <Icon icon='delete' extraClass='delete-photo' onClick={() => deleteConfirmation(photo)} />
      <div
        className='urlPreview'
        style={{
          backgroundImage: `url(${photo.url || photo.urlPreview})`,
        }}
      />
    </div>
  ));

  const handleChange = function (e: any) {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      if (e.currentTarget.files.length === 1) {
        const iFile = {
          id: 1,
          inServer: false,
          file: e.currentTarget.files[0],
          urlPreview: URL.createObjectURL(e.currentTarget.files[0]),
        };
        validateImageDimension(iFile.file).then(() => {
          handleUpload([iFile]);
        });
      } else {
        const arrayImage = [...e.currentTarget.files];
        const send = arrayImage.map((file: File, index: number) => {
          const r = {
            id: index,
            file: file,
            urlPreview: URL.createObjectURL(file),
            inServer: false,
          };
          return r;
        });
        handleUpload(send);
      }
    }
  };

  useEffect(() => {
    if (data?.media) {
      const updatedPhotos = data?.media.map((file: any) => {
        const r = Object.assign({}, file);
        r.urlPreview = file.url;
        r.inServer = true;
        return r;
      });
      setSelectedPhotos(updatedPhotos);
    }
  }, [data?.media]);

  return (
    <div
      className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary overflow-auto'
      style={{ maxHeight: '75vh' }}
    >
      <div className='flex items-center p-3 bg-background'>
        {isEditing && <Icon icon='backBtn' onClick={handleNav} />}
        <span className='flex font-semibold text-sm mx-2 uppercase'>
          {data ? t('editHeader') : t('addHeader')}
        </span>
      </div>
      <div className='formContainer' style={{ height: '75vh' }}>
        <form
          className='bg-white shadow-md rounded px-8 pb-4 flex flex-col justify-center'
          style={{ height: '75vh' }}
        >
          <div className='flex justify-center relative'>
            <div className='mt-4 relative w-2/4'>
              <div className='flex justify-center'>
                <div className='formUsersHeader text-2xl mt-2'>{`${
                  isEditing ? `${data?.fullName} ${t('userPhotos')}` : t('profilePhotos')
                }`}</div>
              </div>
              <div
                className={`w-full flex justify-center ${
                  selectedPhotos.length !== 0 ? 'invisible h-0' : 'visible'
                }`}
              >
                <button
                  type='button'
                  className='formUsersProfilePicture bg-background w-64 h-64'
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputRef.current?.click();
                  }}
                >
                  <UploadIcon />
                </button>
              </div>
              <input
                type='file'
                onChange={handleChange}
                multiple
                ref={fileInputRef}
                className='hidden'
                name='file'
                accept='image/*'
              />
              <div
                className={`slider ${selectedPhotos.length === 0 ? 'invisible h-0' : 'visible'}`}
              >
                <AliceCarousel
                  mouseTracking
                  controlsStrategy='responsive'
                  responsive={{ 0: { items: 1 } }}
                  infinite
                  items={listPhotos}
                />
              </div>
            </div>
          </div>
          {formik.touched.photos && formik.errors.photos && (
            <div className='flex justify-center'>
              <div className='formCoachesError mt-1'>{formik.errors.photos}</div>
            </div>
          )}
          <div className='flex items-center flex-col'>
            <Button
              onClick={(e) => {
                e.preventDefault();
                fileInputRef.current?.click();
              }}
              extraClass='w-2/12 p-2 mt-4'
            >
              {`${selectedPhotos.length > 0 ? t('multipleSelect') : t('individualSelect')}`}
            </Button>
            <Button type='submit' extraClass='w-2/12 p-2 mt-4' onClick={formik.handleSubmit}>
              {t('submitBtn')}
            </Button>
          </div>
        </form>
      </div>
      <ConfirmationPopup
        isVisible={showDeletePhotos}
        title={t('deleteAlert.title')}
        subtitle={t('deleteAlert.subtitle')}
        submitBtn={t('deleteAlert.confirm')}
        handleSubmit={handleRemove}
        onClose={() => setShowDeletePhotos(false)}
      />
    </div>
  );
};

export default UserPhotosForm;
