import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import acceptedImageFormats from 'src/common/constants/imgFormats';

// Components
import Input from 'src/ui/Input';
import DecisionFooter from 'src/ui/layout/DecisionFooter';
import Loading from 'src/ui/loading/Loading';

// Interfaces
import { EditGeneralFormI } from 'src/interfaces/storiesInterface';

const EditGeneralForm = (props: EditGeneralFormI) => {
  const { onSuccess, data, onClose } = props;
  const { t } = useTranslation(['localizedStory']);
  const coverRef = React.createRef<HTMLInputElement>();
  const thumbnail = React.createRef<HTMLInputElement>();
  const [fileName, setFileName] = useState<string>(t('media'));
  const [thumbName, setThumbName] = useState<string>(t('thumbnail'));
  const status = [
    { name: 'Publish', value: 'true', id: 1 },
    { name: 'Unpublish', value: 'false', id: 2 },
  ];
  const formik = useFormik({
    initialValues: {
      status: data ? (data.isPublished ? 'true' : 'false') : 'false',
      title: data.title || '',
      description: data.description || '',
      cover: data.medium?.url || '',
      thumbnail: data.medium?.thumbnailUrl || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      status: Yup.string().required(t('statusRequired')),
      title: Yup.string().required(t('titleRequired')),
      description: Yup.string().required(t('descRequired')),
      cover: Yup.mixed()
        .test('format', t('fileExtErr'), (video: any) => {
          if (video !== data.medium?.url) {
            if (video && video.type !== 'video/mp4') {
              return false;
            }
            return true;
          }
          return true;
        })
        .test('size', t('fileSizeErr'), (video: any) => {
          if (video !== data.medium?.url) {
            if (video && video.size > 62914560) {
              return false;
            }
            return true;
          }
          return true;
        })
        .required(t('mediaRequired')),
      thumbnail: Yup.mixed()
        .test('imgFormat', t('fileFormatErr'), (image: any) => {
          if (image !== data.medium?.thumbnailUrl) {
            if (image && !acceptedImageFormats.includes(image.type)) {
              return false;
            }
            return true;
          }
          return true;
        })
        .required(t('errorIntroThumb')),
    }),
    onSubmit: async (values) => {
      onSuccess(values);
    },
  });

  useEffect(() => {
    if (data?.medium) {
      setFileName(data.medium?.url);
      setThumbName(data.medium?.thumbnailUrl);
    }
  }, [data.medium]);

  useEffect(() => {
    if (
      formik.values.cover !== data.medium?.url &&
      formik.values.thumbnail === data.medium?.thumbnailUrl
    ) {
      formik.setFieldValue('thumbnail', '');
      setThumbName(t('thumbnail'));
    } else if (
      formik.values.thumbnail !== data.medium?.thumbnailUrl &&
      formik.values.cover === data.medium?.url
    ) {
      formik.setFieldValue('cover', '');
      setFileName(t('media'));
    }
  }, [data.medium?.thumbnailUrl, data.medium?.url, formik, t]);

  return (
    <div className='w-full px-8 pt-8 pb-4'>
      <form onSubmit={formik.handleSubmit}>
        <span className='text-2xl'>{t('editHeader')}</span>
        <div className='flex w-full mt-4'>
          <div className='flex w-full items-center relative mb-2 pt-4'>
            {formik.touched.status && formik.errors.status && (
              <div className='formUsersError'>{formik.errors.status}</div>
            )}
            <span className='font-medium pr-1'>{t('status')}</span>
            <select
              className={`border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary transition-all duration-150 mx-2 w-full ${
                formik.touched.status && formik.errors.status ? 'error' : ''
              }`}
              name='status'
              onChange={(e) => formik.handleChange(e)}
              value={formik.values.status}
              onBlur={formik.handleBlur}
            >
              <option value=''>{t('accessPlaceholder')}</option>
              {status.map((lang: any) => (
                <option value={lang.value} key={lang.id}>
                  {lang.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='flex-1 py-4 items-center relative'>
          {formik.touched.title && formik.errors.title && (
            <div className='formUsersError'>{formik.errors.title}</div>
          )}
          <div className='block text-primary mr-4 font-medium pr-1'>{t('title')}</div>
          <Input
            extraClass='w-full bg-sweetPurple'
            type='text'
            name='title'
            value={formik.values.title}
            placeholder={t('titlePlaceholder')}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            haveError={formik.touched.title && formik.errors.title}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              !/^[A-Za-z\s]*$/.test(e.key) && e.preventDefault()
            }
          />
        </div>
        <div className='flex-1 py-4 items-center relative'>
          {formik.touched.description && formik.errors.description && (
            <div className='formUsersError'>{formik.errors.description}</div>
          )}
          <div className='block text-primary font-medium pr-1 mr-4'>{t('desc')}</div>
          <textarea
            name='description'
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            value={formik.values.description}
            placeholder={t('descPlaceholder')}
            className={`h-24 formUsersInput rounded w-full ease-linear bg-sweetPurple ${
              formik.touched.description && formik.errors.description
                ? 'border border-red border-red-500'
                : ''
            }`}
          />
        </div>
        <input
          type='file'
          onChange={(event) => {
            if (event.target.files) {
              formik.setFieldValue('cover', event.target.files[0]);
              setFileName(event.target.files[0].name);
            }
          }}
          ref={coverRef}
          className='hidden'
          name='cover'
          accept='.mp4'
        />
        <input
          type='file'
          onChange={(event) => {
            if (event.target.files) {
              formik.setFieldValue('thumbnail', event.target.files[0]);
              setThumbName(event.target.files[0].name);
            }
          }}
          multiple
          ref={thumbnail}
          className='hidden'
          name='thumbnail'
          accept='image/png, image/gif, image/jpeg, image/jpg, image/heic, image/raw'
        />
        <div className='py-4 flex justify-center w-full'>
          <div className={`relative flex pt-6 justify-start`} style={{ width: '48%' }}>
            {formik.touched.cover && formik.errors.cover && (
              <div className='formUsersError'>{formik.errors.cover}</div>
            )}
            <div className='flex flex-col w-full'>
              <span className='font-medium pr-1'>{t('trailer')}</span>
              <button
                type='submit'
                className={`border-2 border-primary border-solid rounded-3xl py-2 px-4 text-sm break-words ${
                  formik.touched.cover && formik.errors.cover ? 'border-0 error' : ''
                } w-full overflow-hidden text-ellipsis whitespace-nowrap`}
                onClick={(e) => {
                  e.preventDefault();
                  coverRef.current?.click();
                }}
              >
                {fileName}
              </button>
            </div>
          </div>
          <div className='relative ml-4 flex pt-6 justify-start' style={{ width: '48%' }}>
            {formik.touched.thumbnail && formik.errors.thumbnail && (
              <div className='formUsersError'>{formik.errors.thumbnail}</div>
            )}
            <div className='flex flex-col w-full'>
              <span className='font-medium pr-1'>{t('thumb')}</span>
              <button
                type='submit'
                className={`border-2 border-primary border-solid rounded-3xl py-2 px-4 text-sm ${
                  formik.touched.thumbnail && formik.errors.thumbnail ? 'border-0 error' : ''
                } w-full overflow-hidden text-ellipsis whitespace-nowrap`}
                onClick={(e) => {
                  e.preventDefault();
                  thumbnail.current?.click();
                }}
              >
                {thumbName}
              </button>
            </div>
          </div>
        </div>
        <hr />
        <DecisionFooter cancel={t('cancelBtn')} onCancel={onClose} approve={t('editBtn')} />
      </form>
      <Loading />
    </div>
  );
};
export default EditGeneralForm;
