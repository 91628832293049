import { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  onClick?: (e: any) => void;
  // eslint-disable-next-line react/require-default-props
  extraClass?: string;
  children?: any;
}
function Button(props: ButtonProps) {
  const { onClick, disabled, extraClass, children } = props;
  return (
    <button
      className={`bg-secondary text-white border-2 border-white border-solid active:bg-blueGray-600 text-sm font-medium uppercase rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 ${
        disabled ? 'opacity-70 cursor-auto' : ''
      } ${extraClass || ''}`}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type='submit'
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
