import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from '../state.types';

const existToken =
  localStorage.getItem('him-auth-token') !== null
    ? JSON.parse(localStorage.getItem('him-auth-token')!)
    : '';

const existRefreshToken =
  localStorage.getItem('focus-token') !== null
    ? JSON.parse(localStorage.getItem('him-refresh-token')!)
    : '';

const currentRole =
  localStorage.getItem('him-role') !== null ? JSON.parse(localStorage.getItem('him-role')!) : '';

const initialState: AuthState = {
  role: currentRole,
  token: existToken,
  refreshToken: existRefreshToken,
  isAuthenticated: !!existToken,
  resetPasswordView: '',
  changePasswordView: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
      state.isAuthenticated = true;
    },
    setRefreshToken(state, action) {
      state.refreshToken = action.payload;
    },
    setResetPasswordView(state, action) {
      state.resetPasswordView = action.payload;
    },
    setChangePasswordView(state, action) {
      state.changePasswordView = action.payload;
    },
  },
});

export const { setRole, setToken, setRefreshToken, setResetPasswordView, setChangePasswordView } =
  authSlice.actions;

export default authSlice.reducer;
