import { useTranslation } from 'react-i18next';
import logo from '../../assets/img/him-logo.png';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { Link, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

// Components
import LoginForm from 'src/components/forms/auth/LoginForm';

// Interfaces
import { LoginFormValuesI } from 'src/interfaces/ui/formInterface';

// Services
import { loginUseCase } from 'src/services/auth/useCases';

// Redux
import { setResetPasswordView } from 'src/redux/slices/auth.slice';
import { setRole } from 'src/redux/slices/auth.slice';

const Login = () => {
  const token = useAppSelector((state) => state.auth.token);
  const role = useAppSelector((state) => state.auth.role);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['login']);

  const handleSuccess = async (values: LoginFormValuesI) => {
    dispatch(
      loginUseCase({
        email: values.email,
        password: values.password,
        role: values.role === 'admin' ? 'Admin' : 'Coach',
      }),
    );
  };

  useEffect(() => {
    dispatch(setResetPasswordView(''));
  }, [dispatch]);

  if (token && token.length > 0) {
    if (role === 'admin') {
      return <Navigate to='/admin/users' />;
    } else if (role === 'coach') {
      return <Navigate to='/coach/appointments' />;
    }
  }

  return (
    <div className='container mx-auto px-4 h-full'>
      <div className='flex content-center items-center justify-center h-full'>
        <div className='w-full lg:w-4/12 px-4'>
          <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-background border-0'>
            <div className='rounded-t mb-0 px-6 pt-6'>
              <div className='text-center mb-3 flex justify-center flex-col'>
                <div className='text-blueGray-500 text-sm font-bold flex flex-col items-center'>
                  <img src={logo} alt='logo' width={'150px'} />
                </div>
                <span className='text-primary text-3xl mt-2'>{t('title')}</span>
              </div>
            </div>
            <div className='flex-auto px-4 lg:px-10 py-10 pt-0'>
              <LoginForm onSuccess={(e) => handleSuccess(e)} />
              <Link
                to='/auth/forgot-password'
                className='text-sm underline underline-offset-4 font-medium hover:shadow-md'
              >
                {t('forgotPassword')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
