import urls, { BuildUrl } from 'src/common/constants/urls';

// Services
import { axiosClient } from 'src/common/services/httpService';

// Interfaces
import {
  AvailabilitySendData,
  CoachGeneralInfo,
  UpdateAvailabilitySendData,
} from 'src/interfaces/coachesInterface';

// Delete coach
export const deleteCoachAction = (props: { id: any }) =>
  axiosClient.delete(urls['coach-info'], {
    params: {
      coachId: props.id,
    },
  });

// Deactivate/activate coach
export const updateCoachStatusAction = (props: { coachId: string | undefined; status: boolean }) =>
  axiosClient.patch(BuildUrl(urls['update-coach-status'], Number(props.coachId)), {
    isThisCoachActivated: props.status,
  });

// Delete coach profile picture
export const deleteCoachProfilePictureAction = (coachId: string | undefined) =>
  axiosClient.delete(BuildUrl(urls['delete-coach-picture'], coachId));

// add availability
export const addAvailabilityBlock = (info: { coachId: string; data: any }) =>
  axiosClient.post(BuildUrl(urls['coach-availability'], info.coachId), info.data);

// Delete availability
export const deleteAvailabilityBlock = (props: { coachId: string | undefined; data: any }) =>
  axiosClient.delete(BuildUrl(urls['delete-availability'], props.coachId), {
    data: {
      firstAvailabilityIdToDelete: props.data.firstAvailabilityIdToDelete,
      lastAvailabilityIdToDelete: props.data.lastAvailabilityIdToDelete,
    },
  });
// Update AvailabilityBlock
export const updateAvailabilityBlock = (props: {
  coachId: number | undefined;
  data: UpdateAvailabilitySendData;
}) => axiosClient.patch(`coaches/${props.coachId}/availability`, props.data);

// create coach action
export const createCoachAction = (newCoach: CoachGeneralInfo) =>
  axiosClient.post(urls['coach-info'], newCoach);

//Upload coach profile picture
export const uploadCoachPictureAction = (props: {
  coachId?: string | undefined;
  profilePicture: FormData;
}) =>
  axiosClient.post(BuildUrl(urls['post-coach-picture'], props.coachId), props.profilePicture, {
    headers: { 'Content-Type': `undefined ` },
    validateStatus: function (status) {
      return status < 500;
    },
  });

// Upload coach certifications
export const uploadCoachCertificationsAction = (props: {
  coachId: string;
  certificates: FormData;
}) => axiosClient.post(BuildUrl(urls['post-certificates'], props.coachId), props.certificates);

//Get coach availability
export const getCoachAvailability = (info: { coachId: string | number | undefined }) =>
  axiosClient.get(BuildUrl(urls['coach-availability'], info.coachId));

//Get coach appointments
export const getAppointments = (info: {
  coachId: string | number | undefined;
  startDateTime: string;
  endDateTime: string;
}) =>
  axiosClient.get(BuildUrl(urls['wellness-sessions'], info.coachId), {
    params: {
      page: 1,
      limit: 10,
      startDateTime: info.startDateTime,
      endDateTime: info.endDateTime,
    },
  });

// Get coach detail
export const getCoachDetailAction = (coachId: string) =>
  axiosClient.get(urls['coach-info'], {
    params: {
      coachId: coachId,
    },
  });

// Update coach
export const updateCoachAction = (props: { coachId: string | undefined; updatedCoach: any }) =>
  axiosClient.patch(BuildUrl(urls['update-coach'], props.coachId), props.updatedCoach);

// Get coaches list
export const getCoachesListAction = (coaches?: { page?: number; limit?: number }) =>
  axiosClient.get(urls['coaches-list'], {
    params: {
      page: coaches?.page,
      limit: coaches?.limit || 10,
    },
  });

// Delete coach certificate
export const deleteCertificateAction = (props: {
  coachId: string | undefined;
  certificationId: string | number;
}) =>
  axiosClient.delete(BuildUrl(urls['delete-certification'], props.coachId, props.certificationId));
