import urls from 'src/common/constants/urls';

// Services
import { axiosClient } from 'src/common/services/httpService';

// Interfaces
import { LoginI } from 'src/interfaces/generalInterface';

export const changePasswordAction = (props: {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  role: string;
}) => axiosClient.post(urls['change-pass'], props);

// Validate code
export const validateCodeAction = (props: { email: string; recoveryCode: string; role: string }) =>
  axiosClient.post(urls['validate-code'], {
    email: props.email,
    recoveryCode: props.recoveryCode,
    role: props.role,
  });

// forgot password
export const forgotPasswordAction = (props: { email: string; role: string }) =>
  axiosClient.post(urls['forgot-password'], {
    email: props.email,
    role: props.role,
  });

// reset password
export const resetPasswordAction = (props: {
  code: string;
  email: string;
  role: string;
  newPassword: string;
}) =>
  axiosClient.post(urls['reset-pass'], {
    code: props.code,
    email: props.email,
    role: props.role,
    newPassword: props.newPassword,
  });

// Login action
export const loginAction = (user: LoginI) => axiosClient.post(urls['login-user'], user);

// refresh token action
export const refreshTokenAction = (refreshToken: any) =>
  axiosClient.post(urls['refresh-token'], refreshToken);
