import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import acceptedImageFormats from 'src/common/constants/imgFormats';

// Components
import Input from 'src/ui/Input';
import DecisionFooter from 'src/ui/layout/DecisionFooter';
import Loading from 'src/ui/loading/Loading';

// Interfaces
import { NodeFormI, StoryNodeFormI } from 'src/interfaces/storiesInterface';

const NodeForm = (props: NodeFormI) => {
  const { onSuccess, data, isEditing, currentNode, onClose, isResponse, responseId } = props;
  const { t } = useTranslation(['storyNode']);
  const mediaRef = React.createRef<HTMLInputElement>();
  const thumbRef = React.createRef<HTMLInputElement>();
  const [fileName, setFileName] = useState<string>(t('media'));
  const [thumbName, setThumbName] = useState<string>(t('thumbnail'));

  const currentResponse: any = () => {
    const getResponse = data?.children?.filter((child: StoryNodeFormI) => child.id === responseId);
    return getResponse?.[0];
  };

  const formik = useFormik({
    initialValues: {
      type: isResponse ? currentResponse()?.type : data ? data.type : '',
      medium: (data?.medium?.url as string) || '',
      thumbnail: (data?.medium?.thumbnailUrl as string) || '',
      question: isResponse ? currentResponse()?.question : data ? data.question : '',
      statement: isResponse ? currentResponse()?.statement : data ? data.statement : '',
      isEditing: isEditing ? isEditing : false,
      currentNode: currentNode || '',
      isResponse: isResponse ? isResponse : false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      type: Yup.string().required(t('typeRequired')),
      medium: Yup.mixed().when(['type', 'isResponse'], {
        is: (type: string, isResponse: boolean) => type !== 'Question' && !isResponse,
        then: Yup.mixed()
          .test('format', t('fileExtErr'), (medium: any) => {
            if (medium !== data?.medium?.url) {
              if (medium && medium.type !== 'video/mp4') {
                return false;
              }
              return true;
            }
            return true;
          })
          .test('size', t('fileSizeErr'), (video: any) => {
            if (video !== data?.medium?.url) {
              if (video && video.size > 62914560) {
                return false;
              }
              return true;
            }
            return true;
          })
          .required(t('mediumRequired')),
        otherwise: Yup.string(),
      }),
      thumbnail: Yup.mixed().when(['type', 'isResponse'], {
        is: (type: string, isResponse: boolean) => type !== 'Question' && !isResponse,
        then: Yup.mixed()
          .test('imgFormat', t('fileFormatErr'), (image: any) => {
            if (image !== data?.medium?.thumbnailUrl) {
              if (image && !acceptedImageFormats.includes(image.type)) {
                return false;
              }
              return true;
            }
            return true;
          })
          .required(t('thumbRequired')),
        otherwise: Yup.string(),
      }),
      question: Yup.string().when(['type', 'isResponse'], {
        is: (type: string, isResponse: boolean) => type === 'Question' && !isResponse,
        then: Yup.string().required(t('questionRequired')),
      }),
      statement: Yup.string()
        .when(['type', 'currentNode'], {
          is: (type: string, currentNode: string) =>
            (type === 'LearningMoment' || type === 'Final' || type === 'Restart') &&
            currentNode === 'Question',
          then: Yup.string().required(t('statementRequired')),
          otherwise: Yup.string(),
        })
        .when('type', {
          is: 'Question',
          then: Yup.string(),
        })
        .when('type', {
          is: 'Restart',
          then: Yup.string().required(t('statementRequired')),
        })
        .when('type', {
          is: 'LearningMoment',
          then: Yup.string(),
        })
        .when('type', {
          is: 'Final',
          then: Yup.string(),
        }),
    }),
    onSubmit: async (values) => {
      onSuccess(values);
    },
  });

  const handleModalHeader = () => {
    if (isEditing) {
      if (currentNode === 'Question' && !isResponse) {
        return t('headerEditQ');
      } else if (currentNode === 'Question' && isResponse) {
        return t('headerEditR');
      } else if (currentNode === 'LearningMoment') {
        return t('headerEditL');
      } else if (currentNode === 'Introduction') {
        return t('headerEditI');
      } else if (currentNode === 'Restart') {
        return t('headerEditR');
      } else if (currentNode === 'Final') {
        return t('headerEditF');
      }
    } else {
      if (currentNode === 'Question') {
        return t('headerAddR');
      } else return t('headerAddN');
    }
  };

  const nodeTypesNode = [
    { name: t('learningMType'), value: 'LearningMoment', id: 2 },
    { name: t('questionType'), value: 'Question', id: 3 },
    { name: t('finalType'), value: 'Final', id: 4 },
  ];

  const nodeTypesQuestion = [
    { name: t('learningMType'), value: 'LearningMoment', id: 2 },
    { name: t('questionType'), value: 'Question', id: 3 },
    { name: t('restartType'), value: 'Restart', id: 4 },
    { name: t('finalType'), value: 'Final', id: 5 },
  ];

  useEffect(() => {
    if (data?.medium) {
      setFileName(data.medium?.url);
      setThumbName(data.medium?.thumbnailUrl);
    }
  }, [data?.medium]);

  useEffect(() => {
    if (isEditing) {
      if (
        formik.values.medium !== data?.medium?.url &&
        formik.values.thumbnail === data?.medium?.thumbnailUrl
      ) {
        formik.setFieldValue('thumbnail', '');
        setThumbName(t('thumbnail'));
      } else if (
        formik.values.thumbnail !== data?.medium?.thumbnailUrl &&
        formik.values.medium === data?.medium?.url
      ) {
        formik.setFieldValue('medium', '');
        setFileName(t('media'));
      }
    }
  }, [data?.medium?.thumbnailUrl, data?.medium?.url, formik, isEditing, t]);

  return (
    <div className='w-full p-8'>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <span className='text-2xl pb-4 mt-8 normal-case'>{handleModalHeader()}</span>
          {!isEditing && (
            <div className='flex py-4 items-center relative'>
              <div className='pt-4 w-full relative flex items-center'>
                {formik.touched.type && formik.errors.type && (
                  <div className='formUsersError'>{formik.errors.type as string}</div>
                )}
                <span className='font-medium pr-1'>{t('type')}</span>
                <select
                  className={`border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary transition-all duration-150 mx-2 w-3/4 ${
                    formik.touched.type && formik.errors.type ? 'error' : ''
                  } ${formik.values.isEditing ? 'opacity-70' : ''}`}
                  name='type'
                  onChange={(e) => formik.handleChange(e)}
                  value={formik.values.type}
                  onBlur={formik.handleBlur}
                  disabled={formik.values.isEditing ? true : false}
                >
                  <option value=''>
                    {formik.values.isEditing ? t('intro') : t('secondSelectPlaceholder')}
                  </option>
                  {currentNode === 'Question' &&
                    nodeTypesQuestion.map((type: any) => (
                      <option value={type.value} key={type.id}>
                        {type.name}
                      </option>
                    ))}
                  {currentNode !== 'Question' &&
                    nodeTypesNode.map((type: any) => (
                      <option value={type.value} key={type.id}>
                        {type.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
          {formik.values.type !== 'Question' && !isResponse && (
            <div className={`py-4 w-full relative  flex justify-between`}>
              <div className={`relative w-2/4 flex pt-6 justify-start`}>
                {formik.touched.medium && formik.errors.medium && (
                  <div className='formUsersError'>{formik.errors.medium}</div>
                )}
                <div className='flex flex-col w-full'>
                  <span className='font-medium pr-1'>{t('video')}</span>
                  <button
                    type='submit'
                    className={`border-2 border-primary border-solid rounded-3xl py-2 px-4 text-sm ${
                      formik.touched.medium && formik.errors.medium ? 'border-0 error' : ''
                    } w-full overflow-hidden text-ellipsis whitespace-nowrap`}
                    onClick={(e) => {
                      e.preventDefault();
                      mediaRef.current?.click();
                    }}
                  >
                    {fileName}
                  </button>
                </div>
                <input
                  type='file'
                  onChange={(event) => {
                    if (event.target.files) {
                      formik.setFieldValue('medium', event.target.files[0]);
                      setFileName(event.target.files[0].name);
                    }
                  }}
                  ref={mediaRef}
                  className='hidden'
                  name='medium'
                  accept='.mp4'
                />
              </div>
              <div className={`relative w-2/4 ml-4 pt-6 flex justify-start`}>
                {formik.touched.thumbnail && formik.errors.thumbnail && (
                  <div className='formUsersError'>{formik.errors.thumbnail}</div>
                )}
                <div className='flex flex-col w-full'>
                  <span className='font-medium pr-1'>{t('thumb')}</span>
                  <button
                    type='submit'
                    className={`border-2 border-primary border-solid rounded-3xl py-2 px-4 text-sm ${
                      formik.touched.thumbnail && formik.errors.thumbnail ? 'border-0 error' : ''
                    } w-full overflow-hidden text-ellipsis whitespace-nowrap`}
                    onClick={(e) => {
                      e.preventDefault();
                      thumbRef.current?.click();
                    }}
                  >
                    {thumbName}
                  </button>
                </div>
                <input
                  type='file'
                  onChange={(event) => {
                    if (event.target.files) {
                      formik.setFieldValue('thumbnail', event.target.files[0]);
                      setThumbName(event.target.files[0].name);
                    }
                  }}
                  ref={thumbRef}
                  className='hidden'
                  name='thumbnail'
                  accept='image/png, image/gif, image/jpeg, image/jpg, image/heic, image/raw'
                />
              </div>
            </div>
          )}
        </div>
        {formik.values.type !== 'Introduction' &&
          currentNode === 'Question' &&
          ((isResponse && isEditing) || (!isEditing && !isResponse)) && (
            <div className='flex-1 py-4 items-center relative'>
              {formik.touched.statement && formik.errors.statement && (
                <div className='formUsersError'>{formik.errors.statement as string}</div>
              )}
              <span>
                {currentNode === 'Question' && formik.values.type === 'Question'
                  ? t('resQuestion')
                  : t('statement')}
              </span>
              <Input
                extraClass='w-full bg-sweetPurple'
                type='text'
                name='statement'
                value={formik.values.statement}
                placeholder={t('statementPlaceholder')}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.statement && (formik.errors.statement as string)}
              />
            </div>
          )}
        {formik.values.type === 'Question' && !isResponse && (
          <div className='flex-1 py-4 items-center relative'>
            {formik.touched.question && formik.errors.question && (
              <div className='formUsersError'>{formik.errors.question as string}</div>
            )}
            <span>
              {currentNode === 'Question' && formik.values.type === 'Question'
                ? t('nextQ')
                : t('question')}
            </span>
            <Input
              extraClass='w-full bg-sweetPurple'
              type='text'
              name='question'
              value={formik.values.question}
              placeholder={t('questionPlaceholder')}
              onBlur={formik.handleBlur}
              onChange={(e) => formik.handleChange(e)}
              haveError={formik.touched.question && (formik.errors.question as string)}
            />
          </div>
        )}
        <DecisionFooter
          cancel={t('cancelBtn')}
          onCancel={onClose}
          approve={isEditing ? t('saveBtn') : t('addBtn')}
          extraClass={'my-8'}
        />
      </form>
      <Loading />
    </div>
  );
};
export default NodeForm;
