import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

// Interfaces
import { StoriesRowI } from 'src/interfaces/storiesInterface';

// Components
import TableDropdown from '../dropdowns/TableDropdown';

const StoryRow = (props: StoriesRowI) => {
  const { titles, access, id, subscription, isPublished } = props;
  const { t } = useTranslation(['stories']);

  const handlePublishedTextEn = () => {
    if (!titles.enTitle) {
      return t('enNot');
    } else if (titles.enTitle && isPublished.en) {
      return t('enPublished');
    } else return t('enNotPublished');
  };

  const handlePublishedTextEs = () => {
    if (!titles.esTitle) {
      return t('esNot');
    } else if (titles.esTitle && isPublished.es) {
      return t('esPublished');
    } else return t('esNotPublished');
  };

  return (
    <div className='hover:bg-hover flex pl-12'>
      <div className='flex flex-col' style={{ width: '40%' }}>
        <div className='usersTableCell justify-start items-center w-4/5 overflow-hidden text-ellipsis whitespace-nowrap'>
          <ReactTooltip
            id='story-detail'
            place='top'
            className='w-32 bg-secondary text-primary rounded-full !important'
            backgroundColor='bg-secondary'
            arrowColor='#9362C7'
            textColor='white'
          />
          <i data-for='story-detail' data-tip={t('detailTooltip')}>
            <Link
              to={`/admin/stories/${id}/story-detail/${'1'}`}
              className='underline not-italic font-bold shadow-none capitalize text-xs bg-transparent text-blueGray-600'
            >
              {`${t('en')} ${titles.enTitle ? titles.enTitle : t('notExist')}`}
            </Link>
          </i>
        </div>
        <div className='usersTableCell justify-start items-center w-4/5 overflow-hidden text-ellipsis whitespace-nowrap'>
          <ReactTooltip
            id='story-detail'
            place='top'
            className='w-32 bg-secondary text-primary rounded-full !important'
            backgroundColor='bg-secondary'
            arrowColor='#9362C7'
            textColor='white'
          />
          <i data-for='story-detail' data-tip={t('detailTooltip')}>
            <Link
              to={`/admin/stories/${id}/story-detail/${'2'}`}
              className='underline not-italic justify-start font-bold shadow-none capitalize text-xs bg-transparent text-blueGray-600'
            >
              {`${t('es')} ${titles.esTitle ? titles.esTitle : t('notExist')}`}
            </Link>
          </i>
        </div>
      </div>
      <div className='flex flex-col' style={{ width: '30%' }}>
        <div className='usersTableCell  flex justify-start items-center' style={{ width: '100%' }}>
          {handlePublishedTextEn()}
        </div>
        <div className='usersTableCell  flex justify-start items-center' style={{ width: '100%' }}>
          {handlePublishedTextEs()}
        </div>
      </div>
      <div className='usersTableCell flex justify-start items-center' style={{ width: '30%' }}>
        {access ? t('paid') : t('free')}
        <TableDropdown
          user={true}
          id={id ? id?.toString() : ''}
          status={Boolean(status)}
          thirdOption={false}
          subscription={subscription}
          access={access}
        />
      </div>
    </div>
  );
};

export default StoryRow;
