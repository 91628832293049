import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Redux
import { setAddUserView, setEditUserView } from 'src/redux/slices/users.slice';

// Components
import UserPhotosForm from 'src/components/forms/admin/users/UserPhotosForm';

// Services
import { uploadProfilePhotosUseCase } from 'src/services/users/useCases';

const AddUserPhotos = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const addUserView = useAppSelector((state) => state.users.addUserView);
  const currentUserId = JSON.parse(localStorage.getItem('current-user') || '');

  const handleNavigation = () => {
    dispatch(setAddUserView('profile-picture'));
    dispatch(setEditUserView('profile-picture'));
    navigate(-1);
  };

  const handleSuccess = async (values: any) => {
    if (values.photos) {
      if (values.photos.length > 0) {
        const formDataPhotos = new FormData();
        for (let i = 0; i < values.photos.length; i++) {
          formDataPhotos.append('profilePhotos', values.photos[i].file);
        }
        dispatch(
          uploadProfilePhotosUseCase({
            publicId: currentUserId,
            profilePhotos: formDataPhotos,
          }),
        );
      }
    }
    dispatch(setAddUserView('finish'));
  };

  useEffect(() => {
    if (addUserView === 'finish') {
      navigate('/admin/users');
    }
  }, [addUserView, navigate]);

  return (
    <UserPhotosForm onSuccess={handleSuccess} isEditing={false} handleNav={handleNavigation} />
  );
};

export default AddUserPhotos;
