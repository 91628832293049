import { useState } from 'react';

// Components
import Icon from 'src/ui/Icon';

const PaswordVisibilityToggle = () => {
  const [visible, setVisible] = useState(false);
  const inputType = visible ? 'text' : 'password';
  const icon = (
    <Icon
      icon={`${visible ? 'eyeOpen' : 'eyeClosed'}`}
      onClick={() => setVisible((visible) => !visible)}
      extraClass='absolute right-3.5 top-2.5'
    />
  );

  return [inputType, icon];
};

export default PaswordVisibilityToggle;
