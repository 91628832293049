import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';

// Components
import CoachAdditionalInfo from 'src/components/cards/admin/coaches/AdditionalInfo';

// Interfaces
import { CoachAdditionalInfoFormProps } from 'src/interfaces/coachesInterface';

// Redux
import { setCoachDetailView } from 'src/redux/slices/coaches.slice';

// Services
import {
  uploadCoachCertificationsUseCase,
  uploadCoachPictureUseCase,
} from 'src/services/coaches/useCases';

const AddCoachAdditionalInfo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentCoachId = JSON.parse(localStorage.getItem('current-coach') || '');
  const coachDetailView = useAppSelector((state) => state.coaches.coachDetailView);
  const handleSuccess = async (values: CoachAdditionalInfoFormProps) => {
    const formData = new FormData();
    formData.append('profilePicture', values.profilePicture as Blob);
    if (values.profilePicture) {
      await dispatch(
        uploadCoachPictureUseCase({ coachId: currentCoachId, profilePicture: formData }),
      );
    }
    if (values.certificates) {
      const formDataCertificates = new FormData();
      for (let i = 0; i < values.certificates.length; i++) {
        formDataCertificates.append('certificates', values.certificates[i].file);
      }
      await dispatch(
        uploadCoachCertificationsUseCase({
          coachId: currentCoachId,
          certificates: formDataCertificates,
        }),
      );
    }
    dispatch(setCoachDetailView('finish'));
  };

  useEffect(() => {
    if (coachDetailView === 'finish') {
      navigate('/admin/coaches');
    }
  }, [coachDetailView, navigate]);

  return <CoachAdditionalInfo onSuccess={handleSuccess} />;
};

export default AddCoachAdditionalInfo;
