const Item = (prop: any) => {
  const { children, onClick, extraClass, itemExtraClass } = prop;

  return (
    <div
      className={`w-full mb-6 ${extraClass ? extraClass : ''}`}
      onClick={onClick}
      role='button'
      onKeyPress={onClick}
      tabIndex={0}
    >
      <div className={`him-item ${itemExtraClass}`}>{children}</div>
    </div>
  );
};

export default Item;
