import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import acceptedImageFormats from 'src/common/constants/imgFormats';
import * as Yup from 'yup';
import { useAppDispatch } from 'src/hooks';
import { validateImageDimension } from 'src/common/helpers/resizerImage';

// Redux
import { setResponsePopup } from 'src/redux/slices/generalSlice';
import { setCoachDetailView, setEditCoachDetailView } from 'src/redux/slices/coaches.slice';

// Interfaces
import {
  CoachAdditionalInfoFormProps,
  CoachProfilePictureProps,
} from 'src/interfaces/coachesInterface';
import { FileWithUrl, IFile } from 'src/interfaces/generalInterface';

// Components
import Icon from 'src/ui/Icon';
import ConfirmationPopup from 'src/ui/popups/ConfirmationPopup';
import CoachAdditionalInfoForm from 'src/components/forms/admin/coaches/CoachAdditionalInfoForm';

// Services
import { deleteCoachProfilePictureUseCase } from 'src/services/coaches/useCases';
import { deleteCertificateAction } from 'src/services/coaches/requests';

const CoachAdditionalInfo = (props: CoachProfilePictureProps) => {
  const dispatch = useAppDispatch();
  const { onSuccess, data, isEditing } = props;
  const image = new Image();
  const [profilePicture, setProfilePicture] = useState<any>();
  const { t } = useTranslation(['addEditCoachAdditionalInfoForm']);
  const currentCoachId = JSON.parse(localStorage.getItem('current-coach') || '');
  const [preview, setPreview] = useState<any>(data?.profilePicture || '');
  const [certificates, setCertificates] = useState<FileWithUrl[]>([]);
  const [showDeletePhoto, setShowDeletePhoto] = useState(false);
  const [showDeleteCertificate, setShowDeleteCertificate] = useState(false);
  const [currentCertificate, setCurrentCertificate] = useState<IFile>();
  const fileInputRef = React.createRef<HTMLInputElement>();
  const certificatesInputRef = React.createRef<HTMLInputElement>();
  const navigate = useNavigate();
  const formik = useFormik<CoachAdditionalInfoFormProps>({
    initialValues: {
      profilePicture: '',
      certificates: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      profilePicture: Yup.mixed()
        .notRequired()
        .test('type', t('errorImageFormats'), (file) => {
          if (file) {
            return acceptedImageFormats.includes(file.type);
          }
          return true;
        })
        .test('fileSize', t('errorFileSize'), (file) => {
          if (file) {
            return file.size <= 10485760;
          }
          return true;
        })
        .test('imageWidth', t('errorWidth'), (file) => {
          if (file && image.width > 2048) {
            return false;
          }
          return true;
        })
        .test('imageHeight', t('errorHeight'), (file) => {
          if (file && image.height > 2048) {
            return false;
          }
          return true;
        }),
      certificates: Yup.mixed()
        .test('length', t('maxImages'), (photos) => {
          if (photos && photos?.length > 10) {
            return false;
          }
          return true;
        })
        .test('type', t('errorImageFormats'), (photos) => {
          if (photos && photos.length > 0) {
            for (let i = 0; i < photos.length; i++) {
              if (!photos[i].inServer) {
                if (!acceptedImageFormats.includes(photos[i].file.type)) return false;
              }
            }
          }
          return true;
        })
        .test('fileSize', t('errorFileSize'), (files) => {
          let valid = true;
          if (files) {
            files.forEach((file: IFile) => {
              if (!file.inServer) {
                const size = file.file.size / 1024 / 1024;
                if (size > 10) {
                  valid = false;
                }
              }
            });
          }
          return valid;
        })
        .test('width', t('errorDimensions'), async (value) => {
          const imageArray: Array<any> = [];
          if (value) {
            value.forEach((file: IFile) => {
              if (!file.inServer) {
                const promise = validateImageDimension(file.file);
                imageArray.push(promise);
              }
            });
            const res = await Promise.all(imageArray);
            return !res.includes(false);
          }
          return true;
        }),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  const handleNavigation = () => {
    dispatch(setCoachDetailView(''));
    dispatch(setEditCoachDetailView(''));
    navigate(-1);
  };

  const handleUpload = (upPhotos: any) => {
    const nArray: any = certificates.concat(upPhotos);
    const send = nArray.map((p: any, index: number) => {
      const send: any = Object.assign({}, p);
      if (!send.inServer) {
        send.id = index;
      }
      return send;
    });
    setCertificates(send);
    formik.setFieldValue('certificates', send);
    formik.setFieldTouched('certificates');
  };

  const handleChange = (e: any) => {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      if (e.currentTarget.files.length === 1) {
        const iFile = {
          id: 1,
          inServer: false,
          file: e.currentTarget.files[0],
          urlPreview: URL.createObjectURL(e.currentTarget.files[0]),
        };
        validateImageDimension(iFile.file).then(() => {
          handleUpload([iFile]);
        });
      } else {
        const arrayImage = [...e.currentTarget.files];
        const send = arrayImage.map((file: File, index: number) => {
          const r = {
            id: index,
            file: file,
            urlPreview: URL.createObjectURL(file),
            inServer: false,
          };
          return r;
        });
        handleUpload(send);
      }
    }
  };
  const handlePreviewProfile = () => {
    setProfilePicture(null);
    formik.setFieldValue('profilePicture', null);
    setPreview('');
  };

  const handleDeletePicture = () => {
    handlePreviewProfile();
    if (profilePicture?.inServer) {
      dispatch(deleteCoachProfilePictureUseCase(currentCoachId));
    }
    setShowDeletePhoto(false);
  };

  const handleDeleteCertificate = () => {
    if (currentCertificate?.inServer) {
      deleteCertificateAction({
        coachId: currentCoachId,
        certificationId: currentCertificate.id,
      }).then(() => {
        const copyPhotos = [...certificates];
        const save = copyPhotos.filter((p) => p.id !== currentCertificate.id);
        setCertificates(save);
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'success',
            header: t('deleteCertificate.deleteConfirmationTitle'),
            subtitle: t('deleteCertificate.deleteConfirmationSubtitle'),
            submitBtn: t('deleteCertificate.ok'),
          }),
        );
      });
    } else {
      const copyPhotos = [...certificates];
      const save = copyPhotos.filter((p) => p.id !== currentCertificate?.id);
      setCertificates(save);
      formik.setFieldValue('certificates', save);
      formik.setFieldTouched('certificates');
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'success',
          header: t('deleteCertificate.deleteConfirmationTitle'),
          subtitle: t('deleteCertificate.deleteConfirmationSubtitle'),
          submitBtn: t('deleteCertificate.ok'),
        }),
      );
    }
    setShowDeleteCertificate(false);
  };

  useEffect(() => {
    if (data?.profilePicture) {
      const r: any = Object.assign({}, data?.profilePicture);
      r.urlPreview = data?.profilePicture;
      r.inServer = true;
      setProfilePicture(r);
      setPreview(r.urlPreview);
    }
  }, [data?.profilePicture]);

  useEffect(() => {
    if (data?.certifications) {
      const updatedPhotos = data?.certifications.map((file: any) => {
        const r = Object.assign({}, file);
        r.urlPreview = file.fileUrl;
        r.inServer = true;
        return r;
      });
      setCertificates(updatedPhotos);
    }
  }, [data?.certifications]);

  return (
    <div
      className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary'
      style={{ height: '75vh' }}
    >
      <div className='flex items-center p-3 bg-background rounded-t-2xl'>
        {isEditing && <Icon icon='backBtn' onClick={handleNavigation} />}
        <span className='flex font-semibold text-sm mx-2 uppercase'>{t('header')}</span>
      </div>
      <hr />
      <div className='coach-container h-full pt-4'>
        <div className='coach-top flex w-full h-full'>
          <div className='coach-general w-full overflow-auto'>
            <CoachAdditionalInfoForm
              formik={formik}
              setPreview={setPreview}
              setProfilePicture={setProfilePicture}
              setShowDeletePhoto={() => setShowDeletePhoto(true)}
              fileInputRef={fileInputRef}
              preview={preview}
              profilePicture={profilePicture}
              certificates={certificates}
              handleChange={handleChange}
              certificatesInputRef={certificatesInputRef}
              isEditing={isEditing}
              data={data}
              setCurrentCertificate={setCurrentCertificate}
              setShowDeleteCertificate={setShowDeleteCertificate}
            />
          </div>
        </div>
      </div>
      <ConfirmationPopup
        isVisible={showDeletePhoto}
        title={t('deleteAlert.title')}
        subtitle={t('deleteAlert.subtitle')}
        submitBtn={t('deleteAlert.confirm')}
        handleSubmit={handleDeletePicture}
        onClose={() => setShowDeletePhoto(false)}
      />
      <ConfirmationPopup
        isVisible={showDeleteCertificate}
        title={t('deleteCertificate.title')}
        subtitle={t('deleteCertificate.subtitle')}
        submitBtn={t('deleteCertificate.confirm')}
        handleSubmit={handleDeleteCertificate}
        onClose={() => setShowDeleteCertificate(false)}
      />
    </div>
  );
};

export default CoachAdditionalInfo;
