import ReactTooltip from 'react-tooltip';

// Interfaces
import { UserDetalItemI } from 'src/interfaces/usersInterface';

const UserDetailItem = (props: UserDetalItemI) => {
  const { imgSrc, text, imgAlt, imgExtraClass, imgStyle, tooltipId, tooltipText } = props;

  return (
    <div className='flex text-sm my-2 items-center'>
      <div className='w-8'>
        <ReactTooltip
          id={tooltipId}
          place='top'
          className='w-36 bg-secondary text-primary rounded-full !important'
          backgroundColor='bg-secondary'
          arrowColor='#9362C7'
          textColor='white'
        />
        <i data-for={tooltipId} data-tip={tooltipText}>
          <img src={imgSrc} alt={imgAlt} className={imgExtraClass} style={imgStyle} />
        </i>
      </div>
      <span style={{ maxWidth: '85%' }} className='ml-2 break-words'>
        {text}
      </span>
    </div>
  );
};

export default UserDetailItem;
