// Interfaces
import {
  AvailabilityBlock,
  CoachAvailabilityNewI,
  CoachGeneralInfo,
  NewAvailabilityBlock,
  UpdateAvailabilitySendData,
} from 'src/interfaces/coachesInterface';
import { IFile } from 'src/interfaces/generalInterface';

// Redux
import {
  addCoachAvailability,
  createCoach,
  deactivateActivateCoach,
  deleteCoach,
  deleteCoachAvailability,
  deleteCoachProfilePicture,
  setCoachAvailability,
  setCoachDetail,
  setCoachDetailView,
  setCoaches,
  setCurrentAvailability,
  setEditCoachDetailView,
  setMetadata,
  updateCoach,
} from 'src/redux/slices/coaches.slice';
import { setIsLoading, setResponsePopup } from 'src/redux/slices/generalSlice';
import { AppThunk } from 'src/redux/store';

// Services
import {
  addAvailabilityBlock,
  createCoachAction,
  deleteAvailabilityBlock,
  deleteCoachAction,
  deleteCoachProfilePictureAction,
  getAppointments,
  getCoachAvailability,
  getCoachDetailAction,
  getCoachesListAction,
  updateAvailabilityBlock,
  updateCoachAction,
  updateCoachStatusAction,
  uploadCoachCertificationsAction,
  uploadCoachPictureAction,
} from './requests';

// Delete coach use case
export const deleteCoachUseCase =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await deleteCoachAction({ id: id })
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status == 200) {
          dispatch(deleteCoach(Number(id)));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'Coach has been successfully deleted.',
              submitBtn: 'Ok',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Deactivate/ activate coach use case
export const updateCoachStatusUseCase =
  (props: { coachId: string | undefined; status: boolean }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await updateCoachStatusAction({
      coachId: props.coachId,
      status: props.status,
    })
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.data.message === 'Coach successfully deactivated.') {
          dispatch(
            deactivateActivateCoach({ coachId: Number(props.coachId), status: 'Deactivated' }),
          );
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'The coach has been successfully deactivated.',
              submitBtn: 'Ok',
            }),
          );
        } else if (res.data.message === 'Coach successfully activated.') {
          dispatch(
            deactivateActivateCoach({ coachId: Number(props.coachId), status: 'Registered' }),
          );
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'The coach has been successfully activated.',
              submitBtn: 'Ok',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Delete coach profile picture use case
export const deleteCoachProfilePictureUseCase =
  (coachId: string | undefined): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await deleteCoachProfilePictureAction(coachId)
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status === 200) {
          dispatch(deleteCoachProfilePicture(coachId));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'The photo has been deleted.',
              submitBtn: 'Ok',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Add coach AvailabilityBlock use case
export const addAvailabilityBlockUseCase =
  (info: { coachId: string; data: AvailabilityBlock }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const resp = await addAvailabilityBlock({ coachId: info.coachId, data: info.data });
      if (resp.status !== 200) {
        dispatch(setIsLoading(false));
        console.log('res not 200');
      }
      dispatch(setIsLoading(false));
      dispatch(addCoachAvailability(resp.data.data));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'success',
          header: 'Done!',
          subtitle: 'Availability block created.',
          submitBtn: 'Ok',
        }),
      );
    } catch (error: any) {
      dispatch(setIsLoading(false));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle: error.response.data.detail,
          submitBtn: 'Ok',
        }),
      );
    }
  };

/*// Delete availability use Case
export const deleteAvailabilityBlockUseCase =
  (props: {
    coachId: string | undefined;
    availabilityId: string | number;
    divided: boolean;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await deleteAvailabilityBlock({
      coachId: props.coachId,
      availabilityId: props.availabilityId,
    }).then((res) => {
      dispatch(setIsLoading(false));
      if (res.data.message === 'Availability successfully removed.') {
        if (props.divided) {
          dispatch(deleteCoachAvailability(Number(props.availabilityId) - 1));
          dispatch(deleteCoachAvailability(props.availabilityId));
        } else {
          dispatch(deleteCoachAvailability(props.availabilityId));
        }
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'success',
            header: 'Done!',
            subtitle: 'Availability block has been deleted.',
            submitBtn: 'Ok',
          }),
        );
      }
    });
    return data;
  };
*/
/*// Update AvailabilityBlock use case
export const updateAvailabilityBlockUseCase =
  (props: {
    coachId: number | undefined;
    availabilityId: string | number;
    data: AvailabilitySendData;
  }): AppThunk =>
  async (dispatch) => {
    const formattedData = moment('2023-02-06').format('YYYY-MM-DD').toString();
    const offset = moment().utcOffset();
    const startHour = moment(`${formattedData} ${props.data.startHour}`);
    const endHour = moment(`${formattedData} ${props.data.endHour}`);

    let startSend = null;
    let endSend = null;

    if (offset > 0) {
      startSend = startHour.clone().subtract(Math.abs(offset), 'minutes');
      endSend = endHour.clone().subtract(Math.abs(offset), 'minutes');
    } else {
      startSend = startHour.clone().add(Math.abs(offset), 'minutes');
      endSend = endHour.clone().add(Math.abs(offset), 'minutes');
    }
    dispatch(setIsLoading(true));
    try {
      const resp = await updateAvailabilityBlock({
        coachId: props.coachId,
        availabilityId: props.availabilityId,
        data: props.data,
      });
      if (resp.status !== 200) {
        dispatch(setIsLoading(false));
        console.log('res not 200');
      }
      dispatch(setIsLoading(false));
      if (resp.status === 200) {
        dispatch(setIsLoading(false));
        dispatch(
          updateCoachAvailability({
            id: props.availabilityId,
            data: {
              startHour: startSend.format('HH:mm'),
              endHour: endSend.format('HH:mm'),
              day: props.data.day,
              id: props.availabilityId,
            },
          }),
        );
        dispatch(getCoachAvailabilityUseCase({ coachId: props.coachId?.toString() }));
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'success',
            header: 'Done!',
            subtitle: 'Availability block updated.',
            submitBtn: 'Ok',
          }),
        );
      }
    } catch (error: any) {
      dispatch(setIsLoading(false));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle: error.response.data.detail,
          submitBtn: 'Ok',
        }),
      );
    }
  };
*/
// Create coach use case
export const createCoachUseCase =
  (newCoach: CoachGeneralInfo): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await createCoachAction(newCoach)
      .then((res) => {
        dispatch(setIsLoading(false));
        dispatch(createCoach(res.data));
        const idResponse = res.data.data.coachId;
        localStorage.setItem('current-coach', JSON.stringify(idResponse));
        dispatch(setCoachDetailView('additional-info'));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        } else if (err.response.status === 400) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: 'Unable to create a coach with an existing user email.',
              submitBtn: 'Ok',
            }),
          );
        } else {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Upload coach profile picture use case
export const uploadCoachPictureUseCase =
  (props: { coachId?: string | undefined; profilePicture: FormData }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await uploadCoachPictureAction({
      coachId: props.coachId,
      profilePicture: props.profilePicture,
    })
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(setCoachDetailView('finish'));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Upload coach certifications use case
export const uploadCoachCertificationsUseCase =
  (props: { coachId: string; certificates: FormData }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await uploadCoachCertificationsAction({
      coachId: props.coachId,
      certificates: props.certificates,
    })
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(setCoachDetailView('finish'));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Upload certifications first endpoint
export const uploadAllCertificationsAction =
  (certificates: IFile[]): AppThunk =>
  async (dispatch) => {
    const currentCoachId = JSON.parse(localStorage.getItem('current-coach') || '');
    const send = new FormData();
    certificates.forEach((certificate) => {
      if (!certificate.inServer) send.append('certificates', certificate.file);
    });
    const count = Array.from(
      send.entries(),
      (
        [key, prop], // validate if send images length
      ) => ({
        [key]: {
          ContentLength: typeof prop === 'string' ? prop.length : prop.size,
        },
      }),
    );
    if (count.length > 0) {
      dispatch(uploadCoachCertificationsUseCase({ coachId: currentCoachId, certificates: send }));
    } else {
      return true;
    }
  };

// Get coach availability use case
export const getCoachAvailabilityUseCase =
  (info: { coachId: string | number | undefined }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await getCoachAvailability({
      coachId: info.coachId,
    })
      .then((res) => {
        dispatch(setIsLoading(false));
        dispatch(setCoachAvailability(res.data.data));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Get coach detail use case
export const getCoachDetailUseCase =
  (coachId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await getCoachDetailAction(coachId)
      .then((res) => {
        dispatch(setIsLoading(false));
        dispatch(setCoachDetail(res.data.data));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Update coach use case
export const updateCoachUseCase =
  (props: { coachId: string | undefined; updatedCoach: any }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await updateCoachAction({
      coachId: props.coachId,
      updatedCoach: props.updatedCoach,
    })
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status === 200) {
          dispatch(updateCoach(props.updatedCoach));
          dispatch(setEditCoachDetailView('edit-additional-info'));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'The coach has been edited.',
              submitBtn: 'Ok',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Get coaches list use case
export const getCoachesListUseCase =
  (coaches?: { page?: number; limit?: number }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await getCoachesListAction(coaches)
      .then((res) => {
        dispatch(setIsLoading(false));
        dispatch(setCoaches(res.data.data));
        dispatch(setMetadata(res.data.metadata));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Get coach new availability use case
export const getCoachNewAvailabilityUseCase =
  (info: { coachId: string | number | undefined }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await getCoachAvailability({
      coachId: info.coachId,
    })
      .then((res) => {
        dispatch(setIsLoading(false));
        dispatch(setCoachAvailability(res.data.data));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Add coach new AvailabilityBlock use case
export const addNewAvailabilityBlockUseCase =
  (info: { coachId: string; data: NewAvailabilityBlock }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const resp = await addAvailabilityBlock({ coachId: info.coachId, data: info.data });
      if (resp.status !== 200) {
        dispatch(setIsLoading(false));
        console.log('res not 200');
      }
      dispatch(setIsLoading(false));
      const res = resp.data.data;
      const createdAv = {
        startDate: res[0].date,
        endDate: res[res.length - 1].date,
        startHour: res[0].startHour,
        endHour: res[0].endHour,
        appointmentsCount: 0,
        availabilityList: resp.data.data,
      };
      dispatch(addCoachAvailability(createdAv));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'success',
          header: 'Done!',
          subtitle: 'Availability slot created successfully!',
          submitBtn: 'Ok',
        }),
      );
    } catch (error: any) {
      dispatch(setIsLoading(false));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle: error.response.data.detail,
          submitBtn: 'Ok',
        }),
      );
    }
  };

// Update AvailabilityBlock use case
export const updateAvailabilityBlockUseCase =
  (props: { coachId: number | undefined; data: UpdateAvailabilitySendData }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const resp = await updateAvailabilityBlock({
        coachId: props.coachId,
        data: props.data,
      });
      if (resp.status !== 200) {
        dispatch(setIsLoading(false));
        console.log('res not 200');
      }
      dispatch(setIsLoading(false));
      if (resp.status === 200) {
        dispatch(setIsLoading(false));
        /*dispatch(
          updateCoachAvailability({
            id: props.availabilityId,
            data: {
              startHour: startSend.format('HH:mm'),
              endHour: endSend.format('HH:mm'),
              day: props.data.day,
              id: props.availabilityId,
            },
          }),
        );
        */
        dispatch(getCoachNewAvailabilityUseCase({ coachId: props.coachId?.toString() }));
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'success',
            header: 'Done!',
            subtitle: 'Availability slot edited successfully!',
            submitBtn: 'Ok',
          }),
        );
      }
    } catch (error: any) {
      dispatch(setIsLoading(false));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle: error.response.data.detail,
          submitBtn: 'Ok',
        }),
      );
    }
  };

// Delete availability use Case
export const deleteAvailabilityBlockUseCase =
  (props: {
    coachId: string | undefined;
    availabilityList: [];
    firstAvailabilityIdToDelete: number;
    lastAvailabilityIdToDelete: number;
    data: CoachAvailabilityNewI;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const resp = await deleteAvailabilityBlock({
        coachId: props.coachId,
        data: {
          firstAvailabilityIdToDelete: props.firstAvailabilityIdToDelete,
          lastAvailabilityIdToDelete: props.lastAvailabilityIdToDelete,
        },
      });
      if (resp.status !== 200) {
        dispatch(setIsLoading(false));
        console.log('res not 200');
      }
      dispatch(setIsLoading(false));
      if (resp.status === 200) {
        dispatch(setIsLoading(false));
        if (resp.data.message === 'Availability successfully removed.') {
          dispatch(deleteCoachAvailability(props.data));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'Availability slot has been deleted successfully!',
              submitBtn: 'Ok',
            }),
          );
          dispatch(setCurrentAvailability(''));
        }
      }
    } catch (error: any) {
      dispatch(setIsLoading(false));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle: error.response.data.detail,
          submitBtn: 'Ok',
        }),
      );
    }
  };

// Delete availability when editing
export const editSpecificDeleteAvBlockUseCase =
  (props: {
    coachId: string | undefined;
    firstAvailabilityIdToDelete: number;
    lastAvailabilityIdToDelete: number;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const resp = await deleteAvailabilityBlock({
        coachId: props.coachId,
        data: {
          firstAvailabilityIdToDelete: props.firstAvailabilityIdToDelete,
          lastAvailabilityIdToDelete: props.lastAvailabilityIdToDelete,
        },
      });
      if (resp.status !== 200) {
        dispatch(setIsLoading(false));
        console.log('res not 200');
      }
      dispatch(setIsLoading(false));
      if (resp.status === 200) {
        dispatch(setIsLoading(false));
        if (resp.data.message === 'Availability successfully removed.') {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'Availability slot has been removed successfully!',
              submitBtn: 'Ok',
            }),
          );
          dispatch(setCurrentAvailability(''));
        }
      }
    } catch (error: any) {
      dispatch(setIsLoading(false));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle: error.response.data.detail,
          submitBtn: 'Ok',
        }),
      );
    }
  };

// check existing appointments use Case
export const getAppointmentsInfoUseCase =
  (props: {
    coachId: number | undefined;
    startDateTime: string;
    endDateTime: string;
    data: UpdateAvailabilitySendData;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const resp = await getAppointments({
        coachId: props.coachId,
        startDateTime: props.startDateTime,
        endDateTime: props.endDateTime,
      });
      if (resp.status !== 200) {
        dispatch(setIsLoading(false));
        console.log('res not 200');
      }
      dispatch(setIsLoading(false));
      if (resp.status === 200) {
        dispatch(setIsLoading(false));
        if (resp.data.metadata.totalCount > 0) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'question',
              header: 'Are you sure?',
              subtitle: 'Do you want to delete this time frame within the availability slot',
              boldSubtitle: ` with booked sessions?`,
              submitBtn: 'Yes, delete it!',
              cancelBtn: 'No, cancel',
              onSubmit: await dispatch(
                updateAvailabilityBlockUseCase({
                  coachId: Number(props.coachId),
                  data: {
                    timeOffset: props.data.timeOffset,
                    firstAvailabilityIdToDelete: props.data.firstAvailabilityIdToDelete,
                    lastAvailabilityIdToDelete: props.data.lastAvailabilityIdToDelete,
                    availabilitiesToCreate: props.data.availabilitiesToCreate,
                  },
                }),
              ),
            }),
          );
        }
      }
    } catch (error: any) {
      dispatch(setIsLoading(false));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle: error.response.data.detail,
          submitBtn: 'Ok',
        }),
      );
    }
  };
