import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import forward from '../../../assets/img/forward.svg';
import { useTranslation } from 'react-i18next';

// Interfaces
import { OtpFormI } from 'src/interfaces/ui/formInterface';

// Components
import Input from '../../../ui/Input';
import Button from 'src/ui/Button';

const OtpForm = (props: OtpFormI) => {
  const { onSuccess } = props;
  const { t } = useTranslation(['otp']);
  const n1 = useRef<HTMLInputElement>(null);
  const n2 = useRef<HTMLInputElement>(null);
  const n3 = useRef<HTMLInputElement>(null);
  const n4 = useRef<HTMLInputElement>(null);
  const n5 = useRef<HTMLInputElement>(null);

  const formik = useFormik({
    initialValues: {
      num1: '',
      num2: '',
      num3: '',
      num4: '',
      num5: '',
    },

    validationSchema: Yup.object({
      num1: Yup.number().required(t('required')),
      num2: Yup.string().required(t('required')),
      num3: Yup.string().required(t('required')),
      num4: Yup.string().required(t('required')),
      num5: Yup.string().required(t('required')),
    }),

    onSubmit: (values) => {
      onSuccess(values);
      formik.resetForm();
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-col'>
          {(formik.touched.num1 ||
            formik.touched.num2 ||
            formik.touched.num3 ||
            formik.touched.num4 ||
            formik.touched.num5) &&
          (formik.errors.num1 ||
            formik.errors.num2 ||
            formik.errors.num3 ||
            formik.errors.num4 ||
            formik.errors.num5) ? (
            <span className='text-alert ml-2 font-medium text-left'>{t('errorCode')}</span>
          ) : (
            <span className='text-primary ml-2 font-medium'>{t('codeLabel')}</span>
          )}
          <div className='flex items-center justify-center'>
            <Input
              type='text'
              name='num1'
              value={formik.values.num1}
              onChange={(e) => {
                if (e.target.value.length === 0) formik.handleChange(e);
                if (e.target.value.length === 1) {
                  formik.handleChange(e);
                  n2.current!.focus();
                }
                if (e.target.value.length > 1) n2.current!.focus();
              }}
              onBlur={formik.handleBlur}
              extraClass='him-code rounded-xl'
              reference={n1}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                !/[0-9]/.test(e.key) && e.preventDefault()
              }
              haveError={formik.touched.num1 && formik.errors.num1}
            />
            <Input
              type='text'
              name='num2'
              value={formik.values.num2}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  formik.handleChange(e);
                  n1.current!.focus();
                }
                if (e.target.value.length === 1) {
                  formik.handleChange(e);
                  n3.current!.focus();
                }
                if (e.target.value.length > 1) n3.current!.focus();
              }}
              onBlur={formik.handleBlur}
              extraClass='him-code rounded-xl'
              reference={n2}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                !/[0-9]/.test(e.key) && e.preventDefault()
              }
              haveError={formik.touched.num2 && formik.errors.num2}
            />
            <Input
              type='text'
              name='num3'
              value={formik.values.num3}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  formik.handleChange(e);
                  n2.current!.focus();
                }
                if (e.target.value.length === 1) {
                  formik.handleChange(e);
                  n4.current!.focus();
                }
                if (e.target.value.length > 1) n4.current!.focus();
              }}
              onBlur={formik.handleBlur}
              extraClass='him-code rounded-xl'
              reference={n3}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                !/[0-9]/.test(e.key) && e.preventDefault()
              }
              haveError={formik.touched.num3 && formik.errors.num3}
            />
            <Input
              type='text'
              name='num4'
              value={formik.values.num4}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  formik.handleChange(e);
                  n3.current!.focus();
                }
                if (e.target.value.length === 1) {
                  formik.handleChange(e);
                  n5.current!.focus();
                }
                if (e.target.value.length > 1) n5.current!.focus();
              }}
              onBlur={formik.handleBlur}
              extraClass='him-code rounded-xl'
              reference={n4}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                !/[0-9]/.test(e.key) && e.preventDefault()
              }
              haveError={formik.touched.num4 && formik.errors.num4}
            />
            <Input
              type='text'
              name='num5'
              value={formik.values.num5}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  formik.handleChange(e);
                  n4.current!.focus();
                }
                if (e.target.value.length === 1) {
                  formik.handleChange(e);
                }
                if (e.target.value.length > 1) return;
              }}
              onBlur={formik.handleBlur}
              extraClass='him-code rounded-xl'
              reference={n5}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                !/[0-9]/.test(e.key) && e.preventDefault()
              }
              haveError={formik.touched.num5 && formik.errors.num5}
            />
          </div>
        </div>
        <div className='him-footer'>
          <Button
            type='submit'
            extraClass='p-2 w-5/12 capitalize flex justify-center relative rounded-md border border-white'
          >
            <span>{t('submitBtn')}</span>
            <img src={forward} alt='forward' className='absolute right-4' />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OtpForm;
